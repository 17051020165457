'use client'

import { Listbox, ListboxButton, ListboxOptions } from '@headlessui/react'
import {
  themeBackgroundBlurMap,
  themeBackgroundMap,
  themeBorderColorMap,
  themeDivideColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { VirtualItem } from '@tanstack/react-virtual'
import { Fragment, forwardRef, useRef, useState } from 'react'
import { useVirtualizer } from '../../hooks/use-virtualizer.tsx'
import type { UseFormFieldControlProps } from '../form-field/use-form-field-control.ts'
import {
  MultiselectChips,
  type MultiselectChipsProps,
} from './multiselect-chips.tsx'
import { multiselectOptionHeight } from './multiselect-option.tsx'
import { MultiselectPopoutOption } from './multiselect-popout-option.tsx'
import {
  MultiselectSearch,
  multiselectSearchHeight,
} from './multiselect-search.tsx'
import {
  type MultiselectOptionItem,
  MultiselectProvider,
  type UseMultiselectProps,
  useMultiselect,
  useMultiselectContext,
} from './use-multiselect.ts'

type MultiselectPopoutInnerProps = UseFormFieldControlProps<HTMLDivElement> &
  Pick<MultiselectChipsProps, 'listLocation' | 'placeholder' | 'singleLine'> & {
    options: MultiselectOptionItem[]
    searchable?: boolean
  }

type MultiselectPopoutProps = Readonly<
  UseMultiselectProps & MultiselectPopoutInnerProps
>

const searchHeight = multiselectSearchHeight()
const optionHeight = multiselectOptionHeight()

const MultiselectPopoutInner = forwardRef<
  HTMLDivElement,
  MultiselectPopoutInnerProps
>(
  (
    {
      placeholder,
      singleLine,
      options,
      searchable,
      listLocation,
      ...useFormFieldProps
    },
    ref
  ) => {
    const [items, setItems] = useState<VirtualItem[]>([])
    const scrollRef = useRef<HTMLDivElement>(null)

    const [virtualizer, VirtualizationProvider] = useVirtualizer({
      enabled: true,
      count: options.length + (searchable ? 1 : 0),
      getScrollElement: () => scrollRef.current,
      estimateSize: (i) =>
        searchable && i === 0 ? searchHeight : optionHeight,
      overscan: 10,
    })

    const { selectedItems, onChange } = useMultiselectContext()

    const scrollCb = (el: HTMLDivElement) => {
      scrollRef.current = el

      setItems(virtualizer.getVirtualItems())
    }

    return (
      <VirtualizationProvider value={virtualizer}>
        <Listbox multiple value={selectedItems} onChange={onChange}>
          <ListboxButton as={Fragment}>
            <MultiselectChips
              ref={ref}
              listLocation={listLocation}
              placeholder={placeholder}
              singleLine={singleLine}
              {...useFormFieldProps}
            />
          </ListboxButton>
          <ListboxOptions
            anchor={{
              to: 'bottom start',
              gap: '16px',
              padding: '32px',
            }}
            className={cn(
              'rounded border divide-y focus-visible:outline-none w-[var(--button-width)] overflow-hidden z-30',
              themeBackgroundBlurMap['blur-xl'],
              themeBackgroundMap['container-secondary'],
              themeBorderColorMap.muted,
              themeDivideColorMap.muted
            )}
          >
            <div ref={scrollCb} className="h-full w-full overflow-y-auto">
              <div
                className="relative w-full"
                style={{ height: virtualizer.getTotalSize() }}
              >
                <div
                  className="absolute top-0 left-0 w-full [&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:border-b-white/10"
                  style={{ transform: `translateY(${items[0]?.start ?? 0}px)` }}
                >
                  {items.map((virtualRow) => {
                    if (searchable && virtualRow.index === 0) {
                      return (
                        <MultiselectSearch
                          key={virtualRow.key}
                          index={virtualRow.index}
                        />
                      )
                    }

                    const option = options[virtualRow.index - 1]

                    if (!option) {
                      return null
                    }

                    return (
                      <MultiselectPopoutOption
                        key={virtualRow.key}
                        item={option}
                        index={virtualRow.index}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </ListboxOptions>
        </Listbox>
      </VirtualizationProvider>
    )
  }
)

const MultiselectPopoutComponent = forwardRef<
  HTMLDivElement,
  MultiselectPopoutProps
>(({ selectedItems, onChange, ...innerProps }, ref) => {
  const context = useMultiselect({ isPopout: true, selectedItems, onChange })

  return (
    <MultiselectProvider value={context}>
      <MultiselectPopoutInner {...innerProps} ref={ref} />
    </MultiselectProvider>
  )
})

const MultiselectPopoutNamespace = Object.assign(MultiselectPopoutComponent, {
  Option: MultiselectPopoutOption,
  Search: MultiselectSearch,
})

export { MultiselectPopoutNamespace as MultiselectPopout }
