'use client'

import { useCallback, useState } from 'react'
import type { TestIds } from '../../utils'
import { createContext } from '../../utils/context'

export type UseCollapsibleProps = {
  isOpen?: boolean
} & TestIds

export type UseCollapsibleReturn = {
  isOpen: boolean
  toggleOpen: () => void
  setOpen: (state: boolean) => void
} & TestIds

export function useCollapsible(
  props: UseCollapsibleProps
): UseCollapsibleReturn {
  const { isOpen: initialOpen = false } = props

  const [isOpen, setOpen] = useState(initialOpen)

  const toggleOpen = useCallback(() => {
    setOpen((prev) => !prev)
  }, [])

  return {
    dataTestId: props.dataTestId,
    dataTrackingId: props.dataTrackingId,
    isOpen,
    toggleOpen,
    setOpen,
  }
}

const [CollapsibleProvider, useCollapsibleContext] =
  createContext<UseCollapsibleReturn>({
    name: 'CollapsibleContext',
  })

export { CollapsibleProvider, useCollapsibleContext }
