export function generateRandomString(length = 16): string {
  const bytes = new Uint8Array(length)
  crypto.getRandomValues(bytes) // Secure random values

  // Convert bytes to a Base64 string (compatible with Edge runtime)
  const base64 = Buffer.from(bytes).toString('base64')

  // Convert to URL-safe Base64
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}
