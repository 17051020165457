export type TestIds = {
  dataTestId?: string
  dataTrackingId?: string
}

export const getTestProps = ({ dataTestId, dataTrackingId }: TestIds) => ({
  'data-testid': dataTestId,
  'data-tracking-id': dataTrackingId,
})

export const getChildTestIds = (
  { dataTestId, dataTrackingId }: TestIds,
  ...childKeys: string[]
): TestIds => ({
  dataTestId: dataTestId ? [dataTestId, ...childKeys].join('-') : undefined,
  dataTrackingId: dataTestId
    ? [dataTrackingId, ...childKeys].join('-')
    : undefined,
})

export const getChildTestProps = (
  { dataTestId, dataTrackingId }: TestIds,
  ...childKeys: string[]
) => {
  return getTestProps(
    getChildTestIds({ dataTestId, dataTrackingId }, ...childKeys)
  )
}
