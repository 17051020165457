import type { LiteralUnion } from 'type-fest'
import { z } from 'zod'
import type { ZodSimplify } from './utility-types.ts'

// ISO 8601 format
export type ISO8601DateString =
  | `${number}-${number}-${number}T${number}:${number}:${number}Z`
  | `${number}-${number}-${number}T${number}:${number}:${number}+${number}:${number}`

// Short date format (MM/DD/YYYY)
export type ShortDateString = `${number}/${number}/${number}`

// Long date format (Month DD, YYYY)
export type LongDateString = `${string} ${number}, ${number}`

// RFC 2822 format
export type RFC2822DateString =
  `${string}, ${number} ${string} ${number} ${number}:${number}:${number} GMT`

// Combine all formats
export type AnyDateString = LiteralUnion<
  ISO8601DateString | ShortDateString | LongDateString | RFC2822DateString,
  string
>

// ISO 8601 format (with Z or timezone offset)
const iso8601Regex =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(Z|[\+\-]\d{2}:\d{2})$/
// Short date format (MM/DD/YYYY)
const shortDateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/
// Long date format (Month DD, YYYY)
const longDateRegex = /^[a-zA-Z]+ \d{2}, \d{4}$/
// RFC 2822 format
const rfc2822Regex = /^[a-zA-Z]+, \d{2} [a-zA-Z]+ \d{4} \d{2}:\d{2}:\d{2} GMT$/

export const isDateString = (dateStr: string): dateStr is AnyDateString => {
  return (
    iso8601Regex.test(dateStr) ||
    shortDateRegex.test(dateStr) ||
    longDateRegex.test(dateStr) ||
    rfc2822Regex.test(dateStr)
  )
}

// Zod schema for date strings
export const DateStringSchema = z.string().refine(isDateString, {
  message: 'Invalid date format.',
})

// Type inference
export type DateString = ZodSimplify<typeof DateStringSchema>
