import type { NonEmptyArray } from '@mntn-dev/utility-types'

export function getRandomItem<T>(items: Readonly<NonEmptyArray<T>>): T {
  const randomIndex = Math.floor(Math.random() * items.length)
  return items[randomIndex] as T
}

export function getRandomItems<T>(items: T[], count: number): T[] {
  const shuffled = [...items].sort(() => 0.5 - Math.random())
  return shuffled.slice(0, Math.min(count, items.length))
}
