import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { OrganizationTypeSchema } from './organization.models.ts'
import { NameSchema } from './property.models.ts'
import { TagFilterSchema } from './tag.models.ts'
import {
  FileIdSchema,
  OrganizationIdSchema,
  TeamUrnSchema,
} from './unique-id.models.ts'
import { TeamIdSchema } from './unique-id.models.ts'

export const TeamDomainSelectModelSchema = z.object({
  teamId: TeamIdSchema,
  teamUrn: TeamUrnSchema,
  organizationType: OrganizationTypeSchema,
  organizationId: OrganizationIdSchema,
  name: NameSchema,
  avatarFileId: FileIdSchema.optional(),
})

export type TeamDomainSelectModel = ZodSimplify<
  typeof TeamDomainSelectModelSchema
>

/**
 * TeamDomainInsertModel
 */
export const TeamDomainInsertModelSchema = TeamDomainSelectModelSchema.omit({
  teamUrn: true,
}).partial({
  teamId: true,
})

export type TeamDomainInsertModel = ZodSimplify<
  typeof TeamDomainInsertModelSchema
>

/**
 * TeamDomainUpdateModel
 */
export const TeamDomainUpdateModelSchema = TeamDomainSelectModelSchema.pick({
  name: true,
})
  .extend({
    avatarFileId: TeamDomainSelectModelSchema.shape.avatarFileId.nullable(),
  })
  .partial()

export type TeamDomainUpdateModel = ZodSimplify<
  typeof TeamDomainUpdateModelSchema
>

/**
 * TeamDomainSelectModelFilters
 */
export const TeamDomainSelectModelFiltersSchema = z
  .object({
    teamId: ModelFilterSchema(TeamIdSchema),
    organizationType: ModelFilterSchema(OrganizationTypeSchema),
    organizationId: ModelFilterSchema(OrganizationIdSchema),
    // When filtering, transform names to lower case so the
    // "lower(teams.name)" index can be used.
    name: ModelFilterSchema(NameSchema.toLowerCase()),
    tags: TagFilterSchema,
  })
  .partial()

export type TeamDomainSelectModelFilters = ZodSimplify<
  typeof TeamDomainSelectModelFiltersSchema
>
