import type { TupleToUnion } from 'type-fest'
import { z } from 'zod'

import type { ZodSimplify } from '@mntn-dev/utility-types'
import type { AnyUrn } from '../types.ts'
import { OpaqueSchema, UniqueIdBuilder } from '../unique-id-builder.ts'
export { UuidSchema } from '../unique-id-builder.ts'

/**
 * AgreementId
 */

export const [
  AgreementIdTag,
  AgreementIdSchema,
  AgreementId,
  AgreementUrnSchema,
  AgreementUrn,
] = UniqueIdBuilder('agreement')
export type AgreementId = ZodSimplify<typeof AgreementIdSchema>
export type AgreementUrn = ZodSimplify<typeof AgreementUrnSchema>

/**
 * ActivityId
 */

export const [
  ActivityIdTag,
  ActivityIdSchema,
  ActivityId,
  ActivityUrnSchema,
  ActivityUrn,
] = UniqueIdBuilder('activity')
export type ActivityId = ZodSimplify<typeof ActivityIdSchema>
export type ActivityUrn = ZodSimplify<typeof ActivityUrnSchema>

/**
 * AgencyId
 */
export const [
  AgencyIdTag,
  AgencyIdSchema,
  AgencyId,
  AgencyUrnSchema,
  AgencyUrn,
] = UniqueIdBuilder('agency')
export type AgencyId = ZodSimplify<typeof AgencyIdSchema>
export type AgencyUrn = ZodSimplify<typeof AgencyUrnSchema>

/**
 * BidId
 */
export const [BidIdTag, BidIdSchema, BidId, BidUrnSchema, BidUrn] =
  UniqueIdBuilder('bid')
export type BidId = ZodSimplify<typeof BidIdSchema>
export type BidUrn = ZodSimplify<typeof BidUrnSchema>

/**
 * BillingProfileId
 */
export const [
  BillingProfileIdTag,
  BillingProfileIdSchema,
  BillingProfileId,
  BillingProfileUrnSchema,
  BillingProfileUrn,
] = UniqueIdBuilder('billing-profile')
export type BillingProfileId = ZodSimplify<typeof BillingProfileIdSchema>
export type BillingProfileUrn = ZodSimplify<typeof BillingProfileUrnSchema>

/**
 * BrandId
 */
export const [BrandIdTag, BrandIdSchema, BrandId, BrandUrnSchema, BrandUrn] =
  UniqueIdBuilder('brand')
export type BrandId = ZodSimplify<typeof BrandIdSchema>
export type BrandUrn = ZodSimplify<typeof BrandUrnSchema>

/**
 * CommentId
 */
export const [
  CommentIdTag,
  CommentIdSchema,
  CommentId,
  CommentUrnSchema,
  CommentUrn,
] = UniqueIdBuilder('comment')
export type CommentId = ZodSimplify<typeof CommentIdSchema>
export type CommentUrn = ZodSimplify<typeof CommentUrnSchema>

/**
 * DeliverableId
 */
export const [
  DeliverableIdTag,
  DeliverableIdSchema,
  DeliverableId,
  DeliverableUrnSchema,
  DeliverableUrn,
] = UniqueIdBuilder('deliverable')
export type DeliverableId = ZodSimplify<typeof DeliverableIdSchema>
export type DeliverableUrn = ZodSimplify<typeof DeliverableUrnSchema>

/**
 * FeedId
 */
export const [FeedIdTag, FeedIdSchema, FeedId, FeedUrnSchema, FeedUrn] =
  UniqueIdBuilder('feed')
export type FeedId = ZodSimplify<typeof FeedIdSchema>
export type FeedUrn = ZodSimplify<typeof FeedUrnSchema>

/**
 * FileId
 */
export const [FileIdTag, FileIdSchema, FileId, FileUrnSchema, FileUrn] =
  UniqueIdBuilder('file')
export type FileId = ZodSimplify<typeof FileIdSchema>
export type FileUrn = ZodSimplify<typeof FileUrnSchema>

/**
 * NotificationId
 */
export const [
  NotificationIdTag,
  NotificationIdSchema,
  NotificationId,
  NotificationUrnSchema,
  NotificationUrn,
] = UniqueIdBuilder('notification')
export type NotificationId = ZodSimplify<typeof NotificationIdSchema>
export type NotificationUrn = ZodSimplify<typeof NotificationUrnSchema>

/**
 * OfferId
 */
export const [OfferIdTag, OfferIdSchema, OfferId, OfferUrnSchema, OfferUrn] =
  UniqueIdBuilder('offer')
export type OfferId = ZodSimplify<typeof OfferIdSchema>
export type OfferUrn = ZodSimplify<typeof OfferUrnSchema>

/**
 * OrganizationId
 */
export const [
  OrganizationIdTag,
  OrganizationIdSchema,
  OrganizationId,
  OrganizationUrnSchema,
  OrganizationUrn,
] = UniqueIdBuilder('organization')
export type OrganizationId = ZodSimplify<typeof OrganizationIdSchema>
export type OrganizationUrn = ZodSimplify<typeof OrganizationUrnSchema>

/**
 * OrganizationProgramId
 */
export const [
  OrganizationProgramIdTag,
  OrganizationProgramIdSchema,
  OrganizationProgramId,
  OrganizationProgramUrnSchema,
  OrganizationProgramUrn,
] = UniqueIdBuilder('organization-program')
export type OrganizationProgramId = ZodSimplify<
  typeof OrganizationProgramIdSchema
>
export type OrganizationProgramUrn = ZodSimplify<
  typeof OrganizationProgramUrnSchema
>

/**
 * PackageId
 */
export const [
  PackageIdTag,
  PackageIdSchema,
  PackageId,
  PackageUrnSchema,
  PackageUrn,
] = UniqueIdBuilder('package')
export type PackageId = ZodSimplify<typeof PackageIdSchema>
export type PackageUrn = ZodSimplify<typeof PackageUrnSchema>

/**
 * PackageServiceId
 */
export const [
  PackageServiceIdTag,
  PackageServiceIdSchema,
  PackageServiceId,
  PackageServiceUrnSchema,
  PackageServiceUrn,
] = UniqueIdBuilder('package-service')
export type PackageServiceId = ZodSimplify<typeof PackageServiceIdSchema>
export type PackageServiceUrn = ZodSimplify<typeof PackageServiceUrnSchema>

/**
 * ProjectId
 */
export const [
  ProjectIdTag,
  ProjectIdSchema,
  ProjectId,
  ProjectUrnSchema,
  ProjectUrn,
] = UniqueIdBuilder('project')
export type ProjectId = ZodSimplify<typeof ProjectIdSchema>
export type ProjectUrn = ZodSimplify<typeof ProjectUrnSchema>

/**
 * ProjectServiceId
 */
export const [
  ProjectServiceIdTag,
  ProjectServiceIdSchema,
  ProjectServiceId,
  ProjectServiceUrnSchema,
  ProjectServiceUrn,
] = UniqueIdBuilder('project-service')
export type ProjectServiceId = ZodSimplify<typeof ProjectServiceIdSchema>
export type ProjectServiceUrn = ZodSimplify<typeof ProjectServiceUrnSchema>

/**
 * ProofId
 */
export const [ProofIdTag, ProofIdSchema, ProofId, ProofUrnSchema, ProofUrn] =
  UniqueIdBuilder('proof')
export type ProofId = ZodSimplify<typeof ProofIdSchema>
export type ProofUrn = ZodSimplify<typeof ProofUrnSchema>

/**
 * ReviewId
 */
export const [
  ReviewIdTag,
  ReviewIdSchema,
  ReviewId,
  ReviewUrnSchema,
  ReviewUrn,
] = UniqueIdBuilder('review')
export type ReviewId = ZodSimplify<typeof ReviewIdSchema>
export type ReviewUrn = ZodSimplify<typeof ReviewUrnSchema>

/**
 * RoundId
 */
export const [RoundIdTag, RoundIdSchema, RoundId, RoundUrnSchema, RoundUrn] =
  UniqueIdBuilder('round')
export type RoundId = ZodSimplify<typeof RoundIdSchema>
export type RoundUrn = ZodSimplify<typeof RoundUrnSchema>

/**
 * ServiceId
 */

export const [
  ServiceIdTag,
  ServiceIdSchema,
  ServiceId,
  ServiceUrnSchema,
  ServiceUrn,
] = UniqueIdBuilder('service')
export type ServiceId = ZodSimplify<typeof ServiceIdSchema>
export type ServiceUrn = ZodSimplify<typeof ServiceUrnSchema>

/**
 * SessionId
 */

export const SessionIdTag = 'session' as const
export const SessionIdSchema = OpaqueSchema(z.string(), SessionIdTag)

const isSessionId = (value: unknown): value is string & z.BRAND<'session'> => {
  return SessionIdSchema.safeParse(value).success
}

/**
 * Generates an Auth0-style session ID that works in Vercel Edge
 * Uses Web Crypto API (`crypto.getRandomValues`)
 *
 * @param length Number of random bytes before encoding (default: 16)
 * @returns Base64-URL encoded session ID
 */
function generateSessionId(length = 16): string {
  const bytes = new Uint8Array(length)
  crypto.getRandomValues(bytes) // Secure random values

  // Convert bytes to a Base64 string (compatible with Edge runtime)
  const base64 = Buffer.from(bytes).toString('base64')

  // Convert to URL-safe Base64 (same as Auth0-style)
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
}

const EmptySessionId = '0000000000000000' as const

export const SessionId = Object.assign(
  (value = generateSessionId()) => SessionIdSchema.parse(value),
  {
    Empty: SessionIdSchema.parse(EmptySessionId) as typeof EmptySessionId &
      z.BRAND<'session'>,
    check: isSessionId,
    parse: (input: AnyUrn | (string & z.BRAND<'session'>)) => {
      if (isSessionId(input)) {
        return input
      }

      const [, , sessionId] = input.split(':')

      if (isSessionId(sessionId)) {
        return sessionId
      }

      throw new Error(
        `Input '${input}' is not in the expected parse urn format.`
      )
    },
  }
)

export type SessionId = ZodSimplify<typeof SessionIdSchema>

/**
 * SurveyId
 */
export const [
  SurveyIdTag,
  SurveyIdSchema,
  SurveyId,
  SurveyUrnSchema,
  SurveyUrn,
] = UniqueIdBuilder('survey')
export type SurveyId = ZodSimplify<typeof SurveyIdSchema>
export type SurveyUrn = ZodSimplify<typeof SurveyUrnSchema>

/**
 * SurveyResponseId
 */
export const [
  SurveyResponseIdTag,
  SurveyResponseIdSchema,
  SurveyResponseId,
  SurveyResponseUrnSchema,
  SurveyResponseUrn,
] = UniqueIdBuilder('survey-response')
export type SurveyResponseId = ZodSimplify<typeof SurveyResponseIdSchema>
export type SurveyResponseUrn = ZodSimplify<typeof SurveyResponseUrnSchema>

/**
 * TagId
 */
export const [TagIdTag, TagIdSchema, TagId, TagUrnSchema, TagUrn] =
  UniqueIdBuilder('tag')
export type TagId = ZodSimplify<typeof TagIdSchema>
export type TagUrn = ZodSimplify<typeof TagUrnSchema>

/**
 * TeamId
 */
export const [TeamIdTag, TeamIdSchema, TeamId, TeamUrnSchema, TeamUrn] =
  UniqueIdBuilder('team')
export type TeamId = ZodSimplify<typeof TeamIdSchema>
export type TeamUrn = ZodSimplify<typeof TeamUrnSchema>

/**
 * TeamProfileId
 */
export const [
  TeamProfileIdTag,
  TeamProfileIdSchema,
  TeamProfileId,
  TeamProfileUrnSchema,
  TeamProfileUrn,
] = UniqueIdBuilder('team-profile')
export type TeamProfileId = ZodSimplify<typeof TeamProfileIdSchema>
export type TeamProfileUrn = ZodSimplify<typeof TeamProfileUrnSchema>

/**
 * ThreadId
 */
export const ThreadIdSchema = OpaqueSchema(
  z.number().int().positive(),
  'thread'
)
export type ThreadId = ZodSimplify<typeof ThreadIdSchema>
export const ThreadId = (threadId: number) => ThreadIdSchema.parse(threadId)

/**
 * UserId
 */
export const [UserIdTag, UserIdSchema, UserId, UserUrnSchema, UserUrn] =
  UniqueIdBuilder('user')
export type UserId = ZodSimplify<typeof UserIdSchema>
export type UserUrn = ZodSimplify<typeof UserUrnSchema>

/**
 * WatchId
 */
export const [WatchIdTag, WatchIdSchema, WatchId, WatchUrnSchema, WatchUrn] =
  UniqueIdBuilder('watch')
export type WatchId = ZodSimplify<typeof WatchIdSchema>
export type WatchUrn = ZodSimplify<typeof WatchUrnSchema>

/**
 * SubjectId
 */
export const [SubjectIdTag, _subjectIdSchema, SubjectId] =
  UniqueIdBuilder('subject')
export const SubjectIdSchema = _subjectIdSchema
  .or(ProjectIdSchema)
  .or(FeedIdSchema)
export type SubjectId = ZodSimplify<typeof SubjectIdSchema>

/**
 * TagListId
 */
export const TagListIdSchema = z.union([
  FileIdSchema,
  PackageIdSchema,
  ProjectIdSchema,
  ServiceIdSchema,
  TeamIdSchema,
  UserIdSchema,
])
export type TagListId = ZodSimplify<typeof TagListIdSchema>

export const idTags = <const>[
  AgreementIdTag,
  ActivityIdTag,
  AgencyIdTag,
  BidIdTag,
  BrandIdTag,
  CommentIdTag,
  DeliverableIdTag,
  FeedIdTag,
  FileIdTag,
  NotificationIdTag,
  OfferIdTag,
  OrganizationIdTag,
  PackageIdTag,
  PackageServiceIdTag,
  ProjectIdTag,
  ProjectServiceIdTag,
  ProofIdTag,
  ReviewIdTag,
  RoundIdTag,
  ServiceIdTag,
  SessionIdTag,
  SurveyIdTag,
  SurveyResponseIdTag,
  TagIdTag,
  TeamIdTag,
  TeamProfileIdTag,
  UserIdTag,
  WatchIdTag,
  SubjectIdTag,
]

export type IdTag = TupleToUnion<typeof idTags>
