import type { TupleToUnion } from 'type-fest'

const themeBackgroundNames = [
  'page',
  'page-secondary',
  'container',
  'container-secondary',
  'container-tertiary',
  'cta',
  'secondary',
  'secondary-inverse',
  'tertiary',
  'lighten',
  'lighten-secondary',
  'default',
  'info',
  'info-muted',
  'positive',
  'caution',
  'negative',
  'notice',
  'transparent',
  'negative-medium',
  'notice-bright',
  'positive-light',
] as const

type ThemeBackground = TupleToUnion<typeof themeBackgroundNames>

const themeHoverBackgroundNames = [
  'cta',
  'primary',
  'secondary',
  'tertiary',
  'text',
  'lighten',
  'info',
  'positive',
  'negative',
  'notice',
  'negative-medium',
  'positive-bright-muted',
  'notice-bright-muted',
] as const
type ThemeHoverBackground = TupleToUnion<typeof themeHoverBackgroundNames>

const themeActiveBackgroundNames = [
  'cta',
  'secondary',
  'text',
  'positive',
  'negative',
  'notice',
  'negative-dark',
  'notice-medium',
] as const
type ThemeActiveBackground = TupleToUnion<typeof themeActiveBackgroundNames>

const themeDisabledBackgroundNames = [
  'cta',
  'secondary',
  'text',
  'positive',
  'negative',
  'notice',
  'info',
  'negative-muted',
  'notice-muted',
  'positive-muted',
] as const
type ThemeDisabledBackground = TupleToUnion<typeof themeDisabledBackgroundNames>

const themeBackgroundMap: Record<ThemeBackground, string> = {
  page: 'bg-blue-800',
  'page-secondary': 'bg-gray-900',
  container: 'bg-black/30',
  'container-secondary': 'bg-black/20',
  'container-tertiary': 'bg-black/10',
  cta: 'bg-primary-blue',
  secondary: 'bg-black/30',
  'secondary-inverse': 'bg-black/60',
  tertiary: 'bg-white/40',
  lighten: 'bg-white/5',
  'lighten-secondary': 'bg-white/20',
  default: 'bg-white/70',
  info: 'bg-primary-blue',
  'info-muted': 'bg-primary-blue/30',
  positive: 'bg-primary-green',
  caution: 'bg-primary-yellow',
  negative: 'bg-primary-red',
  notice: 'bg-primary-purple',
  transparent: 'bg-transparent',
  'negative-medium': 'bg-red-400',
  'positive-light': 'bg-green-200',
  'notice-bright': 'bg-purple-100',
}

const themeHoverBackgroundMap: Record<ThemeHoverBackground, string> = {
  cta: 'hover:bg-blue-200',
  primary: 'hover:bg-black/30',
  secondary: 'hover:bg-black/20',
  tertiary: 'hover:bg-black/10',
  text: 'hover:bg-black/10',
  lighten: 'hover:bg-white/10',
  info: 'hover:bg-primary-blue/30',
  positive: 'hover:bg-green-200',
  negative: 'hover:bg-red-200',
  notice: 'hover:bg-purple-100',
  'negative-medium': 'hover:bg-red-400',
  'positive-bright-muted': 'hover:bg-green-100/10',
  'notice-bright-muted': 'hover:bg-purple-200/10',
}

const themeActiveBackgroundMap: Record<ThemeActiveBackground, string> = {
  cta: 'active:bg-blue-400 data-[active]:bg-blue-400',
  secondary: 'active:bg-black/40 data-[active]:bg-black/40',
  text: 'active:bg-black/20 data-[active]:bg-black/20',
  positive: 'active:bg-green-400 data-[active]:bg-green-400',
  negative: 'active:bg-red-400 data-[active]:bg-red-400',
  notice: 'active:bg-purple-400 data-[active]:bg-purple-400',
  'negative-dark': 'active:bg-red-600 data-[active]:bg-red-600',
  'notice-medium': 'active:bg-purple-300 data-[active]:bg-purple-300',
}

const themeDisabledBackgroundMap: Record<ThemeDisabledBackground, string> = {
  cta: 'disabled:bg-blue-600',
  secondary: 'disabled:bg-black/10',
  text: 'disabled:bg-black/5',
  positive: 'disabled:bg-green-600',
  negative: 'disabled:bg-red-600',
  notice: 'disabled:bg-purple-600',
  info: 'disabled:bg-primary-blue/30',
  'negative-muted': 'disabled:bg-primary-red/30',
  'notice-muted': 'disabled:bg-purple-300/30',
  'positive-muted': 'disabled:bg-primary-green/30',
}

const themeBackgroundBlurs = [
  'blur',
  'blur-xs',
  'blur-sm',
  'blur-md',
  'blur-lg',
  'blur-xl',
  'blur-md-shadow',
  'table-head-blur',
] as const

type ThemeBackgroundBlur = TupleToUnion<typeof themeBackgroundBlurs>

const themeBackgroundBlurMap: Record<ThemeBackgroundBlur, string> = {
  blur: 'backdrop-blur-[1px]',
  'blur-xs': 'backdrop-blur-xs',
  'blur-sm': 'backdrop-blur-sm',
  'blur-md': 'backdrop-blur-md',
  'blur-lg': 'backdrop-blur-lg',
  'blur-xl': 'backdrop-blur-xl',
  'blur-md-shadow': 'backdrop-blur-md shadow-blur',
  'table-head-blur': 'backdrop-blur-table-head',
}

export {
  themeActiveBackgroundMap,
  themeBackgroundBlurMap,
  themeBackgroundBlurs,
  themeBackgroundMap,
  themeBackgroundNames,
  themeDisabledBackgroundMap,
  themeHoverBackgroundMap,
  type ThemeActiveBackground,
  type ThemeBackground,
  type ThemeBackgroundBlur,
  type ThemeDisabledBackground,
  type ThemeHoverBackground,
}
