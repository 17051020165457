import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import {
  archiveMimeTypes,
  audioMimeTypes,
  codeMimeTypes,
  documentMimeTypes,
  fontMimeTypes,
  imageDocumentMimeTypes,
  imageMimeTypes,
  otherMimeTypes,
  videoMimeTypes,
} from './mime-data.ts'
import { MimeTypeBuilder } from './mime-type-builder.ts'

/**
 * Document File Content Types as Extensions
 */
export const [
  documentFileContentTypes,
  documentFileExtensions,
  documentFileContentSchema,
  documentFileExtensionSchema,
] = MimeTypeBuilder(documentMimeTypes)
export type DocumentContentType = ZodSimplify<typeof documentFileContentSchema>
export type DocumentExtension = ZodSimplify<typeof documentFileExtensionSchema>

/**
 * Image File Content Types as Extensions
 */
export const [
  imageFileContentTypes,
  imageFileExtensions,
  imageFileContentSchema,
  imageExtensionsSchema,
] = MimeTypeBuilder(imageMimeTypes)
export type ImageContentType = ZodSimplify<typeof imageFileContentSchema>
export type ImageExtension = ZodSimplify<typeof imageExtensionsSchema>

/**
 * Image Document File Content Types as Extensions
 */
export const [
  imageDocumentFileContentTypes,
  imageDocumentFileExtensions,
  imageDocumentFileContentSchema,
  imageDocumentExtensionsSchema,
] = MimeTypeBuilder(imageDocumentMimeTypes)
export type ImageDocumentContentType = ZodSimplify<
  typeof imageDocumentFileContentSchema
>
export type ImageDocumentExtension = ZodSimplify<
  typeof imageDocumentExtensionsSchema
>

/**
 * Audio File Content Types as Extensions
 */
export const [
  audioFileContentTypes,
  audioFileExtensions,
  audioFileContentSchema,
  audioExtensionsSchema,
] = MimeTypeBuilder(audioMimeTypes)
export type AudioContentType = ZodSimplify<typeof audioFileContentSchema>
export type AudioExtension = ZodSimplify<typeof audioExtensionsSchema>

/**
 * Video File Content Types as Extensions
 */
export const [
  videoFileContentTypes,
  videoFileExtensions,
  videoFileContentSchema,
  videoExtensionsSchema,
] = MimeTypeBuilder(videoMimeTypes)
export type VideoContentType = ZodSimplify<typeof videoFileContentSchema>
export type VideoExtension = ZodSimplify<typeof videoExtensionsSchema>

/**
 * Archive File Content Types as Extensions
 */
export const [
  archiveFileContentTypes,
  archiveFileExtensions,
  archiveFileContentSchema,
  archiveExtensionsSchema,
] = MimeTypeBuilder(archiveMimeTypes)
export type ArchiveContentType = ZodSimplify<typeof archiveFileContentSchema>
export type ArchiveExtension = ZodSimplify<typeof archiveExtensionsSchema>

/**
 * Font File Content Types as Extensions
 */
export const [
  fontFileContentTypes,
  fontFileExtensions,
  fontFileContentSchema,
  fontExtensionsSchema,
] = MimeTypeBuilder(fontMimeTypes)
export type FontContentType = ZodSimplify<typeof fontFileContentSchema>
export type FontExtension = ZodSimplify<typeof fontExtensionsSchema>

/**
 * Code File Content Types as Extensions
 */
export const [
  codeFileContentTypes,
  codeFileExtensions,
  codeFileContentSchema,
  codeExtensionsSchema,
] = MimeTypeBuilder(codeMimeTypes)
export type CodeContentType = ZodSimplify<typeof codeFileContentSchema>
export type CodeExtension = ZodSimplify<typeof codeExtensionsSchema>

/**
 * Other File Content Types as Extensions
 */
export const [
  otherFileContentTypes,
  otherFileExtensions,
  otherFileContentSchema,
  otherExtensionsSchema,
] = MimeTypeBuilder(otherMimeTypes)
export type OtherContentType = ZodSimplify<typeof otherFileContentSchema>
export type OtherExtension = ZodSimplify<typeof otherExtensionsSchema>

/**
 * Combined Content Types as Extensions
 */

export const FileContentTypeSchema = z.enum([
  ...archiveFileContentTypes,
  ...audioFileContentTypes,
  ...codeFileContentTypes,
  ...documentFileContentTypes,
  ...fontFileContentTypes,
  ...imageFileContentTypes,
  ...otherFileContentTypes,
  ...videoFileContentTypes,
  ...imageDocumentFileContentTypes,
])
export type FileContentType = ZodSimplify<typeof FileContentTypeSchema>

/**
 * Combined File Extensions
 */
export const FileExtensionSchema = z.enum([
  ...archiveFileExtensions,
  ...audioFileExtensions,
  ...codeFileExtensions,
  ...documentFileExtensions,
  ...fontFileExtensions,
  ...imageFileExtensions,
  ...otherFileExtensions,
  ...videoFileExtensions,
  ...imageDocumentFileExtensions,
])
export type FileExtension = ZodSimplify<typeof FileExtensionSchema>
