import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  CreditProgramIdSchema,
  CreditProgramMembershipIdSchema,
} from './billing/credit-program.ts'
import {
  OrganizationIdSchema,
  OrganizationProgramIdSchema,
} from './unique-id.models.ts'

/**
 * OrganizationProgramDomainSelectModel
 */
export const OrganizationProgramDomainSelectModelSchema = z.object({
  organizationProgramId: OrganizationProgramIdSchema,
  organizationId: OrganizationIdSchema,
  programId: CreditProgramIdSchema,
  programMembershipId: CreditProgramMembershipIdSchema.optional(),
})

export type OrganizationProgramDomainSelectModel = ZodSimplify<
  typeof OrganizationProgramDomainSelectModelSchema
>

/**
 * OrganizationProgramDomainInsertModel
 */
export const OrganizationProgramDomainInsertModelSchema =
  OrganizationProgramDomainSelectModelSchema.partial({
    organizationProgramId: true,
  })

export type OrganizationProgramDomainInsertModel = ZodSimplify<
  typeof OrganizationProgramDomainInsertModelSchema
>

/**
 * OrganizationProgramDomainUpdateModel
 */
export const OrganizationProgramDomainUpdateModelSchema =
  OrganizationProgramDomainSelectModelSchema.pick({
    programMembershipId: true,
  })

export type OrganizationProgramDomainUpdateModel = ZodSimplify<
  typeof OrganizationProgramDomainUpdateModelSchema
>

/**
 * OrganizationProgramDomainSelectModelFilters
 */
export const OrganizationProgramDomainSelectModelFiltersSchema = z
  .object({
    organizationProgramId: ModelFilterSchema(OrganizationProgramIdSchema),
    organizationId: ModelFilterSchema(OrganizationIdSchema),
    programId: ModelFilterSchema(CreditProgramIdSchema),
  })
  .partial()

export type OrganizationProgramDomainSelectModelFilters = ZodSimplify<
  typeof OrganizationProgramDomainSelectModelFiltersSchema
>
