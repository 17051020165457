import type { ThemeFlexParent, ThemeGap } from '@mntn-dev/ui-theme'
import { type PropsWithChildren, type RefAttributes, forwardRef } from 'react'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import { Stack } from '../stack/stack.tsx'

type ChipsProps = Readonly<
  PropsWithChildren<
    TestIds &
      RefAttributes<HTMLDivElement> &
      Pick<ThemeFlexParent, 'direction'> &
      Pick<ThemeGap, 'gap'> & { className?: string }
  >
>

const Chips = forwardRef<HTMLDivElement, ChipsProps>(
  (
    {
      children,
      className,
      dataTestId,
      dataTrackingId,
      direction,
      gap = '2',
    }: ChipsProps,
    ref
  ) => {
    return (
      <Stack
        direction={direction}
        gap={gap}
        className={className}
        ref={ref}
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        {children}
      </Stack>
    )
  }
)

export { Chips }
