import type { ThemeBackground, ThemeBorderColor } from '@mntn-dev/ui-theme'
import type { PropsWithChildren } from 'react'
import type { TupleToUnion } from 'type-fest'
import type { TestIds } from '../../utils/testing.ts'
import type { BadgeProps, BadgeSize } from '../badge/types.ts'
import type { DecoratorProps } from '../decorator/decorator.tsx'
import type { IconName } from '../icon/index.ts'

export const AvatarSizes = ['xs', 'sm', 'med', 'lg', 'xl', '2xl'] as const
export type AvatarSize = TupleToUnion<typeof AvatarSizes>

export type AvatarDecoratorType = 'add' | 'remove'

export type AvatarBorderColor = Exclude<
  Extract<ThemeBorderColor, ThemeBackground>,
  'transparent' | 'notice-bright'
>

export type UseAvatarProps = TestIds & {
  borderColor?: AvatarBorderColor
  decoratorType?: AvatarDecoratorType
  size?: AvatarSize
}

export type AvatarProps = PropsWithChildren<
  UseAvatarProps & {
    className?: string
    empty?: boolean
    onClick?: () => void
    selected?: boolean
  }
>

export type AvatarImageLoadState = 'loading' | 'loaded' | 'error' | 'no-url'

export const avatarPixelSizeMap: Record<AvatarSize, number> = {
  xs: 24,
  sm: 32,
  med: 48,
  lg: 64,
  xl: 96,
  '2xl': 128,
}

export const avatarSizeMap: Record<AvatarSize, string> = {
  xs: 'h-6 w-6',
  sm: 'h-10 w-10',
  med: 'h-12 w-12',
  lg: 'h-16 w-16',
  xl: 'h-[84px] w-[84px]',
  '2xl': 'h-[116px] w-[116px]',
}

export const avatarTextSizeMap: Record<AvatarSize, string> = {
  xs: 'text-xs',
  sm: 'text-lg',
  med: 'text-xl',
  lg: 'text-2xl',
  xl: 'text-3xl',
  '2xl': 'text-[54px]',
}

export const decoratorSizeMap: Record<AvatarSize, string> = {
  xs: 'h-4 w-4',
  sm: 'h-5 w-5',
  med: 'h-6 w-6',
  lg: 'h-6 w-6',
  xl: 'h-7 w-7',
  '2xl': 'h-7 w-7',
}

export const badgePositionMap: Record<
  AvatarSize,
  Pick<DecoratorProps, 'offsetX' | 'offsetY'>
> = {
  xs: { offsetX: '-2', offsetY: '-2' },
  sm: { offsetX: '-2', offsetY: '-2' },
  med: { offsetX: '-2', offsetY: '-2' },
  lg: { offsetX: '-1.5', offsetY: '-1.5' },
  xl: { offsetX: '-1', offsetY: '-1' },
  '2xl': { offsetX: '0', offsetY: '0' },
}

export const badgeSizeMap: Record<AvatarSize, BadgeSize> = {
  xs: 'xs',
  sm: 'sm',
  med: 'md',
  lg: 'md',
  xl: 'lg',
  '2xl': 'lg',
}

export type AvatarDecoratorProps = Required<
  { name: IconName; type: AvatarDecoratorType } & Pick<
    BadgeProps,
    'backgroundColor' | 'color'
  >
>

export const avatarDecoratorIconMap: Record<
  AvatarDecoratorType,
  AvatarDecoratorProps
> = {
  add: {
    name: 'EditIcon',
    type: 'add',
    backgroundColor: 'container',
    color: 'primary',
  },
  remove: {
    name: 'XMarkIcon',
    type: 'remove',
    backgroundColor: 'negative',
    color: 'primary',
  },
}

export type AvatarEntity = {
  avatarUrl?: string
  displayName: string
  initials: string
}
