'use client'

import type { Person } from '../../types/person.ts'
import {
  AvatarEntityComponent,
  type AvatarEntityProps,
} from './avatar-entity.tsx'

type AvatarPersonProps = Readonly<
  {
    person: Person
  } & Omit<AvatarEntityProps, 'entity'>
>

const AvatarPerson = ({ person, ...avatarProps }: AvatarPersonProps) => {
  return <AvatarEntityComponent {...avatarProps} entity={person} />
}

export { AvatarPerson, type AvatarPersonProps }
