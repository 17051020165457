import type { i18n } from 'i18next'
import type { Formatter } from '../types.ts'

export const addFormatter = <TIn, TOptions>(
  i18next: i18n,
  name: string,
  formatter: Formatter<TIn, TOptions>
) => {
  i18next.services.formatter?.add(name, formatter)
}
