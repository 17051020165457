import { z } from 'zod'

import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import type { SetOptional } from 'type-fest'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  CustomerOrganizationTypeSchema,
  OrganizationTypeSchema,
} from './organization.models.ts'
import {
  CompanyNameSchema,
  EmailAddressSchema,
  NameSchema,
  NonEmptyStringSchema,
  TimestampSchema,
  USPhoneNumberSchema,
  WebsiteUrlSchema,
} from './property.models.ts'
import { TagFilterSchema } from './tag.models.ts'
import {
  FileIdSchema,
  OrganizationIdSchema,
  TeamIdSchema,
  UserIdSchema,
  UserUrnSchema,
} from './unique-id.models.ts'

/**
 * UserAuthConnection
 */
export const [
  UserAuthConnections,
  UserAuthConnectionSchema,
  UserAuthConnectionEnum,
] = EnumBuilder('email', 'Username-Password-Authentication').catch('email')

export type UserAuthConnection = ZodSimplify<typeof UserAuthConnectionSchema>

/**
 * Bio
 */
export const BioSchema = z.string().trim().max(4096)
export type Bio = ZodSimplify<typeof BioSchema>

/**
 * UserDomainSelectModel
 */
export const UserDomainSelectModelSchema = z.object({
  userId: UserIdSchema,
  userUrn: UserUrnSchema,
  organizationType: OrganizationTypeSchema,
  organizationId: OrganizationIdSchema,
  emailAddress: EmailAddressSchema,
  firstName: NameSchema,
  lastName: NameSchema,
  displayName: z.string(),
  initials: z.string(),
  avatarFileId: FileIdSchema.optional(),
  bio: BioSchema.optional(),
  websiteUrls: WebsiteUrlSchema.array().optional(),
  phoneNumbers: USPhoneNumberSchema.array().optional(), // TODO: QF-2463 - Support international phone numbers
  authConnection: UserAuthConnectionSchema,
  isActive: z.boolean(),
  lastLogin: TimestampSchema.optional(),
  signUpTimestamp: TimestampSchema,
})

export type UserDomainSelectModel = ZodSimplify<
  typeof UserDomainSelectModelSchema
>

/**
 * UserDisplayModel
 */
export const UserDisplayModelSchema = UserDomainSelectModelSchema.pick({
  organizationType: true,
  firstName: true,
  lastName: true,
  displayName: true,
  initials: true,
  avatarFileId: true,
})

export type UserDisplayModel = ZodSimplify<typeof UserDisplayModelSchema>

export type UserDisplayModelComputedProperties = keyof Pick<
  UserDisplayModel,
  'displayName' | 'initials'
>

export const toUserDisplayModel = ({
  firstName,
  lastName,
  displayName,
  initials,
  avatarFileId,
  organizationType,
}: SetOptional<
  UserDisplayModel,
  UserDisplayModelComputedProperties
>): UserDisplayModel => {
  const getDisplayName = () =>
    displayName ?? `${firstName.trim()} ${lastName.trim()}`

  const getInitials = () => {
    const [first = '-'] = firstName.trim()
    const [last = '-'] = lastName.trim()
    return initials ?? `${first}${last}`.toUpperCase()
  }

  return UserDisplayModelSchema.parse({
    firstName,
    lastName,
    displayName: getDisplayName(),
    initials: getInitials(),
    avatarFileId,
    organizationType,
  })
}

/**
 * UserDomainInsertModel
 */
export const UserDomainInsertModelSchema = UserDomainSelectModelSchema.omit({
  userUrn: true,
  isActive: true,
  displayName: true,
  initials: true,
  authConnection: true,
  lastLogin: true,
  signUpTimestamp: true,
}).partial({
  userId: true,
})

export type UserDomainInsertModel = ZodSimplify<
  typeof UserDomainInsertModelSchema
>
/**
 * UserDomainRegistrationModel
 * userId and emailAddress values are extracted from the session object
 */
export const UserDomainRegistrationModelSchema =
  UserDomainInsertModelSchema.omit({
    userId: true,
    emailAddress: true,
  })

export type UserDomainRegistrationModel = ZodSimplify<
  typeof UserDomainRegistrationModelSchema
>

/**
 * UserDomainUpdateModel
 */
export const UserDomainUpdateModelSchema = UserDomainSelectModelSchema.pick({
  firstName: true,
  lastName: true,
  emailAddress: true,
  bio: true,
  websiteUrls: true,
  phoneNumbers: true,
  authConnection: true,
  isActive: true,
  lastLogin: true,
})
  // Certain properties are nullable so they can be removed
  .extend({
    avatarFileId: UserDomainSelectModelSchema.shape.avatarFileId.nullable(),
  })
  .partial()

export type UserDomainUpdateModel = ZodSimplify<
  typeof UserDomainUpdateModelSchema
>

/**
 * CustomerUserDomainUpdateModel
 */
export const CustomerUserDomainUpdateModelSchema =
  UserDomainUpdateModelSchema.extend({
    organizationType: CustomerOrganizationTypeSchema,
  }).partial()

/**
 * UserDomainSelectModelFilters
 */
export const UserDomainSelectModelFiltersSchema = z
  .object({
    userId: ModelFilterSchema(UserIdSchema),
    userUrn: ModelFilterSchema(UserUrnSchema),
    organizationType: ModelFilterSchema(OrganizationTypeSchema),
    organizationId: ModelFilterSchema(OrganizationIdSchema),
    // When filtering, transform email addresses to lower case so the
    // "lower(users.email_address)" unique index can be used.
    emailAddress: ModelFilterSchema(EmailAddressSchema.toLowerCase()),
    search: NonEmptyStringSchema(),
    includeDeactivated: z.boolean(),
    tags: TagFilterSchema,
  })
  .partial()

export type UserDomainSelectModelFilters = ZodSimplify<
  typeof UserDomainSelectModelFiltersSchema
>

/**
 * TODO: The following schemas/types should probably be moved to the user-service package
 */
export const UserCompanyInsertUpdateSchema = z.object({
  userId: UserIdSchema.optional(),
  organizationType: OrganizationTypeSchema,
  firstName: NameSchema,
  lastName: NameSchema,
  emailAddress: EmailAddressSchema.optional(),
  companyName: CompanyNameSchema.optional(),
  teamId: TeamIdSchema.optional(),
  bio: BioSchema.optional(),
  websiteUrls: WebsiteUrlSchema.array().optional(),
  phoneNumbers: USPhoneNumberSchema.array().optional(),
})

export type UserCompanyInsertUpdateType = ZodSimplify<
  typeof UserCompanyInsertUpdateSchema
>

export const UserDomainSelectModelWithDeactivateStatusSchema =
  UserDomainSelectModelSchema.extend({
    canBeDeactivated: z.boolean(),
  })

export type UserDomainSelectModelWithDeactivateStatusType = ZodSimplify<
  typeof UserDomainSelectModelWithDeactivateStatusSchema
>
