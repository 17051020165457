import { z } from 'zod'
import type { ZodSimplify } from './utility-types.ts'

// a more strict version of the hex color string is too complex for TypeScript
type HexColorTemplate = `#${string}`

const hexRegex = /^#[0-9A-F]{6}$/i

export const isHexColorString = (value: string): value is HexColorTemplate => {
  return hexRegex.test(value)
}

export const HexColorSchema = z.string().refine(isHexColorString, {
  message: 'Invalid hex color.',
})

export type HexColorString = ZodSimplify<typeof HexColorSchema>
