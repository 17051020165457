import { z } from 'zod'

import {
  OfferIdSchema,
  ProjectIdSchema,
  UserIdSchema,
} from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectOfferPendingActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_offer_pending',
    z.object({
      projectId: ProjectIdSchema,
      offerId: OfferIdSchema,
      acceptorId: UserIdSchema,
      ...ActivityLinkTargets('offer'),
    })
  )
