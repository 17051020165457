import { type ThemeGap, getGapClassName } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'

export const FieldGridColumnCountSchema = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
  z.literal(5),
  z.literal(6),
])
export type FieldGridColumnCount = ZodSimplify<
  typeof FieldGridColumnCountSchema
>

export type UseFieldGridProps = ThemeGap & {
  columnCount?: FieldGridColumnCount
}

const columnCountClassNameMap: Record<FieldGridColumnCount, string> = {
  1: 'grid-cols-1 sm:grid-cols-1',
  2: 'grid-cols-1 sm:grid-cols-2',
  3: 'grid-cols-1 sm:grid-cols-3',
  4: 'grid-cols-1 sm:grid-cols-4',
  5: 'grid-cols-1 sm:grid-cols-5',
  6: 'grid-cols-1 sm:grid-cols-6',
}

// Sets a 6-column grid layout for form/data-list fields
export function useFieldGrid(props?: UseFieldGridProps) {
  const gapClassName = getGapClassName(props)
  const columnCountClassName = columnCountClassNameMap[props?.columnCount ?? 6]
  return {
    fieldGridClassName: cn(
      'grid gap-y-8 gap-x-4',
      columnCountClassName,
      gapClassName
    ),
  }
}
