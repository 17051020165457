'use client'

import type { ReactNode } from 'react'
import { Button } from '../../buttons'
import { FormField } from '../../form-field'
import { Stack } from '../../stack'
import { Modal, type ModalProps } from '../modal.tsx'

type FieldModalProps = ModalProps &
  Readonly<{
    open: boolean
    title: string
    label?: ReactNode
    t: (key: 'save-and-close' | 'cancel' | 'close') => string
    onSave?: () => void
    readonly?: boolean
  }>

const FieldModal = ({
  title,
  label,
  onSave,
  onClose,
  readonly,
  children,
  t,
  ...props
}: FieldModalProps) => {
  return (
    <Modal {...{ ...props, onClose }} margin="0">
      <Stack
        direction="col"
        width="full"
        maxWidth="222"
        height="screen"
        paddingTop="16"
        paddingBottom="12"
      >
        <Modal.Overline>
          <Modal.Heading>{title}</Modal.Heading>
        </Modal.Overline>
        <Modal.Surface size="full">
          <Modal.Surface.Body size="full">
            <FormField className="h-full w-full">
              {label && <FormField.Label>{label}</FormField.Label>}
              {children}
            </FormField>
          </Modal.Surface.Body>
          <Modal.Surface.Footer>
            {!readonly && (
              <Button onClick={onSave} width="full" className="mb-2">
                {t('save-and-close')}
              </Button>
            )}
            <Button variant="secondary" onClick={onClose} width="full">
              {t(readonly ? 'close' : 'cancel')}
            </Button>
          </Modal.Surface.Footer>
        </Modal.Surface>
      </Stack>
    </Modal>
  )
}

export { FieldModal, type FieldModalProps }
