import { z } from 'zod'
import { ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectBiddingUnsuccessfulActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_bidding_unsuccessful',
    z.object({
      projectId: ProjectIdSchema,
      ...ActivityLinkTargets('project'),
    })
  )
