'use client'

import { RadioGroup as RadioGroupComponent } from '@headlessui/react'
import { forwardRef, type ReactNode } from 'react'
import { type TestIds, getTestProps } from '../../utils'
import { RadioBlock } from './radio-block'
import { RadioButton } from './radio-button'
import { RadioGroupProvider, useRadioGroup } from './use-radio-group'

type RadioGroupProps = Readonly<
  TestIds & {
    children: Array<ReactNode>
    disabled?: boolean
    value?: string | null
    onChange: (value: string) => void
  }
>

const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      disabled,
      value,
      children,
      dataTestId,
      dataTrackingId,
      onChange,
    }: RadioGroupProps,
    ref
  ) => {
    const radioGroup = useRadioGroup({ value })

    return (
      <RadioGroupProvider value={radioGroup}>
        <RadioGroupComponent
          ref={ref}
          className="flex flex-row gap-4"
          disabled={disabled}
          value={value}
          onChange={onChange}
          {...getTestProps({ dataTestId, dataTrackingId })}
        >
          {children}
        </RadioGroupComponent>
      </RadioGroupProvider>
    )
  }
)

const RadioNamespace = Object.assign(RadioGroup, {
  Item: RadioBlock,
  Button: RadioButton,
})

export { RadioNamespace as RadioGroup, type RadioGroupProps }
