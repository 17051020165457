import {
  type UserDisplayModelComputedProperties,
  UserDisplayModelSchema,
  toUserDisplayModel,
} from '@mntn-dev/domain-types'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import type { SetOptional } from 'type-fest'
import { z } from 'zod'

export const PersonSchema = UserDisplayModelSchema.extend({
  avatarUrl: z.string().optional(),
})

export type Person = ZodSimplify<typeof PersonSchema>

export const toPerson = ({
  avatarUrl,
  ...person
}: SetOptional<Person, UserDisplayModelComputedProperties>) =>
  <const>{
    ...toUserDisplayModel(person),
    avatarUrl,
  }
