import { z } from 'zod'

import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { NoteSchema } from './property.models.ts'
import {
  DeliverableIdSchema,
  FileIdSchema,
  ProofIdSchema,
  RoundIdSchema,
} from './unique-id.models.ts'

/**
 * ProofStatus
 */
export const [ProofStatuses, ProofStatusSchema, ProofStatusEnum] = EnumBuilder(
  'approved',
  'changes_requested'
)
export type ProofStatus = ZodSimplify<typeof ProofStatusSchema>

/**
 * Proof Proposal
 */
export const ProofProposalSchema = z.object({ notes: NoteSchema }).partial()

export type ProofProposal = ZodSimplify<typeof ProofProposalSchema>

/**
 * Proof Feedback
 */
export const ProofFeedbackSchema = z.object({ notes: NoteSchema }).partial()

export type ProofFeedback = ZodSimplify<typeof ProofFeedbackSchema>

/**
 * ProofDomainSelectModel
 */
export const ProofDomainSelectModelSchema = z.object({
  proofId: ProofIdSchema,
  roundId: RoundIdSchema,
  proposal: ProofProposalSchema,
  feedback: ProofFeedbackSchema,
  deliverableId: DeliverableIdSchema,
  fileId: FileIdSchema.optional(),
  status: ProofStatusSchema.optional(),
})

export type ProofDomainSelectModel = ZodSimplify<
  typeof ProofDomainSelectModelSchema
>

/**
 * ProofDomainInsertModel
 */
export const ProofDomainInsertModelSchema = ProofDomainSelectModelSchema.omit({
  proposal: true,
  feedback: true,
}).partial({
  proofId: true,
})

export type ProofDomainInsertModel = ZodSimplify<
  typeof ProofDomainInsertModelSchema
>

/**
 * ProofDomainUpdateModel
 */
export const ProofDomainUpdateModelSchema = z
  .object({
    status: ProofStatusSchema.nullable(),
    fileId: FileIdSchema.nullable(),
    proposal: ProofProposalSchema.merge(
      z.object({ notes: NoteSchema.nullable() }).partial()
    ),
    feedback: ProofProposalSchema.merge(
      z.object({ notes: NoteSchema.nullable() }).partial()
    ),
  })
  .partial()

export type ProofDomainUpdateModel = ZodSimplify<
  typeof ProofDomainUpdateModelSchema
>

/**
 * ProofDomainSelectModelFilters
 */
export const ProofDomainSelectModelFiltersSchema = z
  .object({
    proofId: ModelFilterSchema(ProofIdSchema),
    roundId: ModelFilterSchema(RoundIdSchema),
    deliverableId: ModelFilterSchema(DeliverableIdSchema),
    fileId: ModelFilterSchema(FileIdSchema),
    status: ModelFilterSchema(ProofStatusSchema),
  })
  .partial()

export type ProofDomainSelectModelFilters = ZodSimplify<
  typeof ProofDomainSelectModelFiltersSchema
>
