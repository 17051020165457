import { type ThemeGapValue, columnGapMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { Children, type PropsWithChildren } from 'react'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import {
  BreadcrumbDivider,
  type BreadcrumbDividerProps,
} from './breadcrumb-divider.tsx'

export type BreadcrumbsProps = PropsWithChildren<
  BreadcrumbDividerProps & TestIds & { gap?: ThemeGapValue }
>

const Breadcrumbs = ({
  children,
  dataTestId,
  dataTrackingId,
  gap = '1',
  ...dividerProps
}: BreadcrumbsProps) => {
  const count = Children.count(children)
  const gapClassName = columnGapMap[gap]

  return (
    <div
      className={cn('inline-flex items-center', gapClassName)}
      {...getTestProps({ dataTestId, dataTrackingId })}
    >
      {Children.map(children, (child, index) => (
        <>
          {child}
          {index < count - 1 && (
            <BreadcrumbDivider
              key={`breadcrumb-divider-${index + 1}`}
              {...dividerProps}
            />
          )}
        </>
      ))}
    </div>
  )
}

export { Breadcrumbs }
