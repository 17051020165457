'use client'

import type { PropsWithChildren } from 'react'
import { type TestIds, getTestProps } from '../../utils/testing.ts'
import { CollapsibleButton } from './collapsible-button.tsx'
import { CollapsibleComment } from './collapsible-comment.tsx'
import { CollapsiblePanel } from './collapsible-panel.tsx'
import { CollapsibleTitle } from './collapsible-title.tsx'
import { cn } from '@mntn-dev/ui-utilities'
import { CollapsibleProvider, useCollapsible } from './use-collapsible.ts'

type CollapsibleProps = PropsWithChildren<
  {
    isOpen?: boolean
    className?: string
  } & TestIds
>

const Collapsible = ({
  isOpen = true,
  className,
  children,
  dataTestId,
  dataTrackingId,
}: CollapsibleProps) => {
  const collapsibleContext = useCollapsible({
    isOpen,
    dataTestId,
    dataTrackingId,
  })

  return (
    <CollapsibleProvider value={collapsibleContext}>
      <div
        className={cn('w-full h-full', className)}
        {...getTestProps({ dataTestId, dataTrackingId })}
      >
        {children}
      </div>
    </CollapsibleProvider>
  )
}

const CollapsibleNamespace = Object.assign(Collapsible, {
  Button: CollapsibleButton,
  Comment: CollapsibleComment,
  Panel: CollapsiblePanel,
  Title: CollapsibleTitle,
})

export { CollapsibleNamespace as Collapsible }
