import { z } from 'zod'

import { uuid } from '@mntn-dev/utilities'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import type { AnyUrn } from './types.ts'

export const UuidSchema = z.string().uuid()

export type Uuid = ZodSimplify<typeof UuidSchema>

const EmptyUuid = '00000000-0000-0000-0000-000000000000'

export const OpaqueSchema = <Schema extends z.ZodTypeAny, Tag extends string>(
  schema: Schema,
  tag: Tag
) => schema.brand(tag)

/**
 * @param nid a unique string used to tag the identifiers with
 * @returns An opaque/tagged type that prevents otherwise indistinguishable UUID strings from being used interchangeably.
 * For example, a ProjectId is distinguishable from a BrandId even though they are both just UUIDs and you can't
 * pass one as an argument to a function that expects the other.
 *
 * A lot of this is based of the URN format of urn:nid:nss where nid is the namespace identifier and nss is the namespace specific string
 */
export const UniqueIdBuilder = <NID extends string>(nid: NID) => {
  /**
   * The schema for the UUID string tagged with the NID
   */
  const nssSchema = OpaqueSchema(UuidSchema, nid)

  const isNss = (value: unknown): value is NSS => {
    return nssSchema.safeParse(value).success
  }

  /**
   * @param nss a UUID string
   * @returns a tagged UUID string
   */
  const nssBuilder = Object.assign((nss = uuid()) => nssSchema.parse(nss), {
    Empty: nssSchema.parse(EmptyUuid) as typeof EmptyUuid & z.BRAND<NID>,
    check: isNss,
    parse: (input: AnyUrn | NSS) => {
      if (isNss(input)) {
        return input
      }

      const [, , nss] = input.split(':')

      if (isNss(nss)) {
        return nss
      }

      throw new Error(
        `Input '${input}' is not in the expected parse urn format.`
      )
    },
  })

  type NSS = ZodSimplify<typeof nssSchema>
  type URN = `urn:${NID}:${NSS}`

  /**
   * The schema for the URN string tagged with the NID
   */
  const urnSchema = z.custom<URN>((input) => {
    if (typeof input === 'string') {
      // IIFE to parse the input string into its urn components
      const urn = (() => {
        const [prefix, nid, nss, ...extra] = input.split(':')
        return { prefix, nid, nss, exact: extra.length === 0 }
      })()

      return (
        urn.prefix === 'urn' &&
        urn.nid === nid &&
        nssSchema.safeParse(urn.nss).success &&
        urn.exact
      )
    }
    return false
  })

  /**
   *
   * @param nss a UUID string
   * @returns a URN string
   */
  const urnBuilder = (nss: NSS = nssBuilder()): URN =>
    `urn:${nid}:${nssSchema.parse(nss)}`

  /**
   * Return the generated functions and schemas as a tuple so they can be destructured
   */
  return <const>[nid, nssSchema, nssBuilder, urnSchema, urnBuilder]
}
