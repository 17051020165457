import { cn } from '@mntn-dev/ui-utilities'

import {
  type FontSize,
  type FontWeight,
  type ThemeActiveBackground,
  type ThemeActiveBorderColor,
  type ThemeActiveTextColor,
  type ThemeBackground,
  type ThemeBorderColor,
  type ThemeDisabledBackground,
  type ThemeDisabledBorderColor,
  type ThemeDisabledTextColor,
  type ThemeElevation,
  type ThemeHeightOption,
  type ThemeHoverBackground,
  type ThemeHoverBorderColor,
  type ThemeHoverGlow,
  type ThemeHoverTextColor,
  type ThemePadding,
  type ThemeTextColor,
  type ThemeWidthOption,
  elevationMap,
  fontSizeMap,
  fontWeightMap,
  getHeightClassName,
  getPaddingClassName,
  getWidthClassName,
  themeActiveBackgroundMap,
  themeActiveBorderColorMap,
  themeActiveTextColorMap,
  themeBackgroundMap,
  themeBorderColorMap,
  themeDisabledBackgroundMap,
  themeDisabledBorderColorMap,
  themeDisabledTextColorMap,
  themeHoverBackgroundMap,
  themeHoverBorderColorMap,
  themeHoverGlowMap,
  themeHoverTextColorMap,
  themeTextColorMap,
} from '@mntn-dev/ui-theme'
import type { TupleToUnion } from 'type-fest'
import type { IconSize } from '../icon'
import { useMemo } from 'react'

const buttonColorThemeNames = [
  'default',
  'negative',
  'notice',
  'positive',
] as const
type ButtonColorTheme = TupleToUnion<typeof buttonColorThemeNames>

const buttonSizeNames = ['xs', 'sm', 'md', 'lg'] as const
type ButtonSize = TupleToUnion<typeof buttonSizeNames>

const buttonVariantNames = [
  'primary',
  'secondary',
  'text',
  'destructive',
  'radio',
] as const
type ButtonVariant = TupleToUnion<typeof buttonVariantNames>

type UseButtonStylesProps = {
  borderColor?: ThemeBorderColor
  iconColor?: ThemeTextColor
  textColor?: ThemeTextColor
  size: ButtonSize
  variant: ButtonVariant
  circular?: boolean
  colorTheme?: ButtonColorTheme
  readonly: boolean
}

const sizeClassMap: Record<
  ButtonSize,
  {
    fontSize: FontSize
    fontWeight: FontWeight
    iconSize: IconSize
    height: Extract<ThemeHeightOption, ThemeWidthOption>
  } & Required<Pick<ThemePadding, 'paddingX'>>
> = {
  xs: {
    fontSize: 'sm',
    fontWeight: 'medium',
    iconSize: 'sm',
    height: '7',
    paddingX: '2',
  },
  sm: {
    fontSize: 'sm',
    fontWeight: 'medium',
    iconSize: 'sm',
    height: '10',
    paddingX: '4',
  },
  md: {
    fontSize: 'med',
    fontWeight: 'medium',
    iconSize: 'md',
    height: '12',
    paddingX: '4',
  },
  lg: {
    fontSize: 'med',
    fontWeight: 'medium',
    iconSize: 'md',
    height: '14',
    paddingX: '6',
  },
}

const getSizeClassName = (size: ButtonSize, circular?: boolean) => {
  const { fontSize, fontWeight, height, paddingX } = sizeClassMap[size]

  return cn(
    fontSizeMap[fontSize],
    fontWeightMap[fontWeight],
    getHeightClassName({ height }),
    {
      [getPaddingClassName({ paddingX }) ?? '']: !circular,
      [getWidthClassName({ width: height }) ?? '']: circular,
    }
  )
}

const filterReadonlyStyles = (styles: VariantProps, readonly: boolean) => {
  if (!readonly) {
    return styles
  }

  const {
    backgroundHover,
    backgroundActive,
    borderHoverColor,
    borderActiveColor,
    hoverTextColor,
    activeTextColor,
    glowHover,
    ...filteredStyles
  } = styles

  return filteredStyles
}

type VariantProps = {
  background?: ThemeBackground
  backgroundHover?: ThemeHoverBackground
  backgroundActive?: ThemeActiveBackground
  backgroundDisabled?: ThemeDisabledBackground
  border?: boolean
  borderColor?: ThemeBorderColor
  borderHoverColor?: ThemeHoverBorderColor
  borderActiveColor?: ThemeActiveBorderColor
  borderDisabledColor?: ThemeDisabledBorderColor
  textColor?: ThemeTextColor
  activeTextColor?: ThemeActiveTextColor
  hoverTextColor?: ThemeHoverTextColor
  disabledTextColor?: ThemeDisabledTextColor
  iconColor?: ThemeTextColor
  elevation?: ThemeElevation
  glowHover?: ThemeHoverGlow
}

const getPrimaryVariantPropsByColor = (
  colorTheme: ButtonColorTheme,
  readonly: boolean
): VariantProps => {
  const props = {
    positive: {
      background: 'positive',
      backgroundHover: 'positive',
      backgroundActive: 'positive',
      backgroundDisabled: 'positive-muted',
      border: true,
      borderColor: 'positive-bright',
      borderHoverColor: 'positive-extra-bright',
      borderActiveColor: 'positive-dark',
      borderDisabledColor: 'positive-extra-bright',
      textColor: 'primary-inverse',
      disabledTextColor: 'inverse',
      elevation: 'sm',
      glowHover: 'positive',
    },
    negative: {
      background: 'negative-medium',
      backgroundHover: 'negative-medium',
      backgroundActive: 'negative-dark',
      backgroundDisabled: 'negative-muted',
      border: true,
      borderColor: 'negative-bright',
      borderHoverColor: 'negative-bright',
      borderActiveColor: 'negative-dark',
      borderDisabledColor: 'negative-extra-bright',
      textColor: 'primary',
      disabledTextColor: 'primary',
      elevation: 'sm',
      glowHover: 'negative',
    },
    notice: {
      background: 'notice',
      backgroundHover: 'notice',
      backgroundActive: 'notice',
      backgroundDisabled: 'notice-muted',
      border: true,
      borderColor: 'notice-bright',
      borderHoverColor: 'notice-extra-bright',
      borderActiveColor: 'notice-dark',
      borderDisabledColor: 'notice-extra-bright',
      textColor: 'primary-inverse',
      disabledTextColor: 'inverse',
      elevation: 'sm',
      glowHover: 'notice',
    },
    default: {
      background: 'info',
      backgroundHover: 'cta',
      backgroundActive: 'cta',
      backgroundDisabled: 'info',
      border: true,
      borderColor: 'medium',
      borderHoverColor: 'cta',
      borderActiveColor: 'cta',
      borderDisabledColor: 'cta',
      textColor: 'primary-inverse',
      disabledTextColor: 'inverse',
      elevation: 'sm',
      glowHover: 'info',
    },
  }

  return filterReadonlyStyles(
    (props[colorTheme] as VariantProps) ?? {},
    readonly
  )
}

const getSecondaryVariantProps = (readonly: boolean) => {
  const props = {
    background: 'secondary',
    backgroundHover: 'secondary',
    backgroundActive: 'secondary',
    backgroundDisabled: 'secondary',
    border: true,
    borderColor: 'muted',
    borderHoverColor: 'secondary',
    borderActiveColor: 'secondary',
    borderDisabledColor: 'secondary',
    textColor: 'primary',
    disabledTextColor: 'primary',
    iconColor: 'info',
    elevation: 'sm',
  }

  return filterReadonlyStyles(props as VariantProps, readonly)
}

const getTextraVariantProps = (readonly: boolean) => {
  const props = {
    backgroundHover: 'text',
    backgroundActive: 'text',
    backgroundDisabled: 'text',
    border: true,
    borderColor: 'transparent',
    borderHoverColor: 'transparent',
    borderActiveColor: 'secondary',
    borderDisabledColor: 'secondary',
    textColor: 'secondary',
    activeTextColor: 'primary',
    hoverTextColor: 'primary',
    disabledTextColor: 'primary',
  }

  return filterReadonlyStyles(props as VariantProps, readonly)
}

const getRadioVariantPropsByColor = (
  colorTheme: ButtonColorTheme,
  readonly: boolean
): VariantProps => {
  const props = {
    positive: {
      background: 'secondary',
      backgroundHover: 'positive-bright-muted',
      backgroundActive: 'secondary',
      backgroundDisabled: 'secondary',
      border: true,
      borderColor: 'positive-bright',
      borderHoverColor: 'positive-extra-bright',
      borderActiveColor: 'positive-dark',
      borderDisabledColor: 'positive-extra-bright',
      textColor: 'primary',
      disabledTextColor: 'primary',
      elevation: 'sm',
      glowHover: 'positive-inner',
    },
    negative: {},
    notice: {
      background: 'secondary',
      backgroundHover: 'notice-bright-muted',
      backgroundActive: 'secondary',
      backgroundDisabled: 'secondary',
      border: true,
      borderColor: 'notice-bright',
      borderHoverColor: 'notice-extra-bright',
      borderActiveColor: 'notice-dark',
      borderDisabledColor: 'notice-extra-bright',
      textColor: 'primary',
      disabledTextColor: 'primary',
      elevation: 'sm',
      glowHover: 'notice-inner',
    },
    default: {},
  }

  return filterReadonlyStyles(
    (props[colorTheme] as VariantProps) ?? {},
    readonly
  )
}

const getVariantProps = (
  variant: ButtonVariant,
  colorTheme: ButtonColorTheme,
  readonly: boolean
): VariantProps => {
  switch (variant) {
    case 'destructive':
      return getPrimaryVariantPropsByColor('negative', readonly)
    case 'primary':
      return getPrimaryVariantPropsByColor(colorTheme, readonly)
    case 'secondary':
      return getSecondaryVariantProps(readonly)
    case 'text':
      return getTextraVariantProps(readonly)
    case 'radio':
      return getRadioVariantPropsByColor(colorTheme, readonly)
    default:
      return {}
  }
}

const getVariantClassName = (
  variant: ButtonVariant,
  readonly: boolean,
  textColor?: ThemeTextColor,
  borderColor?: ThemeBorderColor,
  colorTheme?: ButtonColorTheme
) => {
  const variantProps: VariantProps = {
    ...getVariantProps(variant, colorTheme ?? 'default', readonly),
    ...(textColor && {
      textColor,
      hoverTextColor: undefined,
      activeTextColor: undefined,
      disabledTextColor: undefined,
    }),
    ...(borderColor && {
      borderColor,
      borderHoverColor: undefined,
      borderActiveColor: undefined,
      borderDisabledColor: undefined,
    }),
  }

  return cn(
    getBackgroundClassNames(variantProps, readonly),
    getBorderClassNames(variantProps, readonly),
    getTextClassNames(variantProps, readonly),
    getElevationClassName(variantProps),
    !readonly && getGlowHoverClassName(variantProps)
  )
}

const getBackgroundClassNames = (
  {
    background,
    backgroundHover,
    backgroundActive,
    backgroundDisabled,
  }: VariantProps,
  readonly: boolean
) => {
  return cn(
    getDefaultBackgroundClassName(background),
    !readonly && getHoverBackgroundClassName(backgroundHover),
    !readonly && getActiveBackgroundClassName(backgroundActive),
    getDisabledBackgroundClassName(backgroundDisabled)
  )
}

const getDefaultBackgroundClassName = (background?: ThemeBackground) => {
  if (!background) {
    return 'bg-transparent'
  }

  return themeBackgroundMap[background]
}

const getHoverBackgroundClassName = (
  backgroundHover?: ThemeHoverBackground
) => {
  if (!backgroundHover) {
    return 'hover:bg-transparent'
  }

  return themeHoverBackgroundMap[backgroundHover]
}

const getActiveBackgroundClassName = (
  backgroundActive?: ThemeActiveBackground
) => {
  if (!backgroundActive) {
    return 'active:bg-transparent'
  }

  return themeActiveBackgroundMap[backgroundActive]
}

const getDisabledBackgroundClassName = (
  backgroundDisabled?: ThemeDisabledBackground
) => {
  if (!backgroundDisabled) {
    return 'disabled:bg-transparent'
  }

  return themeDisabledBackgroundMap[backgroundDisabled]
}

const getBorderClassNames = (
  {
    border,
    borderColor,
    borderHoverColor,
    borderActiveColor,
    borderDisabledColor,
  }: VariantProps,
  readonly: boolean
) => {
  if (!border) {
    return ''
  }

  return cn(
    'border border-solid',
    getDefaultBorderClassName(borderColor),
    !readonly && getHoverBorderClassName(borderHoverColor),
    !readonly && getActiveBorderClassName(borderActiveColor),
    getDisabledBorderClassName(borderDisabledColor)
  )
}

const getDefaultBorderClassName = (borderColor?: ThemeBorderColor) => {
  if (!borderColor) {
    return 'border-transparent'
  }

  return themeBorderColorMap[borderColor]
}

const getHoverBorderClassName = (borderHoverColor?: ThemeHoverBorderColor) => {
  if (!borderHoverColor) {
    return 'hover:border-transparent'
  }

  return themeHoverBorderColorMap[borderHoverColor]
}

const getActiveBorderClassName = (
  borderActiveColor?: ThemeActiveBorderColor
) => {
  if (!borderActiveColor) {
    return 'active:border-transparent'
  }

  return themeActiveBorderColorMap[borderActiveColor]
}

const getDisabledBorderClassName = (
  borderDisabledColor?: ThemeDisabledBorderColor
) => {
  if (!borderDisabledColor) {
    return 'disabled:border-transparent'
  }

  return themeDisabledBorderColorMap[borderDisabledColor]
}

const getTextClassNames = (props: VariantProps, readonly: boolean) => {
  return cn(
    getDefaultTextColorClassName(props),
    !readonly && getActiveTextColorClassName(props),
    !readonly && getHoverTextColorClassName(props),
    getDisabledTextColorClassName(props)
  )
}

const getDefaultTextColorClassName = ({ textColor }: VariantProps) => {
  if (textColor) {
    return themeTextColorMap[textColor]
  }
}

const getActiveTextColorClassName = ({ activeTextColor }: VariantProps) => {
  if (activeTextColor) {
    return themeActiveTextColorMap[activeTextColor]
  }
}

const getHoverTextColorClassName = ({ hoverTextColor }: VariantProps) => {
  if (hoverTextColor) {
    return themeHoverTextColorMap[hoverTextColor]
  }
}

const getDisabledTextColorClassName = ({ disabledTextColor }: VariantProps) => {
  if (!disabledTextColor) {
    return ''
  }

  return themeDisabledTextColorMap[disabledTextColor]
}

const getElevationClassName = ({ elevation }: VariantProps) => {
  if (!elevation) {
    return ''
  }

  return elevationMap[elevation]
}

const getGlowHoverClassName = ({ glowHover }: VariantProps) => {
  if (!glowHover) {
    return 'hover:bg-transparent'
  }

  return themeHoverGlowMap[glowHover]
}

const getButtonStyles = (
  size: ButtonSize,
  variant: ButtonVariant,
  readonly: boolean,
  textColor?: ThemeTextColor,
  borderColor?: ThemeBorderColor,
  circular?: boolean,
  colorTheme?: ButtonColorTheme
) =>
  cn(
    'flex items-center justify-center gap-2 rounded disabled:shadow-none disabled:cursor-not-allowed transition-transform duration-100',
    { 'rounded-full': circular },
    getVariantClassName(variant, readonly, textColor, borderColor, colorTheme),
    getSizeClassName(size, circular),
    !readonly && 'active:shadow-none active:scale-[0.98]'
  )

function useButtonStyles({
  variant,
  size,
  readonly,
  borderColor: borderColorProp,
  iconColor: iconColorProp,
  textColor: textColorProp,
  circular,
  colorTheme,
}: UseButtonStylesProps) {
  const buttonStyles = useMemo(
    () =>
      getButtonStyles(
        size,
        variant,
        readonly,
        textColorProp,
        borderColorProp,
        circular,
        colorTheme
      ),
    [
      circular,
      size,
      variant,
      textColorProp,
      borderColorProp,
      colorTheme,
      readonly,
    ]
  )
  const { iconColor, iconSize } = useMemo(() => {
    const { iconSize } = sizeClassMap[size]
    const { iconColor } =
      getVariantProps(variant, colorTheme ?? 'default', readonly) ?? {}
    return {
      iconSize,
      iconColor: iconColorProp ?? iconColor,
    }
  }, [variant, iconColorProp, size, colorTheme, readonly])

  return {
    buttonStyles,
    iconColor,
    iconSize,
  }
}

export {
  buttonColorThemeNames,
  buttonSizeNames,
  buttonVariantNames,
  getButtonStyles,
  type ButtonColorTheme,
  type ButtonSize,
  type ButtonVariant,
  type UseButtonStylesProps,
  useButtonStyles,
}
