import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { BillingScheduleSchema } from './billing/billing-schedule.ts'
import { CurrencySchema } from './billing/currency.ts'
import { InvoiceTermsSchema } from './billing/invoice-terms.ts'
import {
  BillingProfileIdSchema,
  OrganizationIdSchema,
} from './unique-id.models.ts'

/**
 * BillingProfileDomainSelectModel
 */

export const BillingProfileDomainSelectModelSchema = z.object({
  billingProfileId: BillingProfileIdSchema,
  organizationId: OrganizationIdSchema,
  billingSchedule: BillingScheduleSchema,
  currency: CurrencySchema,
  invoiceTerms: InvoiceTermsSchema,
  defaultProfile: z.boolean(),
})

export type BillingProfileDomainSelectModel = ZodSimplify<
  typeof BillingProfileDomainSelectModelSchema
>

/**
 * BillingProfileDomainInsertModel
 */

export const BillingProfileDomainInsertModelSchema =
  BillingProfileDomainSelectModelSchema.partial({
    billingProfileId: true,
  })

export type BillingProfileDomainInsertModel = ZodSimplify<
  typeof BillingProfileDomainInsertModelSchema
>

/**
 * BillingProfileDomainUpdateModel
 */

export const BillingProfileDomainUpdateModelSchema =
  BillingProfileDomainSelectModelSchema.pick({
    billingSchedule: true,
    currency: true,
    invoiceTerms: true,
    defaultProfile: true,
  }).partial()

export type BillingProfileDomainUpdateModel = ZodSimplify<
  typeof BillingProfileDomainUpdateModelSchema
>

/**
 * BillingProfileDomainSelectModelFilters
 */
export const BillingProfileDomainSelectModelFiltersSchema = z
  .object({
    billingProfileId: BillingProfileIdSchema,
    organizationId: OrganizationIdSchema,
    billingSchedule: BillingScheduleSchema,
    currency: CurrencySchema,
    invoiceTerms: InvoiceTermsSchema,
    defaultProfile: z.boolean(),
  })
  .partial()

export type BillingProfileDomainSelectModelFilters = ZodSimplify<
  typeof BillingProfileDomainSelectModelFiltersSchema
>
