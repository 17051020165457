import { cn } from '@mntn-dev/ui-utilities'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import { FieldGridColumnCountSchema } from './use-field-grid.ts'

export const FieldGridColumnSpanSchema = FieldGridColumnCountSchema
export type FieldGridColumnSpan = ZodSimplify<typeof FieldGridColumnSpanSchema>

export type UseFieldGridFieldProps = {
  columnSpan?: FieldGridColumnSpan
}

export function useFieldGridField({ columnSpan }: UseFieldGridFieldProps) {
  return {
    fieldGridFieldClassName: cn({
      'sm:col-span-1': columnSpan === 1,
      'sm:col-span-2': columnSpan === 2,
      'sm:col-span-3': columnSpan === 3,
      'sm:col-span-4': columnSpan === 4,
      'sm:col-span-5': columnSpan === 5,
      'sm:col-span-6': columnSpan === 6,
    }),
  }
}
