import { z } from 'zod'

import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  DescriptionSchema,
  DollarsSchema,
  NameSchema,
  TranslationsSchema,
} from './property.models.ts'
import { TagFilterSchema } from './tag.models.ts'
import { PackageIdSchema, PackageUrnSchema } from './unique-id.models.ts'

/**
 * PackageSource
 */
export const [PackageSources, PackageSourceSchema, PackageSourceEnum] =
  EnumBuilder('ctv', 'linkedin', 'meta', 'quickframe', 'tiktok')
export type PackageSource = ZodSimplify<typeof PackageSourceSchema>

/**
 * PackageTranslations
 */
export const PackageTranslationsSchema = TranslationsSchema(
  'name',
  'description'
)
export type PackageTranslations = ZodSimplify<typeof PackageTranslationsSchema>

/**
 * PackageStatus
 */
export const [PackageStatuses, PackageStatusSchema, PackageStatusEnum] =
  EnumBuilder('draft', 'published', 'archived')
export type PackageStatus = ZodSimplify<typeof PackageStatusSchema>

/**
 * PackagePayoutSchema
 */
export const PackagePayoutMax = 1_000_000
export const PackagePayoutSchema = DollarsSchema(PackagePayoutMax)
export type PackagePayout = ZodSimplify<typeof PackagePayoutSchema>

/**
 * PackageDomainSelectModel
 */
export const PackageDomainSelectModelSchema = z.object({
  packageId: PackageIdSchema,
  packageUrn: PackageUrnSchema,
  packageSource: PackageSourceSchema,
  name: NameSchema,
  description: DescriptionSchema,
  translations: PackageTranslationsSchema.optional(),
  cost: PackagePayoutSchema,
  costPlusMargin: DollarsSchema(),
  status: PackageStatusSchema,
})

export type PackageDomainSelectModel = ZodSimplify<
  typeof PackageDomainSelectModelSchema
>

/**
 * PackageDomainInsertModel
 */
export const PackageDomainInsertModelSchema =
  PackageDomainSelectModelSchema.omit({
    packageUrn: true,
    status: true, // Always inserted as draft
    costPlusMargin: true,
  }).partial({ packageId: true })

export type PackageDomainInsertModel = ZodSimplify<
  typeof PackageDomainInsertModelSchema
>

/**
 * PackageDomainUpdateModel
 */
export const PackageDomainUpdateModelSchema =
  PackageDomainSelectModelSchema.pick({
    packageSource: true,
    name: true,
    description: true,
    translations: true,
    cost: true,
    status: true,
  }).partial()

export type PackageDomainUpdateModel = ZodSimplify<
  typeof PackageDomainUpdateModelSchema
>

/**
 * PackageDomainSelectModelFilters
 */
export const PackageDomainSelectModelFiltersSchema = z
  .object({
    packageId: ModelFilterSchema(PackageIdSchema),
    packageUrn: ModelFilterSchema(PackageUrnSchema),
    packageSource: ModelFilterSchema(PackageSourceSchema),
    status: ModelFilterSchema(PackageStatusSchema),
    tags: TagFilterSchema,
  })
  .partial()

export type PackageDomainSelectModelFilters = ZodSimplify<
  typeof PackageDomainSelectModelFiltersSchema
>

export const packagesByStatus =
  <Package extends { status: string }>(status: string) =>
  (pkg: Package): boolean =>
    z.string().safeParse(pkg.status).success && pkg.status === status
