'use client'

import {
  themeBackgroundMap,
  themeBorderColorMap,
  themeDisabledRingColorMap,
  themeDisabledTextColorMap,
  themeFocusBorderColorMap,
  themeHoverBorderColorMap,
  themeReadonlyRingColorMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { isNilOrEmptyArray } from '@mntn-dev/utilities'
import { forwardRef } from 'react'
import {
  type UseFormFieldControlProps,
  useFormFieldControl,
} from '../form-field/use-form-field-control.ts'
import { Icon } from '../icon/icon.tsx'
import { Text } from '../text/text.tsx'
import {
  type MultiselectChipsContainerProps,
  MultiselectChipsContent,
} from './multiselect-chips-content.tsx'
import { useMultiselectContext } from './use-multiselect.ts'

type MultiselectVariant = 'popout' | 'inline'

type MultiselectChipsProps = Partial<
  Pick<MultiselectChipsContainerProps, 'listLocation' | 'singleLine'>
> &
  UseFormFieldControlProps<HTMLDivElement> & {
    className?: string
    placeholder?: string
    variant?: MultiselectVariant
  }

const MultiselectChips = forwardRef<HTMLDivElement, MultiselectChipsProps>(
  (
    {
      className,
      dataTestId,
      dataTrackingId,
      disabled: disabledProp,
      hasError: hasErrorProp,
      hasSuccess: hasSuccessProp,
      hasWarning: hasWarningProp,
      id,
      listLocation = 'inside',
      onBlur,
      placeholder,
      readOnly: readOnlyProp,
      singleLine,
      ...formFieldProps
    },
    ref
  ) => {
    const { isPopout, selectedItems } = useMultiselectContext()

    const { hasError, hasSuccess, hasWarning, disabled, readOnly } =
      useFormFieldControl<HTMLDivElement>({
        disabled: disabledProp,
        id,
        hasError: hasErrorProp,
        hasSuccess: hasSuccessProp,
        hasWarning: hasWarningProp,
        dataTestId,
        dataTrackingId,
        onBlur,
        readOnly: readOnlyProp,
      })

    const borderOverride =
      hasError || hasWarning || hasSuccess || disabled || readOnly

    const showPlaceholder =
      placeholder &&
      (isNilOrEmptyArray(selectedItems) || listLocation === 'above')

    return (
      <div className="flex flex-col gap-2">
        {listLocation === 'above' && (
          <MultiselectChipsContent
            listLocation={listLocation}
            singleLine={singleLine}
            disabled={disabled}
            readOnly={readOnly}
          />
        )}
        <div
          role={isPopout ? 'button' : undefined}
          ref={ref}
          className={cn(
            'w-full flex p-3 justify-between gap-2 items-center rounded border transition-all duration-100 ease-in-out data-[disabled]:pointer-events-none data-[disabled]:cursor-default',
            themeBackgroundMap['container-secondary'],
            themeDisabledTextColorMap.primary,
            themeDisabledRingColorMap.muted,
            themeReadonlyRingColorMap.muted,
            {
              'shadow-elevation-xs': !(readOnly || disabled),
              [`data-[open]:border-primary-blue ${themeBorderColorMap.muted} ${themeHoverBorderColorMap.muted}`]:
                !borderOverride,
              [cn(
                themeBorderColorMap.positive,
                themeFocusBorderColorMap.positive
              )]: hasSuccess,
              [cn(
                themeBorderColorMap.negative,
                themeFocusBorderColorMap.negative
              )]: hasError,
              [cn(
                themeBorderColorMap.caution,
                themeFocusBorderColorMap.caution
              )]: hasWarning,
            },
            className
          )}
          aria-disabled={disabled || readOnly}
          data-disabled={disabled || readOnly}
          {...formFieldProps}
        >
          {showPlaceholder && (
            <Text
              className={cn('flex items-center grow text-left', {
                'h-8': listLocation === 'inside',
              })}
              fontSize="med"
              textColor="tertiary"
            >
              {placeholder}
            </Text>
          )}

          <div className="grow overflow-hidden relative">
            {listLocation === 'inside' && (
              <MultiselectChipsContent
                listLocation={listLocation}
                singleLine={singleLine}
                disabled={disabled}
                readOnly={readOnly}
              />
            )}
          </div>

          {isPopout && (
            <Icon
              name="ChevronDownIcon"
              size="sm"
              color="info"
              className="mx-2 grow-0 shrink-0"
            />
          )}
        </div>
      </div>
    )
  }
)

export { MultiselectChips, type MultiselectChipsProps }
