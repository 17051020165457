import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'

import { motion } from 'framer-motion'
import { useSizing } from '../../hooks/use-sizing.ts'
import { Skeleton } from './skeleton.tsx'
import type { SkeletonProps } from './types.ts'

type SkeletonBoxProps = PropsWithChildren<SkeletonProps>

const SkeletonBox = ({ className, ...props }: SkeletonBoxProps) => {
  const { widthClassName, heightClassName, sizeClassName } = useSizing({
    ...props,
  })

  return (
    <motion.div
      animate={{
        opacity: [0.8, 0.3, 0.8],
        transition: {
          duration: 3,
          ease: 'easeInOut',
          repeat: Number.POSITIVE_INFINITY,
        },
      }}
      className={cn(
        'overflow-hidden',
        widthClassName,
        heightClassName,
        sizeClassName,
        className
      )}
    >
      <Skeleton width="full" height="full" variant="rectangle" />
    </motion.div>
  )
}

export { SkeletonBox, type SkeletonBoxProps }
