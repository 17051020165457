import type { ThemeGapValue } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import {
  type MouseEvent,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Chip } from '../chip/chip.tsx'
import { Chips } from '../chip/chips.tsx'
import type { UseFormFieldControlProps } from '../form-field/use-form-field-control.ts'
import { MultiselectPlusMore } from './multiselect-plus-more.tsx'
import type { MultiselectOptionItem } from './types.ts'
import { useMultiselectContext } from './use-multiselect.ts'
import { getShowMoreAttributes } from './utilities.ts'

const columnGapValue: ThemeGapValue = '2'

export type MultiselectChipsContainerProps = Pick<
  UseFormFieldControlProps<HTMLDivElement>,
  'disabled' | 'readOnly'
> & {
  listLocation: 'inside' | 'above'
  singleLine?: boolean
}

export const MultiselectChipsContent = ({
  singleLine,
  disabled,
  listLocation,
  readOnly,
}: MultiselectChipsContainerProps) => {
  const { onRemove, selectedItems } = useMultiselectContext()

  const [showMoreNumber, setShowMoreNumber] = useState<number>()
  const [showMoreOffset, setShowMoreOffset] = useState<number>()

  const chipContainerRef = useRef<HTMLDivElement | null>(null)

  const handleRemove =
    (item: MultiselectOptionItem) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      e.preventDefault()

      onRemove?.(item)
    }

  const keepOnSameLine = useMemo(
    () => singleLine && listLocation === 'inside',
    [singleLine, listLocation]
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: performance optimization - this layout effect should only be necessary to run if selected items changes.
  useLayoutEffect(() => {
    if (chipContainerRef.current && keepOnSameLine) {
      const [showMoreNumber, showMoreOffset] = getShowMoreAttributes(
        chipContainerRef.current,
        columnGapValue
      )

      setShowMoreNumber(showMoreNumber)
      setShowMoreOffset(showMoreOffset)
    }
  }, [selectedItems, keepOnSameLine])

  return (
    <>
      {selectedItems && selectedItems.length > 0 && (
        <Chips
          ref={chipContainerRef}
          gap={columnGapValue}
          className={cn({
            'flex-wrap': !keepOnSameLine,
            'flex-nowrap': keepOnSameLine,
          })}
        >
          {selectedItems.map((selectedItem) => (
            <Chip
              key={selectedItem.id}
              id={selectedItem.id}
              variant={listLocation === 'above' ? 'rounded-square' : 'pill'}
              dismissable={!(disabled || readOnly)}
              onDismissClick={handleRemove(selectedItem)}
            >
              {selectedItem.name}
            </Chip>
          ))}
        </Chips>
      )}
      {showMoreNumber && (
        <MultiselectPlusMore count={showMoreNumber} offset={showMoreOffset} />
      )}
    </>
  )
}
