import type { TestIds } from '../../../utils/testing.ts'
import { CardDescription } from '../components/card-description.tsx'
import { CardHeading } from '../components/card-heading.tsx'
import { CardTitle } from '../components/card-title.tsx'

type SpotlightCardHeadingProps = {
  className?: string
  description?: string
  title: string
} & TestIds

const SpotlightCardHeading = ({
  className,
  dataTestId,
  dataTrackingId,
  description,
  title,
}: SpotlightCardHeadingProps) => (
  <CardHeading className={className}>
    <CardTitle
      fontSize="3xl"
      dataTestId={`${dataTestId}-title`}
      dataTrackingId={`${dataTrackingId}-title`}
    >
      {title}
    </CardTitle>
    {description && (
      <CardDescription
        dataTestId={`${dataTestId}-description`}
        dataTrackingId={`${dataTrackingId}-description`}
      >
        {description}
      </CardDescription>
    )}
  </CardHeading>
)

export { SpotlightCardHeading }
