import { cn } from '@mntn-dev/ui-utilities'

import { themeBackgroundMap } from '@mntn-dev/ui-theme'
import { useSizing } from '../../hooks/use-sizing.ts'
import type { SkeletonProps } from './types.ts'

const Skeleton = ({
  variant,
  className,
  ...props
}: SkeletonProps & { variant: 'rectangle' | 'rounded' }) => {
  const { widthClassName, heightClassName, sizeClassName } = useSizing({
    ...props,
  })

  return (
    <div
      className={cn(
        themeBackgroundMap.default,
        variant === 'rounded' && 'rounded-full',
        widthClassName,
        heightClassName,
        sizeClassName,
        className
      )}
    />
  )
}

export { Skeleton }
