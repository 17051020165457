'use client'

import type { ReactNode } from 'react'
import { useTestTagsContext } from '../../../hooks/use-test-tags.ts'
import { Breadcrumbs } from '../../breadcrumbs/breadcrumbs.tsx'
import { Text } from '../../text/text.tsx'
import { PageHeaderOverlineBreadcrumbDivider } from './page-header-overline-breadcrumb-divider.tsx'

export type PageHeaderOverlineBreadcrumbsProps = {
  crumbs?: ReactNode[]
}

export const PageHeaderOverlineBreadcrumbs = ({
  crumbs,
}: PageHeaderOverlineBreadcrumbsProps) => {
  const { dataTestId, dataTrackingId } = useTestTagsContext()

  return (
    <Breadcrumbs
      divider={<PageHeaderOverlineBreadcrumbDivider iconColor="secondary" />}
      dividerColor="secondary"
      dividerSize="sm"
      gap="2"
      dataTestId={`${dataTestId}-overline-breadcrumbs`}
      dataTrackingId={`${dataTrackingId}-overline-breadcrumbs`}
    >
      {crumbs?.map((crumb, index) =>
        typeof crumb === 'string' ? (
          <Text
            textColor={index === crumbs.length - 1 ? 'primary' : 'secondary'}
            fontSize="sm"
            fontWeight={index === crumbs.length - 1 ? 'bold' : 'normal'}
            key={`page-header-overline-breadcrumb-${crumb}`}
            dataTestId={`${dataTestId}-overline-breadcrumb-${crumb}`}
            dataTrackingId={`${dataTrackingId}-overline-breadcrumb-${crumb}`}
          >
            {crumb}
          </Text>
        ) : (
          crumb
        )
      )}
    </Breadcrumbs>
  )
}
