import { EnumBuilder } from '@mntn-dev/utility-types'
import type { z } from 'zod'

/**
 * InvoiceTerm
 */
export const [InvoiceTermsList, InvoiceTermsSchema, InvoiceTerms] = EnumBuilder(
  'none',
  'net_30',
  'net_60',
  'net_90'
)

export type InvoiceTerms = z.infer<typeof InvoiceTermsSchema>
