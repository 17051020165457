const matchAllWords = /\b\w/g
const matchFirstCharacter = /^./

export function removeNonAlphanumeric(value: string) {
  return value.replace(/[^a-z0-9]/gi, '')
}

export function removeNonNumeric(value: string) {
  return value.replace(/\D/g, '')
}

export function capitalize(value: string, allWords = true) {
  if (allWords) {
    return value.replace(matchAllWords, (char) => char.toUpperCase())
  }

  return value.replace(matchFirstCharacter, (char) => char.toUpperCase())
}
