import { z } from 'zod'
import {
  BidIdSchema,
  ProjectIdSchema,
  TeamIdSchema,
} from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectBiddingSuccessfulActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_bidding_successful',
    z.object({
      projectId: ProjectIdSchema,
      bidId: BidIdSchema,
      agencyTeamId: TeamIdSchema,
      ...ActivityLinkTargets('project', 'bid'),
    })
  )
