import { generateRandomString } from '@mntn-dev/utilities'
import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { NameSchema } from './property.models.ts'
import {
  OrganizationId,
  OrganizationIdSchema,
  OrganizationUrn,
  OrganizationUrnSchema,
} from './unique-id.models.ts'

export const InternalOrganizationId = OrganizationId.Empty
export const InternalOrganizationUrn = OrganizationUrn(InternalOrganizationId)

/**
 * OrganizationType
 */
export const CustomerOrganizationTypes = <const>['brand', 'agency']
export const InternalOrganizationTypes = <const>['internal']

export const [OrganizationTypes, OrganizationTypeSchema, OrganizationTypeEnum] =
  EnumBuilder(...CustomerOrganizationTypes, ...InternalOrganizationTypes)
export type OrganizationType = ZodSimplify<typeof OrganizationTypeSchema>

export const [, CustomerOrganizationTypeSchema, CustomerOrganizationTypeEnum] =
  EnumBuilder(...CustomerOrganizationTypes)
export type CustomerOrganizationType = ZodSimplify<
  typeof CustomerOrganizationTypeSchema
>

export function isCustomerOrganizationType(
  value: string
): value is CustomerOrganizationType {
  return CustomerOrganizationTypeSchema.safeParse(value).success
}

export function isOrganizationType(value: string): value is OrganizationType {
  return OrganizationTypeSchema.safeParse(value).success
}

export const OrganizationBillingIdSchema = z.string().brand('billing')
export type OrganizationBillingId = ZodSimplify<
  typeof OrganizationBillingIdSchema
>

export const OrganizationBillingId = (
  value: string = generateRandomString(16)
) => {
  return OrganizationBillingIdSchema.parse(value)
}

export const OrganizationPayeeIdSchema = z.string().brand('payee')
export type OrganizationPayeeId = ZodSimplify<typeof OrganizationPayeeIdSchema>

export const OrganizationPayeeId = (
  value: string = generateRandomString(16)
) => {
  return OrganizationPayeeIdSchema.parse(value)
}

/**
 * OnboardingStatus
 */
export const [
  OnboardingStatuses,
  OnboardingStatusSchema,
  OnboardingStatusEnum,
] = EnumBuilder('onboarding', 'onboarded')

export type OnboardingStatus = ZodSimplify<typeof OnboardingStatusSchema>

/**
 * OnboardingStep
 */
export const [OnboardingSteps, OnboardingStepSchema, OnboardingStepEnum] =
  EnumBuilder('certification', 'payee-info', 'profile-info', 'interview')

export type OnboardingStep = ZodSimplify<typeof OnboardingStepSchema>

export const getRemainingOnboardingSteps = (steps: OnboardingStep[]) =>
  Array.from(new Set(OnboardingSteps).difference(new Set(steps)))

export const OrganizationDomainSelectModelSchema = z.object({
  organizationId: OrganizationIdSchema,
  organizationUrn: OrganizationUrnSchema,
  organizationType: OrganizationTypeSchema,
  name: NameSchema,
  billingId: OrganizationBillingIdSchema.optional(),
  payeeId: OrganizationPayeeIdSchema.optional(),
  onboarding: z.object({
    status: OnboardingStatusSchema,
    stepsCompleted: z.array(OnboardingStepSchema),
  }),
})

export type OrganizationDomainSelectModel = ZodSimplify<
  typeof OrganizationDomainSelectModelSchema
>

export const OrganizationDomainInsertModelSchema =
  OrganizationDomainSelectModelSchema.pick({
    organizationId: true,
    organizationType: true,
    name: true,
  }).partial({
    organizationId: true,
  })

export type OrganizationDomainInsertModel = ZodSimplify<
  typeof OrganizationDomainInsertModelSchema
>

export const OrganizationDomainUpdateModelSchema =
  OrganizationDomainSelectModelSchema.pick({
    name: true,
    billingId: true,
    payeeId: true,
  })
    .extend({
      onboarding:
        OrganizationDomainSelectModelSchema.shape.onboarding.partial(),
    })
    .partial()

export type OrganizationDomainUpdateModel = ZodSimplify<
  typeof OrganizationDomainUpdateModelSchema
>

export const OrganizationDomainSelectModelFiltersSchema = z
  .object({
    organizationId: ModelFilterSchema(OrganizationIdSchema),
    // When filtering, transform names to lower case so the
    // "lower(organizations.name)" index can be used.
    name: ModelFilterSchema(NameSchema.toLowerCase()),
    organizationType: ModelFilterSchema(OrganizationTypeSchema),
    onboardingStatus: ModelFilterSchema(OnboardingStatusSchema),
  })
  .partial()

export type OrganizationDomainSelectModelFilters = ZodSimplify<
  typeof OrganizationDomainSelectModelFiltersSchema
>
