import {
  type TextOptions,
  type TextSize,
  getTextBaseClasses,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { AnchorHTMLAttributes, PropsWithChildren } from 'react'
import type { TextProps } from '../text/text.tsx'

type LinkProps = PropsWithChildren<
  Pick<TextProps, 'fontSize' | 'fontWeight'> &
    AnchorHTMLAttributes<HTMLAnchorElement>
> & {
  underlined?: boolean
} & Omit<TextOptions, 'fontSize'> & {
    fontSize?: TextSize
  }

const Link = ({
  children,
  underlined = true,
  textColor = 'link',
  fontSize = 'med',
  fontWeight = 'normal',
  className,
  ...props
}: LinkProps) => {
  return (
    <a
      {...props}
      className={cn(
        underlined && 'underline',
        getTextBaseClasses({
          textColor,
          fontSize,
          fontWeight,
        }),
        className
      )}
    >
      {children}
    </a>
  )
}

export { Link }
