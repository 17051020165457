'use client'

import { useState } from 'react'

//import { zodResolver } from '@hookform/resolvers/zod'
//import { useForm } from 'react-hook-form'
//import { z } from 'zod'

import type { AbstractImage } from '@mntn-dev/ui-utilities'
import type { Person } from '../../types/person.ts'
import { type TestIds, getTestProps } from '../../utils'
import { Avatar } from '../avatar/index.ts'
import { Button } from '../buttons/button.tsx'
//import { FormField } from '../form-field/form-field.tsx'
import { Stack } from '../stack/stack.tsx'
import { Surface } from '../surface/surface.tsx'
import type { TextareaFormProps } from '../textarea-form/textarea-form.tsx'
import { Textarea } from '../textarea/textarea.tsx'

type FeedCommentInputProps = Readonly<
  TestIds &
    Pick<TextareaFormProps, 'onSubmit'> & {
      cancelText?: string
      onSubmit: (values: CommentFormValues) => void
      onCancel: () => void
      requiredErrorText?: string
      submitText?: string
      submitting?: boolean
      user: Person
      image: AbstractImage
    }
>

type CommentFormValues = {
  comment: string
}

const FeedCommentInput = ({
  cancelText = 'Cancel',
  dataTestId,
  dataTrackingId,
  onSubmit,
  onCancel,
  // requiredErrorText = 'Comment is required',
  submitText = 'Submit',
  submitting,
  user,
  image,
}: FeedCommentInputProps) => {
  const [comment, setComment] = useState<string>('')
  // const formValuesSchema = z.object({
  //   comment: z.string({ required_error: requiredErrorText }).min(1),
  // })
  // const {
  //   handleSubmit,
  //   formState: { errors },
  //   reset,
  // } = useForm<CommentFormValues>({
  //   resolver: zodResolver(formValuesSchema),
  // })

  const handleCancel = () => {
    // reset()
    setComment('')
    onCancel()
  }

  const handleSubmit = () => {
    if (comment.length < 1) {
      return
    }

    onSubmit({ comment })
    setComment('')
  }

  return (
    <Surface border padding="8">
      {/*<form onSubmit={handleSubmit(onSubmit)}>*/}
      <Stack gap="4" width="full">
        <Avatar size="sm">
          <Avatar.Person person={user} image={image} />
        </Avatar>
        <Stack gap="4" direction="col" grow={true}>
          {/*<FormField hasError={!!errors.comment}>*/}
          <Textarea
            className="h-36 resize-none overflow-hidden"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            autoFocus
            {...getTestProps({
              dataTestId: dataTestId ? `${dataTestId}-textarea` : undefined,
              dataTrackingId: dataTrackingId
                ? `${dataTrackingId}-textarea`
                : undefined,
            })}
          />
          {/* <FormField.Error>{errors.comment?.message}</FormField.Error> */}
          {/*</FormField>*/}
          <Stack justifyContent="between">
            <Button
              // type="submit"
              disabled={submitting}
              width="48"
              onClick={handleSubmit}
              {...getTestProps({
                dataTestId: dataTestId ? `${dataTestId}-submit` : undefined,
                dataTrackingId: dataTrackingId
                  ? `${dataTrackingId}-submit`
                  : undefined,
              })}
            >
              {submitText}
            </Button>
            <Button
              variant="text"
              {...getTestProps({
                dataTestId: dataTestId ? `${dataTestId}-cancel` : undefined,
                dataTrackingId: dataTrackingId
                  ? `${dataTrackingId}-cancel`
                  : undefined,
              })}
              onClick={handleCancel}
            >
              {cancelText}
            </Button>
          </Stack>
        </Stack>
      </Stack>
      {/*</form>*/}
    </Surface>
  )
}

export { FeedCommentInput, type CommentFormValues, type FeedCommentInputProps }
