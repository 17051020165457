import { z } from 'zod'

import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  ActivityIdSchema,
  NotificationIdSchema,
  UserIdSchema,
} from './unique-id.models.ts'

export const [
  NotificationStatuses,
  NotificationStatusSchema,
  NotificationStatusEnum,
] = EnumBuilder('unread', 'read')
export type NotificationStatus = ZodSimplify<typeof NotificationStatusSchema>

/**
 * NotificationDomainSelectModel
 */
export const NotificationDomainSelectModelSchema = z.object({
  notificationId: NotificationIdSchema,
  recipientId: UserIdSchema,
  activityId: ActivityIdSchema,
  status: NotificationStatusSchema,
})

export type NotificationDomainSelectModel = ZodSimplify<
  typeof NotificationDomainSelectModelSchema
>

/**
 * NotificationDomainInsertModel
 */
export const NotificationDomainInsertModelSchema =
  NotificationDomainSelectModelSchema.omit({ status: true }).partial({
    notificationId: true,
  })

export type NotificationDomainInsertModel = ZodSimplify<
  typeof NotificationDomainInsertModelSchema
>

/**
 * NotificationDomainUpdateModel
 */
export const NotificationDomainUpdateModelSchema =
  NotificationDomainSelectModelSchema.pick({
    status: true,
  }).partial()

export type NotificationDomainUpdateModel = ZodSimplify<
  typeof NotificationDomainUpdateModelSchema
>

/**
 * NotificationDomainSelectModelFilters
 */
export const NotificationDomainSelectModelFiltersSchema = z
  .object({
    notificationId: ModelFilterSchema(NotificationIdSchema),
    recipientId: ModelFilterSchema(UserIdSchema),
    status: ModelFilterSchema(NotificationStatusSchema),
  })
  .partial()

export type NotificationDomainSelectModelFilters = ZodSimplify<
  typeof NotificationDomainSelectModelFiltersSchema
>
