import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'

/**
 * AnyUrn urn:{nss}:{nid}
 * e.g. urn:project:5cff7e31-db22-488f-a116-6ff437b62a35
 */
export const AnyUrnSchema = z
  .string()
  .regex(
    /^urn:([^:]+):[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/
  )
  .transform((input) => input as `urn:${string}:${string}`)
export type AnyUrn = ZodSimplify<typeof AnyUrnSchema>
