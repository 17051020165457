'use client'

import { useIsFirstRender } from '@mntn-dev/ui-utilities'
import {
  type ParsedPhoneNumber,
  getAsYouType,
  getRegionCodeForCountryCode,
  parsePhoneNumber,
} from 'awesome-phonenumber'
import type React from 'react'
import {
  type ChangeEvent,
  type KeyboardEvent,
  type RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Icon } from '../icon/icon.tsx'
import { Input, type InputProps } from '../input/input.tsx'
import {
  getParsedPhoneNumber,
  getPhoneObjectFromParsed,
  getPhoneObjectFromPhoneNumber,
  getPhoneObjectsFromArray,
  validateAndUpdateFormatter,
} from './utils.ts'

type PhoneInputRef = {
  inputRef: RefObject<HTMLInputElement | null>
  resetValue: (phoneNumber?: string) => void
  focus: () => void
}

type PhoneInputProps = Omit<
  InputProps,
  'iconLeft' | 'iconRight' | 'onChange' | 'type' | 'value'
> & {
  onChange?: (
    parsedPhoneNumber: ParsedPhoneNumber,
    originalEvent?: ChangeEvent<HTMLInputElement>
  ) => void
  defaultValue?: string
}

const PhoneInput = forwardRef<PhoneInputRef, PhoneInputProps>(
  (
    {
      defaultValue,
      placeholder = '(555) 555-5555',
      onChange,
      onKeyDown,
      ...props
    },
    ref
  ) => {
    const isFirstRender = useIsFirstRender()
    const formatter = useRef(getAsYouType('US'))
    const inputRef = useRef<HTMLInputElement | null>(null)

    const [formattedValue, setFormattedValue] = useState<string>('')

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.altKey || e.ctrlKey || e.metaKey) {
        e.preventDefault()
      }

      const valid = validateAndUpdateFormatter(formatter.current, e)
      if (!valid) {
        e.preventDefault()
        e.stopPropagation()
        return
      }

      onKeyDown?.(e)
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormattedValue(formatter.current.number())

      if (onChange) {
        onChange(formatter.current.getPhoneNumber(), e)
      }
    }

    useEffect(() => {
      if (isFirstRender && defaultValue) {
        formatter.current.reset(defaultValue)
        setFormattedValue(formatter.current.number())
      }
    }, [defaultValue, isFirstRender])

    const resetValue = useCallback(
      (phoneNumber?: string) => {
        formatter.current.reset(phoneNumber)
        setFormattedValue(formatter.current.number())

        if (onChange) {
          onChange(formatter.current.getPhoneNumber())
        }
      },
      [onChange]
    )

    // this needs to be imperative so we can reset the formatter properly
    useImperativeHandle(
      ref,
      () => {
        return { inputRef, resetValue, focus: () => inputRef.current?.focus() }
      },
      [resetValue]
    )

    return (
      <Input
        type="tel"
        ref={inputRef}
        placeholder={placeholder}
        iconLeft={<Icon name="PhoneIcon" fill="solid" color="info" size="md" />}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        value={formattedValue}
        {...props}
      />
    )
  }
)

export {
  getRegionCodeForCountryCode,
  getParsedPhoneNumber,
  getPhoneObjectFromParsed,
  getPhoneObjectFromPhoneNumber,
  getPhoneObjectsFromArray,
  parsePhoneNumber,
  PhoneInput,
  type ParsedPhoneNumber,
  type PhoneInputProps,
  type PhoneInputRef,
}
