import type { TestIds } from '../../utils'
import { useInputStyles } from './use-input-styles.ts'
import { cn } from '@mntn-dev/ui-utilities'
import type { KeyboardEvent } from 'react'
import { Stack } from '../stack'

type DateInputType = {
  date?: string
  time?: string
  hasError?: boolean
  disabled?: boolean
  onClick?: () => void
  onFocus?: () => void
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void
} & TestIds

export const DateInput = ({
  date,
  time,
  hasError,
  disabled,
  onClick,
  onFocus,
  onKeyDown,
  dataTestId,
  dataTrackingId,
}: DateInputType) => {
  const inputStyles = useInputStyles({ hasError, disabled })
  return (
    <Stack dataTestId={dataTestId} dataTrackingId={dataTrackingId}>
      <div
        className={cn(
          inputStyles,
          'items-center w-full justify-between pr-12 cursor-pointer'
        )}
        tabIndex={0}
        role="textbox"
        aria-disabled={disabled}
        onClick={disabled ? undefined : onClick}
        onFocus={disabled ? undefined : onFocus}
        onKeyDown={disabled ? undefined : onKeyDown}
      >
        {date && <span>{date}</span>}
        {time && <span>{time}</span>}
      </div>
    </Stack>
  )
}
