import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  SurveyDetailsSchema,
  SurveyTypeSchema,
} from './survey-details/index.ts'
import {
  OfferIdSchema,
  ProjectIdSchema,
  SurveyIdSchema,
  UserIdSchema,
} from './unique-id.models.ts'

/**
 * SurveySubjectIdSchema
 */
export const SurveySubjectIdSchema = z.union([ProjectIdSchema, OfferIdSchema])

export type SurveySubjectId = ZodSimplify<typeof SurveySubjectIdSchema>

/**
 * SurveyDomainSelectModel
 */
export const SurveyDomainSelectModelSchema = z.object({
  surveyId: SurveyIdSchema,
  responderId: UserIdSchema,
  details: SurveyDetailsSchema,
})

export type SurveyDomainSelectModel = ZodSimplify<
  typeof SurveyDomainSelectModelSchema
>

/**
 * SurveyDomainInsertModel
 */
export const SurveyDomainInsertModelSchema =
  SurveyDomainSelectModelSchema.partial({ surveyId: true })

export type SurveyDomainInsertModel = ZodSimplify<
  typeof SurveyDomainInsertModelSchema
>

/**
 * SurveyDomainSelectModelFilters
 */
export const SurveyDomainSelectModelFiltersSchema = z
  .object({
    surveyId: ModelFilterSchema(SurveyIdSchema),
    surveyType: ModelFilterSchema(SurveyTypeSchema),
    responderId: ModelFilterSchema(UserIdSchema),
    subjectId: ModelFilterSchema(SurveySubjectIdSchema),
  })
  .partial()

export type SurveyDomainSelectModelFilters = ZodSimplify<
  typeof SurveyDomainSelectModelFiltersSchema
>

export * from './survey-details/index.ts'
