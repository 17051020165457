import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

import {
  type UseFieldGridFieldProps,
  useFieldGridField,
} from '../../hooks/use-field-grid-field.ts'
import { FormFieldControl } from './form-field-control.tsx'
import { FormFieldError } from './form-field-error.tsx'
import { FormFieldHelp } from './form-field-help.tsx'
import { FormFieldLabel } from './form-field-label.tsx'
import { FormFieldOverline } from './form-field-overline.tsx'
import { FormFieldSuccess } from './form-field-success.tsx'
import { FormFieldSurface } from './form-field-surface.tsx'
import { FormFieldWarning } from './form-field-warning.tsx'
import {
  FormFieldProvider,
  type UseFormFieldProps,
  useFormField,
} from './use-form-field.ts'

type FormFieldProps = Readonly<
  UseFormFieldProps &
    UseFieldGridFieldProps & {
      children: React.ReactNode
      className?: string
    }
>

const FormFieldComponent = ({
  children,
  className,
  columnSpan,
  ...useFormFieldProps
}: FormFieldProps) => {
  const context = useFormField(useFormFieldProps)

  const { fieldGridFieldClassName } = useFieldGridField({ columnSpan })

  return (
    <FormFieldProvider value={context}>
      <div
        className={cn(
          'flex flex-col gap-2 w-full',
          fieldGridFieldClassName,
          className
        )}
      >
        {children}
      </div>
    </FormFieldProvider>
  )
}

const FormFieldNamespace = Object.assign(FormFieldComponent, {
  Overline: FormFieldOverline,
  Label: FormFieldLabel,
  Help: FormFieldHelp,
  Error: FormFieldError,
  Control: FormFieldControl,
  Surface: FormFieldSurface,
  Success: FormFieldSuccess,
  Warning: FormFieldWarning,
})

export { FormFieldNamespace as FormField, type FormFieldProps }
