import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'

export const [ActivityTypes, ActivityTypeSchema, ActivityTypeEnum] =
  EnumBuilder(
    'agreement_terms_accepted',
    'comment_created',
    'post_production_review_brand_feedback_submitted',
    'post_production_review_maker_proposal_submitted',
    'pre_production_brand_feedback_submitted',
    'pre_production_maker_proposal_submitted',
    'pre_production_review_brand_feedback_submitted',
    'pre_production_review_maker_proposal_submitted',
    'project_bid_accepted',
    'project_bid_rejected',
    'project_bidding_closed',
    'project_bidding_opened',
    'project_bidding_successful',
    'project_bidding_unsuccessful',
    'project_created',
    'project_file_added',
    'project_not_matched',
    'project_offer_expired',
    'project_offer_pending',
    'project_offers_sent',
    'project_service_added',
    'project_status_changed',
    'service_note_added'
  )

export type ActivityType = ZodSimplify<typeof ActivityTypeSchema>
