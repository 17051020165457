import type { NonEmptyArray } from '@mntn-dev/utility-types'

export function first<T>(array: NonEmptyArray<T>): T
export function first<T>(array: T[] | undefined): T | undefined
export function first<T>(array: (T | null)[]): T | null
export function first<T>(array: (T | undefined)[]): T | undefined
export function first<T>(
  array: (T | null | undefined)[] | undefined
): T | null | undefined {
  const result = array && array.length > 0 ? array[0] : undefined

  return result
}

export function assertFirst<T>(array: (T | null | undefined)[] | undefined): T {
  const result = array ? array[0] : undefined

  if (result === undefined || result === null) {
    throw new Error(
      'The first element of the array was unexpectedly undefined or null'
    )
  }

  return result
}
