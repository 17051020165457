import { UnhandledUnionError } from '@mntn-dev/utilities'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { OfferAcceptedSurveyDetailsSchema } from './offer-accepted.ts'
import { OfferDeclinedSurveyDetailsSchema } from './offer-declined.ts'
import { ProjectCompletedBrandReviewsMakerSurveyDetailsSchema } from './project-completed-brand-reviews-maker.ts'
import { ProjectCompletedMakerReviewsBrandSurveyDetailsSchema } from './project-completed-maker-reviews-brand.ts'

export const SurveyDetailsSchema = z.discriminatedUnion('surveyType', [
  OfferAcceptedSurveyDetailsSchema,
  OfferDeclinedSurveyDetailsSchema,
  ProjectCompletedBrandReviewsMakerSurveyDetailsSchema,
  ProjectCompletedMakerReviewsBrandSurveyDetailsSchema,
])

export type SurveyDetails = ZodSimplify<typeof SurveyDetailsSchema>

export const getSurveyDetailsSubjectId = (details: SurveyDetails) => {
  const { surveyType } = details

  switch (surveyType) {
    case 'offer-accepted':
    case 'offer-declined': {
      return details.offerId
    }
    case 'project-completed-brand-reviews-maker':
    case 'project-completed-maker-reviews-brand': {
      return details.projectId
    }
    default: {
      throw new UnhandledUnionError(surveyType)
    }
  }
}
