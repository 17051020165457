'use client'

import { cn } from '@mntn-dev/ui-utilities'

import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { getTestProps } from '../../utils'
import { AvatarDecorator } from './avatar-decorator.tsx'
import { AvatarEntityComponent } from './avatar-entity.tsx'
import { AvatarIcon } from './avatar-icon.tsx'
import { AvatarPerson, type AvatarPersonProps } from './avatar-person.tsx'
import type { AvatarProps } from './types.ts'
import { AvatarProvider, useAvatar } from './use-avatar.ts'

const Avatar = ({
  className,
  size = 'med',
  dataTestId,
  dataTrackingId,
  decoratorType,
  borderColor,
  selected,
  children,
  onClick,
}: AvatarProps) => {
  const context = useAvatar({
    borderColor,
    dataTestId,
    dataTrackingId,
    decoratorType,
    size,
  })

  const {
    backgroundClassName,
    borderClassName,
    sizeClassName,
    textSizeClassName,
  } = context

  return (
    <AvatarProvider value={context}>
      <AvatarDecorator>
        <div
          className={cn('rounded-full', {
            'drop-shadow-glow-blue-light': selected,
          })}
        >
          <div
            className={cn(
              'relative flex items-start rounded-full overflow-hidden',
              backgroundClassName,
              borderClassName,
              sizeClassName,
              className,
              { 'cursor-pointer': !!onClick }
            )}
            onClick={onClick}
            {...getTestProps({ dataTestId, dataTrackingId })}
          >
            <div
              className={cn(
                'flex shrink-0 items-center justify-center rounded-full text-center font-bold',
                themeTextColorMap['primary-inverse'],
                textSizeClassName,
                sizeClassName,
                {
                  '-m-0.5': borderColor,
                }
              )}
              {...getTestProps({ dataTestId, dataTrackingId })}
            >
              {children}
            </div>
          </div>
        </div>
      </AvatarDecorator>
    </AvatarProvider>
  )
}
const AvatarNamespace = Object.assign(Avatar, {
  Entity: AvatarEntityComponent,
  Icon: AvatarIcon,
  Person: AvatarPerson,
})

export { AvatarNamespace as Avatar, type AvatarProps, type AvatarPersonProps }
