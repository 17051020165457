import { z } from 'zod'
import { BidIdSchema, ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectBiddingClosedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_bidding_closed',
    z.object({
      projectId: ProjectIdSchema,
      submittedBidIds: BidIdSchema.array(),
      ...ActivityLinkTargets('project'),
    })
  )
