import { z } from 'zod'

import { ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectNotMatchedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'project_not_matched',
  z.object({
    projectId: ProjectIdSchema,
    ...ActivityLinkTargets('project'),
  })
)
