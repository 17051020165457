import { type JwtPayload, jwtDecode } from 'jwt-decode'

interface AccessTokenPayload extends JwtPayload {
  scope: string
}

export const extractScopes = (accessToken: string): string[] => {
  const decodedToken = jwtDecode<AccessTokenPayload>(accessToken)
  const scopes = Array.isArray(decodedToken.scope)
    ? decodedToken.scope
    : typeof decodedToken.scope === 'string'
      ? decodedToken.scope.split(' ')
      : []

  return scopes
}
