import { uniqBy } from '@mntn-dev/utilities'
import type { ZodSimplify } from '@mntn-dev/utility-types'
import type { EmptyObject, OverrideProperties, SimplifyDeep } from 'type-fest'
import { z } from 'zod'
import {
  type ActivityDomainSelectModel,
  ActivityDomainSelectModelSchema,
  type ActivityType,
} from './activity.models.ts'
import {
  type AgreementDomainSelectModel,
  AgreementDomainSelectModelSchema,
} from './agreement.models.ts'
import {
  type BidDomainSelectModel,
  BidDomainSelectModelSchema,
} from './bid.models.ts'
import {
  type BillingProfileDomainSelectModel,
  BillingProfileDomainSelectModelSchema,
} from './billing-profile.models.ts'
import {
  type DeliverableDomainSelectModel,
  DeliverableDomainSelectModelSchema,
} from './deliverable.models.ts'
import {
  type FileDomainSelectModel,
  FileDomainSelectModelSchema,
} from './file.models.ts'
import {
  type NotificationDomainSelectModel,
  NotificationDomainSelectModelSchema,
} from './notification.models.ts'
import {
  type OfferDomainSelectModel,
  OfferDomainSelectModelSchema,
} from './offer.models.ts'
import {
  type OrganizationProgramDomainSelectModel,
  OrganizationProgramDomainSelectModelSchema,
} from './organization-program.models.ts'
import {
  type OrganizationDomainSelectModel,
  OrganizationDomainSelectModelSchema,
} from './organization.models.ts'
import {
  type PackageServiceViewDomainSelectModel,
  PackageServiceViewDomainSelectModelSchema,
} from './package-service.models.ts'
import {
  type PackageDomainSelectModel,
  PackageDomainSelectModelSchema,
} from './package.models.ts'
import {
  type ProjectServiceDomainSelectModel,
  ProjectServiceDomainSelectModelSchema,
} from './project-service.models.ts'
import {
  ProjectDomainSelectModelSchema,
  type ProjectViewDomainSelectModel,
  ProjectViewDomainSelectModelSchema,
} from './project.models.ts'
import {
  type ProofDomainSelectModel,
  ProofDomainSelectModelSchema,
} from './proof.models.ts'
import {
  type ReviewDomainSelectModel,
  ReviewDomainSelectModelSchema,
} from './review.models.ts'
import {
  type RoundDomainSelectModel,
  RoundDomainSelectModelSchema,
} from './round.models.ts'
import {
  type ServiceDomainSelectModel,
  ServiceDomainSelectModelSchema,
} from './service.models.ts'
import {
  type SurveyDomainSelectModel,
  SurveyDomainSelectModelSchema,
} from './survey.models.ts'
import {
  TagCategorySchema,
  type TagDomainSelectModel,
  TagDomainSelectModelSchema,
} from './tag.models.ts'
import {
  type TeamProfileDomainSelectModel,
  TeamProfileDomainSelectModelSchema,
} from './team-profile.models.ts'
import {
  type TeamDomainSelectModel,
  TeamDomainSelectModelSchema,
} from './team.models.ts'
import {
  type UserDomainSelectModel,
  UserDomainSelectModelSchema,
} from './user.models.ts'
import {
  type WatchDomainSelectModel,
  WatchDomainSelectModelSchema,
} from './watch.models.ts'

/**
 * Typescript does not allow circular references between objects so the validation can only
 * be done one level deep which is sufficient for testing and mapping purposes. The relations
 * are defined using .passThrough() so that the validation won't fail when there are nested
 * relations. The following functions are used to define the relations for consistency.
 */

/**
 * A helper function to define a one-to-one relation
 * @param schema The referenced query model schema
 * @returns A schema with .passthrough() applied (so that nested relations won't fail validation)
 */
type One<To> = To

export const One = <Schema extends z.ZodRawShape>(
  schema: z.ZodObject<Schema>
) => schema.passthrough()

/**
 * A helper function to define a one-to-many relation
 * @param schema The referenced query model schema
 * @returns A schema with .passthrough() applied (so that nested relations won't fail validation) as an array
 */
type Many<To> = Array<To>

export const Many = <Schema extends z.ZodRawShape>(
  schema: z.ZodObject<Schema>
) => One(schema).array()

/**
 * A helper function to define the relations for a specific query model
 * @param relations The set of relations for a specific query model
 * @returns A schema that does not allow any additional properties and allows the relations to be optional
 */
type Relations<Links> = Partial<Links>

const Relations = <Links extends z.ZodRawShape>(links: Links) =>
  z.object(links).partial()

/**
 * TagDomainQueryModel
 */
export const TagDomainQueryModelSchema = TagDomainSelectModelSchema.merge(
  Relations({})
)

export type TagDomainQueryModel = TagDomainSelectModel & Relations<EmptyObject>

/**
 * TagsByCategoryMap
 */
export const TagsByCategoryMapSchema = z.record(
  TagCategorySchema,
  TagDomainQueryModelSchema.array()
)

export type TagsByCategoryMap = ZodSimplify<typeof TagsByCategoryMapSchema>

// This is a function used to transform a list of tags into a map of unique tags by category
export const toTagsByCategoryMap = (
  tags: Many<TagDomainQueryModel> = []
): TagsByCategoryMap => {
  return TagsByCategoryMapSchema.parse({
    ...Object.groupBy(
      uniqBy(tags, (tag) => tag.tagId),
      (tag) => tag.category
    ),
  })
}

/**
 * ActivityDomainQueryModel
 */
export const ActivityDomainQueryModelSchema =
  ActivityDomainSelectModelSchema.merge(
    Relations({
      actor: One(UserDomainSelectModelSchema),
    })
  )

export type ActivityDomainQueryModel<Type extends ActivityType = ActivityType> =
  ActivityDomainSelectModel<Type> &
    Relations<{
      actor: One<UserDomainQueryModel>
    }>

/**
 * BillingProfileDomainQueryModel
 */
export const BillingProfileDomainQueryModelSchema =
  BillingProfileDomainSelectModelSchema.merge(Relations({}))

export type BillingProfileDomainQueryModel = BillingProfileDomainSelectModel &
  Relations<{
    organization: One<OrganizationDomainQueryModel>
  }>

/**
 * TeamProfileDomainQueryModel
 */
export const TeamProfileDomainQueryModelSchema =
  TeamProfileDomainSelectModelSchema.merge(
    Relations({
      team: One(TeamDomainSelectModelSchema),
    })
  )

export type TeamProfileDomainQueryModel = TeamProfileDomainSelectModel &
  Relations<{
    team: One<TeamDomainQueryModel>
  }>

/**
 * AgreementDomainQueryModel
 */
export const AgreementDomainQueryModelSchema =
  AgreementDomainSelectModelSchema.merge(Relations({}))

export type AgreementDomainQueryModel = AgreementDomainSelectModel &
  Relations<EmptyObject>

/**
 * BidDomainQueryModel
 */
export const BidDomainQueryModelSchema = BidDomainSelectModelSchema.merge(
  Relations({
    agencyTeam: One(TeamDomainSelectModelSchema),
    files: Many(FileDomainSelectModelSchema),
    project: One(ProjectViewDomainSelectModelSchema),
  })
)

export type BidDomainQueryModel = BidDomainSelectModel &
  Relations<{
    agencyTeam: One<TeamDomainQueryModel>
    files: Many<FileDomainQueryModel>
    project: One<ProjectDomainQueryModel>
  }>

/**
 * DeliverableDomainQueryModel
 */
export const DeliverableDomainQueryModelSchema =
  DeliverableDomainSelectModelSchema.merge(
    Relations({
      file: One(FileDomainSelectModelSchema),
      project: One(ProjectViewDomainSelectModelSchema),
      service: One(ProjectServiceDomainSelectModelSchema),
    })
  )

export type DeliverableDomainQueryModel = DeliverableDomainSelectModel &
  Relations<{
    file: One<FileDomainQueryModel>
    project: One<ProjectDomainQueryModel>
    service: One<ProjectServiceDomainQueryModel>
  }>

/**
 * FileDomainQueryModel
 */
export const FileDomainQueryModelSchema = FileDomainSelectModelSchema.merge(
  Relations({
    owner: One(UserDomainSelectModelSchema),
    project: One(ProjectDomainSelectModelSchema),
    service: One(ProjectServiceDomainSelectModelSchema),
    tags: Many(TagDomainSelectModelSchema),
  })
)

export type FileDomainQueryModel = FileDomainSelectModel &
  Relations<{
    owner: One<UserDomainQueryModel>
    project: One<ProjectDomainQueryModel>
    service: One<ProjectServiceDomainQueryModel>
    tags: Many<TagDomainQueryModel>
  }>

/**
 * NotificationDomainQueryModel
 */
export const NotificationDomainQueryModelSchema =
  NotificationDomainSelectModelSchema.merge(
    Relations({
      activity: One(ActivityDomainSelectModelSchema),
      recipient: One(UserDomainSelectModelSchema),
    })
  )

export type NotificationDomainQueryModel = NotificationDomainSelectModel &
  Relations<{
    activity: One<ActivityDomainQueryModel>
    recipient: One<UserDomainQueryModel>
  }>

/**
 * OfferDomainQueryModel
 */
export const OfferDomainQueryModelSchema = OfferDomainSelectModelSchema.merge(
  Relations({
    agencyTeam: One(TeamDomainSelectModelSchema),
    acceptor: One(UserDomainSelectModelSchema),
    project: One(ProjectViewDomainSelectModelSchema),
  })
)

export type OfferDomainQueryModel = OfferDomainSelectModel &
  Relations<{
    agencyTeam: One<TeamDomainQueryModel>
    acceptor: One<UserDomainQueryModel>
    project: One<ProjectDomainQueryModel>
  }>

/**
 * OrganizationDomainQueryModel
 */
export const OrganizationDomainQueryModelSchema =
  OrganizationDomainSelectModelSchema.merge(
    Relations({
      teams: Many(TeamDomainSelectModelSchema),
      programs: Many(OrganizationProgramDomainSelectModelSchema),
      users: Many(UserDomainSelectModelSchema),
      billingProfiles: Many(BillingProfileDomainSelectModelSchema),
    })
  )

export type OrganizationDomainQueryModel = OrganizationDomainSelectModel &
  Relations<{
    teams: Many<TeamDomainQueryModel>
    users: Many<UserDomainQueryModel>
    programs: Many<OrganizationProgramDomainQueryModel>
  }>

/**
 * OrganizationProgramDomainQueryModel
 */
export const OrganizationProgramDomainQueryModelSchema =
  OrganizationProgramDomainSelectModelSchema.merge(
    Relations({
      organization: One(OrganizationDomainSelectModelSchema),
    })
  )

export type OrganizationProgramDomainQueryModel =
  OrganizationProgramDomainSelectModel &
    Relations<{
      organization: One<OrganizationDomainQueryModel>
    }>

/**
 * PackageDomainQueryModel
 */
export const PackageDomainQueryModelSchema =
  PackageDomainSelectModelSchema.merge(
    Relations({
      services: Many(PackageServiceViewDomainSelectModelSchema),
      tags: Many(TagDomainSelectModelSchema),
    })
  )

export type PackageDomainQueryModel = PackageDomainSelectModel &
  Relations<{
    services: Many<PackageServiceDomainQueryModel>
    tags: Many<TagDomainQueryModel>
  }>

/**
 * PackageServiceDomainQueryModel
 */
export const PackageServiceDomainQueryModelSchema =
  PackageServiceViewDomainSelectModelSchema.merge(
    Relations({
      tags: Many(TagDomainSelectModelSchema),
    })
  )

export type PackageServiceDomainQueryModel =
  PackageServiceViewDomainSelectModel &
    Relations<{
      tags: Many<TagDomainQueryModel>
    }>

/**
 * ProjectDomainQueryModel
 */
export const ProjectDomainQueryModelSchema =
  ProjectViewDomainSelectModelSchema.merge(
    Relations({
      agencyTeam: One(TeamDomainSelectModelSchema),
      acceptor: One(UserDomainSelectModelSchema),
      brandTeam: One(TeamDomainSelectModelSchema),
      owner: One(UserDomainSelectModelSchema),
      activity: Many(ActivityDomainSelectModelSchema),
      services: Many(ProjectServiceDomainSelectModelSchema),
      offers: Many(OfferDomainSelectModelSchema),
      files: Many(FileDomainSelectModelSchema),
      tags: Many(TagDomainSelectModelSchema),
      review: One(ReviewDomainSelectModelSchema),
      deliverables: Many(DeliverableDomainSelectModelSchema),
      thumbnail: One(FileDomainSelectModelSchema),
      bids: Many(BidDomainSelectModelSchema),
      package: One(PackageDomainSelectModelSchema),
    })
  )

export type ProjectDomainQueryModel = ProjectViewDomainSelectModel &
  Relations<{
    agencyTeam: One<TeamDomainQueryModel>
    acceptor: One<UserDomainQueryModel>
    brandTeam: One<TeamDomainQueryModel>
    owner: One<UserDomainQueryModel>
    activity: Many<ActivityDomainQueryModel>
    services: Many<ProjectServiceDomainQueryModel>
    offers: Many<OfferDomainQueryModel>
    files: Many<FileDomainQueryModel>
    tags: Many<TagDomainQueryModel>
    review: One<ReviewDomainQueryModel>
    deliverables: Many<DeliverableDomainQueryModel>
    thumbnail: One<FileDomainQueryModel>
    bids: Many<BidDomainQueryModel>
    package: One<PackageDomainQueryModel>
  }>

/**
 * ProjectServiceDomainQueryModel
 */
export const ProjectServiceDomainQueryModelSchema =
  ProjectServiceDomainSelectModelSchema.merge(
    Relations({
      files: Many(FileDomainSelectModelSchema),
      project: One(ProjectViewDomainSelectModelSchema),
      deliverables: Many(DeliverableDomainSelectModelSchema),
      review: One(ReviewDomainSelectModelSchema),
      tags: Many(TagDomainSelectModelSchema),
    })
  )

export type ProjectServiceDomainQueryModel = ProjectServiceDomainSelectModel &
  Relations<{
    files: Many<FileDomainQueryModel>
    project: One<ProjectDomainQueryModel>
    deliverables: Many<DeliverableDomainQueryModel>
    review: One<ReviewDomainQueryModel>
    tags: Many<TagDomainQueryModel>
  }>

/**
 * ProofDomainQueryModel
 */
export const ProofDomainQueryModelSchema = ProofDomainSelectModelSchema.merge(
  Relations({
    deliverable: One(DeliverableDomainSelectModelSchema),
    file: One(FileDomainSelectModelSchema),
    round: One(RoundDomainSelectModelSchema),
  })
)

export type ProofDomainQueryModel = SimplifyDeep<
  ProofDomainSelectModel &
    Relations<{
      deliverable: One<DeliverableDomainQueryModel>
      file: One<FileDomainQueryModel>
      round: One<RoundDomainQueryModel>
    }>
>

/**
 * ReviewDomainQueryModel
 */
export const ReviewDomainQueryModelSchema = ReviewDomainSelectModelSchema.merge(
  Relations({
    project: One(ProjectDomainQueryModelSchema),
    service: One(ProjectServiceDomainSelectModelSchema),
    rounds: Many(RoundDomainSelectModelSchema),
  })
)

export type ReviewDomainQueryModel = ReviewDomainSelectModel &
  Relations<{
    project: One<ProjectDomainQueryModel>
    service: One<ProjectServiceDomainQueryModel>
    rounds: Many<RoundDomainQueryModel>
  }>

/**
 * RoundDomainQueryModel
 */
export const RoundDomainQueryModelSchema = RoundDomainSelectModelSchema.merge(
  Relations({
    proofs: Many(ProofDomainSelectModelSchema),
  })
).extend({
  proposal: RoundDomainSelectModelSchema.shape.proposal.extend({
    submitted: RoundDomainSelectModelSchema.shape.proposal.shape.submitted
      .unwrap()
      .merge(
        Relations({
          actor: One(UserDomainSelectModelSchema),
        })
      )
      .optional(),
  }),
  feedback: RoundDomainSelectModelSchema.shape.feedback.extend({
    submitted: RoundDomainSelectModelSchema.shape.feedback.shape.submitted
      .unwrap()
      .merge(
        Relations({
          actor: One(UserDomainSelectModelSchema),
        })
      )
      .optional(),
  }),
})

export type RoundDomainQueryModel = SimplifyDeep<
  OverrideProperties<
    RoundDomainSelectModel &
      Relations<{
        proofs: Many<ProofDomainQueryModel>
      }>,
    {
      proposal: OverrideProperties<
        RoundDomainSelectModel['proposal'],
        Partial<{
          submitted: RoundDomainSelectModel['proposal']['submitted'] &
            Relations<{
              actor: One<UserDomainQueryModel>
            }>
        }>
      >
      feedback: OverrideProperties<
        RoundDomainSelectModel['feedback'],
        Partial<{
          submitted: RoundDomainSelectModel['feedback']['submitted'] &
            Relations<{
              actor: One<UserDomainQueryModel>
            }>
        }>
      >
    }
  >
>

/**
 * ServiceDomainQueryModel
 */
export const ServiceDomainQueryModelSchema =
  ServiceDomainSelectModelSchema.merge(
    Relations({
      tags: Many(TagDomainSelectModelSchema),
    })
  )

export type ServiceDomainQueryModel = ServiceDomainSelectModel &
  Relations<{
    tags: Many<TagDomainQueryModel>
  }>

/**
 * SurveyDomainQueryModel
 */
export const SurveyDomainQueryModelSchema = SurveyDomainSelectModelSchema.merge(
  Relations({})
)

export type SurveyDomainQueryModel = SurveyDomainSelectModel &
  Relations<EmptyObject>

/**
 * TeamDomainQueryModel
 */
export const TeamDomainQueryModelSchema = TeamDomainSelectModelSchema.merge(
  Relations({
    avatar: One(FileDomainSelectModelSchema),
    files: Many(FileDomainSelectModelSchema),
    organization: One(OrganizationDomainSelectModelSchema),
    profile: One(TeamProfileDomainSelectModelSchema),
    projects: Many(ProjectViewDomainSelectModelSchema),
    users: Many(UserDomainSelectModelSchema),
    tags: Many(TagDomainSelectModelSchema),
  })
)

export type TeamDomainQueryModel = TeamDomainSelectModel &
  Relations<{
    avatar: One<FileDomainQueryModel>
    files: Many<FileDomainQueryModel>
    organization: One<OrganizationDomainQueryModel>
    profile: One<TeamProfileDomainQueryModel>
    projects: Many<ProjectDomainQueryModel>
    users: Many<UserDomainQueryModel>
    tags: Many<TagDomainQueryModel>
  }>

export const withTeamIds = <TModel extends { teams?: TeamDomainQueryModel[] }>(
  model: TModel
) => ({
  ...model,
  teamIds: model.teams?.map((team) => team.teamId) ?? [],
})

/**
 * UserDomainQueryModel
 */
export const UserDomainQueryModelSchema = UserDomainSelectModelSchema.merge(
  Relations({
    avatar: One(FileDomainSelectModelSchema),
    files: Many(FileDomainSelectModelSchema),
    organization: One(OrganizationDomainSelectModelSchema),
    tags: Many(TagDomainSelectModelSchema),
    teams: Many(TeamDomainSelectModelSchema),
  })
)

export type UserDomainQueryModel = UserDomainSelectModel &
  Relations<{
    avatar: One<FileDomainQueryModel>
    files: Many<FileDomainQueryModel>
    organization: One<OrganizationDomainQueryModel>
    tags: Many<TagDomainQueryModel>
    teams: Many<TeamDomainQueryModel>
  }>

/**
 * WatchDomainQueryModel
 */
export const WatchDomainQueryModelSchema = WatchDomainSelectModelSchema.merge(
  Relations({
    user: One(UserDomainSelectModelSchema),
  })
)

export type WatchDomainQueryModel = WatchDomainSelectModel &
  Relations<{
    user: One<UserDomainQueryModel>
  }>
