import { themeBackgroundMap, themeBorderColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { type TestIds, getTestProps } from '../../../utils/testing.ts'

const SpotlightCard = ({
  children,
  className,
  ...testProps
}: PropsWithChildren<{ className?: string } & TestIds>) => {
  return (
    <div
      className={cn(
        'w-full p-8 gap-8 flex flex-col items-center border rounded-lg',
        themeBackgroundMap['container-tertiary'],
        themeBorderColorMap.muted,
        className
      )}
      {...getTestProps(testProps)}
    >
      {children}
    </div>
  )
}

export { SpotlightCard }
