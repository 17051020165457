import type { OverrideProperties, Simplify } from 'type-fest'
import { z } from 'zod'

import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  DateSchema,
  NonEmptyStringSchema,
  UrlSchema,
} from './property.models.ts'
import { AgreementIdSchema } from './unique-id.models.ts'

export const [AgreementTypes, AgreementTypeSchema, AgreementTypeEnum] =
  EnumBuilder(
    'project-statement-of-work', // SOW terms to be accepted by Brand
    'project-greenlight-agreement', // GLA terms to be accepted by Maker
    'maker-project-payment-terms' // Payment terms to be accepted by Maker
  )
export type AgreementType = ZodSimplify<typeof AgreementTypeSchema>

export const AgreementVersionSchema = DateSchema

/**
 * AgreementDomainSelectModel
 */
export const AgreementDomainSelectModelSchema = z.object({
  agreementId: AgreementIdSchema,
  type: AgreementTypeSchema,
  version: AgreementVersionSchema,
  title: NonEmptyStringSchema(),
  link: UrlSchema,
})

export type AgreementDomainSelectModel<
  Type extends AgreementType = AgreementType,
> = OverrideProperties<
  ZodSimplify<typeof AgreementDomainSelectModelSchema>,
  { type: Type }
>

/**
 * AgreementDomainSelectModelFilters
 */
export const AgreementDomainSelectModelFiltersSchema = z
  .object({
    agreementId: ModelFilterSchema(AgreementIdSchema),
    type: ModelFilterSchema(AgreementTypeSchema),
  })
  .partial()

export type AgreementDomainSelectModelFilters = ZodSimplify<
  typeof AgreementDomainSelectModelFiltersSchema
>

export const ProjectAgreementSchema = AgreementDomainSelectModelSchema.pick({
  agreementId: true,
  version: true,
  type: true,
})

export type ProjectAgreement = ZodSimplify<typeof ProjectAgreementSchema>

export const ProjectStatementOfWorkAgreementSchema =
  ProjectAgreementSchema.extend({
    type: AgreementTypeSchema.extract(['project-statement-of-work']),
  })

export type ProjectStatementOfWorkAgreement = ZodSimplify<
  typeof ProjectStatementOfWorkAgreementSchema
>

export const ProjectGreenlightAgreementSchema = ProjectAgreementSchema.extend({
  type: AgreementTypeSchema.extract(['project-greenlight-agreement']),
})

export type ProjectGreenlightAgreement = ZodSimplify<
  typeof ProjectGreenlightAgreementSchema
>

export const MakerProjectPaymentTermsAgreementSchema =
  ProjectAgreementSchema.extend({
    type: AgreementTypeSchema.extract(['maker-project-payment-terms']),
  })

export type MakerProjectPaymentTermsAgreement = ZodSimplify<
  typeof MakerProjectPaymentTermsAgreementSchema
>

export const isAgreementType = <
  Agreement extends AgreementDomainSelectModel,
  Type extends AgreementType,
>(
  agreement: Agreement | undefined,
  type: Type
): agreement is Simplify<Agreement & { type: Type }> => {
  return agreement?.type === type
}
