import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ProjectStatusSchema } from '../project.models.ts'
import { ProjectIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

// This is a subset of the project statuses since not all status changes require an activity
export const ProjectStatusChangedActivityStatusSchema =
  ProjectStatusSchema.extract([
    'complete',
    'matched',
    'post_production_complete',
    'pre_production_complete',
    'priced',
    'pricing',
    'processing_final_files',
    'production',
    'submitted',
  ])

export type ProjectStatusChangedActivityStatus = ZodSimplify<
  typeof ProjectStatusChangedActivityStatusSchema
>

const ProjectStatusChangedActivityDataSchema = z.object({
  projectId: ProjectIdSchema,
  ...ActivityLinkTargets('project'),
})

export const ProjectStatusChangedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_status_changed',
    // Right now the data schemas are the same for all statuses, but
    // in the future we may have different shapes for different statuses
    z.discriminatedUnion('status', [
      ProjectStatusChangedActivityDataSchema.extend({
        status: ProjectStatusChangedActivityStatusSchema,
      }),
    ])
  )
