'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import {
  type UseFieldGridProps,
  useFieldGrid,
} from '../../hooks/use-field-grid.ts'
import { DataListDescription } from './data-list-description.tsx'
import { DataListItem } from './data-list-item.tsx'
import { DataListTitle } from './data-list-title.tsx'
import {
  DataListProvider,
  type UseDataListProps,
  useDataList,
} from './use-data-list.ts'

type DataListProps = PropsWithChildren<
  UseDataListProps & UseFieldGridProps & { className?: string }
>

const DataListComponent = ({
  children,
  className,
  columnCount = 1,
  columnGap,
  gap,
  rowGap,
  ...useDataListProps
}: DataListProps) => {
  const context = useDataList(useDataListProps)
  const { fieldGridClassName } = useFieldGrid({
    columnCount,
    columnGap,
    gap,
    rowGap,
  })

  return (
    <DataListProvider value={context}>
      <dl className={cn(fieldGridClassName, className)}>{children}</dl>
    </DataListProvider>
  )
}

const DataListNamespace = Object.assign(DataListComponent, {
  Item: DataListItem,
  Title: DataListTitle,
  Description: DataListDescription,
})

export { DataListNamespace as DataList, type DataListProps }
