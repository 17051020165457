'use client'

import { type AbstractImage, cn } from '@mntn-dev/ui-utilities'
import { easeOut, motion } from 'framer-motion'
import type { Person } from '../../types'
import { getTestProps } from '../../utils/testing.ts'
import { Avatar } from '../avatar'
import { Stack } from '../stack'
import { Text } from '../text/text'
import { CollapsibleButton } from './collapsible-button.tsx'
import { useCollapsibleContext } from './use-collapsible.ts'

type CollapsibleCommentProps = {
  comment: string
  user: Person
  image: AbstractImage
  title?: string
}

const CollapsibleComment = ({
  comment,
  user,
  image,
  title,
}: CollapsibleCommentProps) => {
  const { isOpen, dataTestId, dataTrackingId } = useCollapsibleContext()

  return (
    <div
      {...getTestProps({
        dataTestId: `${dataTestId}-comment-container`,
        dataTrackingId: `${dataTrackingId}-comment-container`,
      })}
    >
      <CollapsibleButton>
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'fit-content', padding: 6 }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3, ease: easeOut }}
        >
          <Stack
            justifyContent="center"
            alignItems="start"
            gap="8"
            alignSelf="stretch"
            className="overflow-visible"
          >
            <Avatar
              size="sm"
              className={cn(
                'transition-all duration-200 ease-in-out',
                isOpen && 'scale-125'
              )}
              dataTestId={`${dataTestId}-avatar`}
              dataTrackingId={`${dataTrackingId}-avatar`}
            >
              <Avatar.Person person={user} image={image} />
            </Avatar>
            <Stack
              direction="col"
              width="full"
              justifyContent="center"
              className="text-left "
            >
              <motion.div
                key="title-area"
                initial={{ opacity: 0, height: 0, paddingTop: 8 }}
                animate={
                  isOpen
                    ? { opacity: 1, height: 'auto', paddingTop: 0 }
                    : { opacity: 0, height: 0, paddingTop: 8 }
                }
                transition={{ duration: 0.3, ease: easeOut }}
              >
                <Stack direction="col">
                  {title && (
                    <Text
                      textColor="negative"
                      fontSize="sm"
                      dataTestId={`${dataTestId}-title`}
                      dataTrackingId={`${dataTrackingId}-title`}
                    >
                      {title}
                    </Text>
                  )}
                  <Text
                    textColor="primary"
                    fontSize="med"
                    dataTestId={`${dataTestId}-username`}
                    dataTrackingId={`${dataTrackingId}-username`}
                  >
                    {user.displayName}
                  </Text>
                </Stack>
              </motion.div>
              <Text
                textColor="secondary"
                fontSize="med"
                className={cn(!isOpen && 'truncate line-clamp-1')}
                dataTestId={`${dataTestId}-comment`}
                dataTrackingId={`${dataTrackingId}-comment`}
              >
                {comment}
              </Text>
            </Stack>
          </Stack>
        </motion.div>
      </CollapsibleButton>
    </div>
  )
}

export { CollapsibleComment }
