'use client'

import { themeBorderColorMap } from '@mntn-dev/ui-theme'
import { getTestProps } from '../../utils'
import { Text } from '../text'
import { CollapsibleButton } from './collapsible-button.tsx'
import { useCollapsibleContext } from './use-collapsible.ts'

type CollapsibleTitleProps = {
  title: string
}

const CollapsibleTitle = ({ title }: CollapsibleTitleProps) => {
  const { dataTestId, dataTrackingId } = useCollapsibleContext()
  return (
    <div
      className={`pl-8 pr-2 py-2 border-b ${themeBorderColorMap.muted}`}
      {...getTestProps({
        dataTestId: `${dataTestId}-title`,
        dataTrackingId: `${dataTrackingId}-title`,
      })}
    >
      <CollapsibleButton>
        <Text
          textColor="secondary"
          fontSize="xs"
          textTransform="uppercase"
          fontWeight="semibold"
          className="flex items-center"
        >
          {title}
        </Text>
      </CollapsibleButton>
    </div>
  )
}

export { CollapsibleTitle }
