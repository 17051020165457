'use client'

import { forwardRef, type PropsWithChildren, useCallback } from 'react'
import { getTestProps, type TestIds } from '../../utils'
import { Button, type ButtonProps } from '../buttons'
import { cn } from '@mntn-dev/ui-utilities'
import { useRadioGroupContext } from './use-radio-group'
import { Radio } from '@headlessui/react'
import { getRadioButtonClassNames } from './styles'

export type RadioButtonsItemProps = Readonly<
  PropsWithChildren<
    TestIds &
      ButtonProps & {
        value: string
      }
  >
>

export const RadioButton = forwardRef<HTMLDivElement, RadioButtonsItemProps>(
  (props, ref) => {
    const { value: selectedValue } = useRadioGroupContext()

    const getButtonProps = useCallback(
      (checked: boolean): ButtonProps => {
        const { value, children, dataTestId, dataTrackingId, ...buttonProps } =
          props
        return {
          ...buttonProps,
          variant: checked ? 'primary' : 'radio',
          iconLeft: checked ? 'CheckIcon' : undefined,
          dataTestId: `${dataTestId}-button`,
          dataTrackingId: `${dataTrackingId}-button`,
          className: cn(
            buttonProps.className,
            getRadioButtonClassNames({
              isChecked: checked,
              siblingIsChecked: !checked && !!selectedValue,
              colorTheme: buttonProps.colorTheme,
            })
          ),
        }
      },
      [props, selectedValue]
    )

    return (
      <Radio
        ref={ref}
        value={props.value}
        disabled={props.disabled}
        {...getTestProps({
          dataTestId: props.dataTestId,
          dataTrackingId: props.dataTrackingId,
        })}
      >
        {({ checked }) => (
          <Button {...getButtonProps(checked)}>{props.children}</Button>
        )}
      </Radio>
    )
  }
)
