import { z } from 'zod'

import type { ZodSimplify } from '@mntn-dev/utility-types'
import { OrganizationDomainInsertModelSchema } from './organization.models.ts'
import { UserDomainInsertModelSchema } from './user.models.ts'

export const OrganizationRegistrationModelSchema =
  OrganizationDomainInsertModelSchema

export const UserRegistrationModelSchema = UserDomainInsertModelSchema.pick({
  firstName: true,
  lastName: true,
  emailAddress: true,
})

export const RegistrationModelSchema = z.object({
  user: UserRegistrationModelSchema,
  organization: OrganizationRegistrationModelSchema,
})

export type RegistrationModel = ZodSimplify<typeof RegistrationModelSchema>
