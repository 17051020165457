import { cn } from '@mntn-dev/ui-utilities'

import { useSizing } from '../../hooks/use-sizing.ts'
import { Stack } from '../stack/stack.tsx'
import { Skeleton } from './skeleton.tsx'
import type { SkeletonProps } from './types.ts'

type SkeletonCrumbProps = SkeletonProps

const SkeletonCrumb = ({ className, ...props }: SkeletonCrumbProps) => {
  const sizing = useSizing({
    ...props,
  })

  return (
    <Stack key="2" direction="col" width="24">
      <div
        className={cn('animate-pulse', {
          ...Object.values(sizing),
          className,
        })}
      >
        <Skeleton variant="rectangle" width="full" height="3" />
      </div>
    </Stack>
  )
}

export { SkeletonCrumb, type SkeletonCrumbProps }
