'use client'

import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import { getTestProps } from '../../utils/testing.ts'
import { Icon } from '../icon'
import { useCollapsibleContext } from './use-collapsible.ts'

type CollapsibleButtonProps = PropsWithChildren<{ className?: string }>

const CollapsibleButton = ({ className, children }: CollapsibleButtonProps) => {
  const { isOpen, toggleOpen, dataTestId, dataTrackingId } =
    useCollapsibleContext()

  return (
    <button
      type="button"
      className={cn(
        'flex w-full min-h-10 items-top gap-4 self-stretch justify-between',
        className
      )}
      onClick={toggleOpen}
      {...getTestProps({
        dataTestId: `${dataTestId}-button`,
        dataTrackingId: `${dataTrackingId}-button`,
      })}
    >
      {children}
      <Icon
        name="ChevronDownIcon"
        color="info"
        size="sm"
        className={cn(isOpen && 'rotate-180', 'w-6 m-3')}
      />
    </button>
  )
}

export { CollapsibleButton }
