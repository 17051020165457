import { z } from 'zod'

import {
  EnumBuilder,
  type NonEmptyArray,
  type ZodSimplify,
} from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { OpaqueSchema } from '../unique-id-builder.ts'
import {
  DescriptionSchema,
  NameSchema,
  NonEmptyStringSchema,
  TimestampSchema,
} from './property.models.ts'
import { TagFilterSchema } from './tag.models.ts'
import {
  BidUrnSchema,
  FileIdSchema,
  PackageUrnSchema,
  ProjectServiceUrnSchema,
  ProjectUrnSchema,
  ReviewUrnSchema,
  TeamUrnSchema,
  UserIdSchema,
  UserUrnSchema,
} from './unique-id.models.ts'

/**
 * FileUploadStatus
 */
export const [
  FileUploadStatuses,
  FileUploadStatusSchema,
  FileUploadStatusEnum,
] = EnumBuilder('unknown', 'initiated', 'complete')
export type FileUploadStatus = ZodSimplify<typeof FileUploadStatusSchema>

/**
 * FileStatus
 */
export const [FileStatuses, FileStatusSchema, FileStatusEnum] = EnumBuilder(
  'active',
  'archived'
)
export type FileStatus = ZodSimplify<typeof FileStatusSchema>

/**
 * FileCategory
 */
export const [FileCategories, FileCategorySchema, FileCategoryEnum] =
  EnumBuilder(
    'document',
    'image',
    'raw',
    'video',
    'audio',
    'archive',
    'image_document'
  )
export type FileCategory = ZodSimplify<typeof FileCategorySchema>

export const ThumbnailableFileCategories: NonEmptyArray<FileCategory> = [
  'image',
  'image_document',
  'video',
]

/**
 * FileMimeType - this could become an enum if we need to validate specific mime types
 */
export const FileMimeTypeSchema = NonEmptyStringSchema(256)

/**
 * FileSize
 */
export const FileSizeSchema = z.number().int().positive()

/**
 * FileTaggingStatus
 */
export const [FileTaggingStatuses, FileTaggingStatusSchema] = EnumBuilder(
  'pending',
  'processing',
  'complete',
  'error'
)
export type FileTaggingState = ZodSimplify<typeof FileTaggingStatusSchema>

/**
 * FolderUrn
 */
export const FolderUrnSchema = z.union([
  BidUrnSchema,
  PackageUrnSchema,
  ProjectUrnSchema,
  ProjectServiceUrnSchema,
  ReviewUrnSchema,
  TeamUrnSchema,
  UserUrnSchema,
])
export type FolderUrn = ZodSimplify<typeof FolderUrnSchema>

type ExtractFolderIdTag<T> = T extends `urn:${infer U}:${string}` ? U : never

type ExtractFolderUrnId<T> = T extends `urn:${string}:${infer I}` ? I : never

export type FolderIdTag = ExtractFolderIdTag<FolderUrn>

export type FolderUrnId = ExtractFolderUrnId<FolderUrn>

// Function to extract FolderIdTag and FolderUrnId from a FolderUrn string
export function parseFolderUrn(urn: FolderUrn): [FolderIdTag, FolderUrnId] {
  const [, folderIdTag, folderUrnId] = FolderUrnSchema.transform(
    (input) => input.split(':') as ['urn', FolderIdTag, FolderUrnId]
  ).parse(urn)

  return [folderIdTag, folderUrnId]
}

/**
 * FileStorageKey
 */
export const FileStorageKeySchema = OpaqueSchema(
  NonEmptyStringSchema(256),
  'file-storage-key'
)
export type FileStorageKey = ZodSimplify<typeof FileStorageKeySchema>
export const FileStorageKey = (s: string) => FileStorageKeySchema.parse(s)

/**
 * FileArea
 */

export const [FileAreas, FileAreaSchema, FileAreaEnum] = EnumBuilder(
  'bids.examples',
  'packages.examples',
  'projects.assets.images',
  'projects.assets.reference',
  'projects.assets.videos',
  'projects.services.assets.deliverables',
  'projects.services.assets.final',
  'projects.services.assets.reference',
  'projects.thumbnails',
  'teams.files.general',
  'teams.profiles.examples',
  'teams.avatars',
  'users.avatars',
  'users.files.general'
)

export type FileArea = ZodSimplify<typeof FileAreaSchema>

export const PublicFileAreaSchema = FileAreaSchema.extract([
  'users.avatars',
  'teams.avatars',
])
export const PublicFileAreas = FileAreaSchema.options

export type PublicFileArea = ZodSimplify<typeof PublicFileAreaSchema>

export const TaggableFileAreas: NonEmptyArray<FileArea> = [
  'projects.services.assets.final',
]
export const TaggableFileCategories: NonEmptyArray<FileCategory> = ['video']

export const FileDomainSelectModelSchema = z.object({
  fileId: FileIdSchema,
  folderUrn: FolderUrnSchema,
  area: FileAreaSchema,
  name: NameSchema,
  category: FileCategorySchema,
  mimeType: FileMimeTypeSchema,
  size: FileSizeSchema,
  status: FileStatusSchema,
  storageKey: FileStorageKeySchema,
  title: NameSchema.optional(),
  description: DescriptionSchema.optional(),
  ownerId: UserIdSchema,
  taggingStatus: FileTaggingStatusSchema.optional(),
  uploadTimestamp: TimestampSchema,
  uploadStatus: FileUploadStatusSchema,
})

export type FileDomainSelectModel = ZodSimplify<
  typeof FileDomainSelectModelSchema
>

/**
 * FileDomainInsertModel
 */
export const FileDomainInsertModelSchema = FileDomainSelectModelSchema.omit({
  status: true,
  uploadTimestamp: true,
})
  .partial({
    fileId: true,
    ownerId: true,
  })
  .extend({
    linkSourceFileId: FileIdSchema.optional(),
  })

export type FileDomainInsertModel = ZodSimplify<
  typeof FileDomainInsertModelSchema
>

/**
 * FileDomainUpdateModel
 */
export const FileDomainUpdateModelSchema = z
  .object({
    title: FileDomainSelectModelSchema.shape.title.nullable(),
    description: FileDomainSelectModelSchema.shape.description.nullable(),
    status: FileStatusSchema,
    taggingStatus: FileTaggingStatusSchema,
    uploadStatus: FileUploadStatusSchema,
    folderUrn: FolderUrnSchema,
  })
  .partial()

export type FileDomainUpdateModel = ZodSimplify<
  typeof FileDomainUpdateModelSchema
>

/**
 * FileDomainSelectModelFilter
 */
export const FileDomainSelectModelFiltersSchema = z
  .object({
    fileId: ModelFilterSchema(FileIdSchema),
    area: ModelFilterSchema(FileAreaSchema),
    category: ModelFilterSchema(FileCategorySchema),
    folderUrn: ModelFilterSchema(FolderUrnSchema),
    search: NonEmptyStringSchema(),
    status: ModelFilterSchema(FileStatusSchema),
    tags: TagFilterSchema,
    taggingStatus: ModelFilterSchema(FileTaggingStatusSchema),
  })
  .partial()

export type FileDomainSelectModelFilters = ZodSimplify<
  typeof FileDomainSelectModelFiltersSchema
>
