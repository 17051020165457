'use client'
import { cn } from '@mntn-dev/ui-utilities'
import type { PropsWithChildren } from 'react'
import {
  type UseFieldGridFieldProps,
  useFieldGridField,
} from '../../hooks/use-field-grid-field.ts'

type DataListItemProps = PropsWithChildren<
  UseFieldGridFieldProps & { className?: string }
>

const DataListItem = ({
  children,
  className,
  ...fieldGridFieldProps
}: DataListItemProps) => {
  const { fieldGridFieldClassName } = useFieldGridField(fieldGridFieldProps)

  return (
    <div
      className={cn(
        'flex flex-col gap-2 overflow-hidden',
        fieldGridFieldClassName,
        className
      )}
    >
      {children}
    </div>
  )
}

export { DataListItem }
