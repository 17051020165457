import { z } from 'zod'

import { OrganizationTypeSchema } from '../organization.models.ts'
import { ProjectIdSchema, ProjectServiceIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ServiceNoteAddedActivityDetailsSchema = ActivityTypeAndDataSchema(
  'service_note_added',
  z.object({
    projectId: ProjectIdSchema,
    projectServiceId: ProjectServiceIdSchema,
    noteType: OrganizationTypeSchema,
    ...ActivityLinkTargets('project', 'service'),
  })
)
