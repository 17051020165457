import { z } from 'zod'

import type { ZodSimplify } from '@mntn-dev/utility-types'
import { OrganizationTypeSchema } from '../models/organization.models.ts'
import { NonEmptyStringSchema } from '../models/property.models.ts'
import { UserDomainQueryModelSchema } from '../models/query.models.ts'
import { TeamDomainSelectModelSchema } from '../models/team.models.ts'
import {
  OrganizationIdSchema,
  UserIdSchema,
} from '../models/unique-id.models.ts'
import { UserDomainSelectModelWithDeactivateStatusSchema } from '../models/user.models.ts'

export const UserListItemServiceModelSchema =
  UserDomainSelectModelWithDeactivateStatusSchema.pick({
    userId: true,
    firstName: true,
    lastName: true,
    displayName: true,
    emailAddress: true,
    isActive: true,
    canBeDeactivated: true,
    avatarFileId: true,
    organizationId: true,
    organizationType: true,
    initials: true,
  }).extend({
    teams: TeamDomainSelectModelSchema.array().optional(),
  })

export type UserListItemServiceModel = ZodSimplify<
  typeof UserListItemServiceModelSchema
>

const UserListAclSchema = z.object({
  canDeactivate: z.boolean(),
})

export type UserListAcl = ZodSimplify<typeof UserListAclSchema>

export const UserListServiceModelSchema = z.object({
  acl: UserListAclSchema,
  items: z.array(UserListItemServiceModelSchema),
  // Add pagination metadata here (cursor, etc)
})

export type UserListServiceModel = ZodSimplify<
  typeof UserListServiceModelSchema
>

export const UserListOrderByColumnSchema =
  UserDomainQueryModelSchema.keyof().extract([
    'firstName',
    'lastName',
    'emailAddress',
    'organizationType',
  ])

export type UserListOrderByColumn = ZodSimplify<
  typeof UserListOrderByColumnSchema
>

export const UserListOrderByDirectionSchema = z.enum(['asc', 'desc'])

export type UserListOrderByDirection = ZodSimplify<
  typeof UserListOrderByDirectionSchema
>

export const UserListOrderBySchema = z.object({
  column: UserListOrderByColumnSchema,
  direction: UserListOrderByDirectionSchema,
})

export type UserListOrderBy = ZodSimplify<typeof UserListOrderBySchema>

export const UserMaxSearchLength = 256

export const UserSearchSchema = NonEmptyStringSchema(UserMaxSearchLength)

export const UserListWhereSchema = z.object({
  search: UserSearchSchema.optional(),
  userId: z.array(UserIdSchema).nonempty().optional(),
  organizationType: z.array(OrganizationTypeSchema).nonempty().optional(),
  includeDeactivated: z.boolean().optional(),
  organizationId: OrganizationIdSchema.optional(),
})

export type UserListWhere = ZodSimplify<typeof UserListWhereSchema>

export const UserListLimitMax = 1000

export const UserListLimitSchema = z
  .number()
  .int()
  .positive()
  .max(UserListLimitMax)
