import { z } from 'zod'
import type { ZodSimplify } from './utility-types.ts'

export const OrAnyStringSchema = z.string().and(z.object({}))

/**
 * The type resulting from the above schema is a union of string and object.
 * This type is used to represent a string or any object.
 * This type can be used in a string union to allow for any other string.
 * It works because the "any object" prevents the string union from reducing to just a plain string.
 *
 * This is useful when you want to provide a list of strings for intellisense to offer
 * but also want to allow for any other string to be passed in.
 *
 * See the type `FontFace` in the files-shared package for an example of this.
 * Intellisense for FontFace offers up a long list of the most popular Google Font names
 * but also allows for any other string so that other fonts can be used.
 */

export type OrAnyString = ZodSimplify<typeof OrAnyStringSchema>
