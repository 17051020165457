import { capitalize } from '@mntn-dev/utilities'
import type { Formatter } from '../types.ts'

type CapitalizeOptions = {
  allWords?: boolean
}

export const capitalizeFormatter: Formatter<string, CapitalizeOptions> = (
  val: string,
  _,
  options: { allWords?: boolean }
) => {
  const allWords = options.allWords ?? true
  return capitalize(val, allWords)
}
