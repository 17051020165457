import type { FixedLengthTuple, PositiveInteger } from '@mntn-dev/utility-types'

export const makeTuple = <Item, Length extends number>(
  length: PositiveInteger<Length>,
  items: (index: number) => Item
) =>
  Array.from({ length }, (_, index) => items(index)) as FixedLengthTuple<
    Item,
    Length
  >
