'use client'

import React, { useEffect, useImperativeHandle, useRef } from 'react'

import { Input, type InputProps } from './input.tsx'

type AutoInputProps = InputProps &
  Readonly<{
    autoFocus?: boolean
  }>

const AutoInput = React.forwardRef<HTMLInputElement, AutoInputProps>(
  ({ autoFocus, ...props }, outerRef) => {
    const innerRef = useRef<HTMLInputElement>(null)

    // biome-ignore lint/style/noNonNullAssertion: innerRef.current is guaranteed to be set by React lifecycle
    useImperativeHandle(outerRef, () => innerRef.current!, [])

    useEffect(() => {
      if (autoFocus) {
        const input = innerRef?.current
        if (input) {
          input.focus()
          input.select()
        }
      }
    }, [autoFocus])
    return <Input {...props} ref={innerRef} />
  }
)

export { AutoInput }
