import type { FixedLengthArray } from 'type-fest'
import { z } from 'zod'

import { UnhandledUnionError } from '@mntn-dev/utilities'

import type { ActivityType, ActivityTypes } from './activity-types.ts'
import { AgreementTermsAcceptedActivityDetailsSchema } from './agreement-terms-accepted.ts'
import { CommentCreatedActivityDetailsSchema } from './comment-created.ts'
import { PostProductionReviewBrandFeedbackSubmittedActivityDetailsSchema } from './post-production-review-brand-feedback-submitted.ts'
import { PostProductionReviewMakerProposalSubmittedActivityDetailsSchema } from './post-production-review-maker-proposal-submitted.ts'
import { PreProductionBrandFeedbackSubmittedActivityDetailsSchema } from './pre-production-brand-feedback-submitted.ts'
import { PreProductionMakerProposalSubmittedActivityDetailsSchema } from './pre-production-maker-proposal-submitted.ts'
import { PreProductionReviewBrandFeedbackSubmittedActivityDetailsSchema } from './pre-production-review-brand-feedback-submitted.ts'
import { PreProductionReviewMakerProposalSubmittedActivityDetailsSchema } from './pre-production-review-maker-proposal-submitted.ts'
import { ProjectBidAcceptedActivityDetailsSchema } from './project-bid-accepted.ts'
import { ProjectBidRejectedActivityDetailsSchema } from './project-bid-rejected.ts'
import { ProjectBiddingClosedActivityDetailsSchema } from './project-bidding-closed.ts'
import { ProjectBiddingOpenedActivityDetailsSchema } from './project-bidding-opened.ts'
import { ProjectBiddingSuccessfulActivityDetailsSchema } from './project-bidding-successful.ts'
import { ProjectBiddingUnsuccessfulActivityDetailsSchema } from './project-bidding-unsuccessful.ts'
import { ProjectCreatedActivityDetailsSchema } from './project-created.ts'
import { ProjectFileAddedActivityDetailsSchema } from './project-file-added.ts'
import { ProjectNotMatchedActivityDetailsSchema } from './project-not-matched.ts'
import { ProjectOfferExpiredActivityDetailsSchema } from './project-offer-expired.ts'
import { ProjectOfferPendingActivityDetailsSchema } from './project-offer-pending.ts'
import { ProjectOffersSentActivityDetailsSchema } from './project-offers-sent.ts'
import { ProjectServiceAddedActivityDetailsSchema } from './project-service-added.ts'
import { ProjectStatusChangedActivityDetailsSchema } from './project-status-changed.ts'
import { ServiceNoteAddedActivityDetailsSchema } from './service-note-added.ts'

/**
 * This function is used to get the activity details schema for a specific activity type.
 * It is used in conjunction with "satisfies FixedLengthArray" to ensure that all activity
 * types are represented in the ActivityDetailsSchema discriminated union.
 */
export const getActivityDetailsSchema = (activityType: ActivityType) => {
  switch (activityType) {
    case 'agreement_terms_accepted': {
      return AgreementTermsAcceptedActivityDetailsSchema
    }
    case 'comment_created': {
      return CommentCreatedActivityDetailsSchema
    }
    case 'post_production_review_brand_feedback_submitted': {
      return PostProductionReviewBrandFeedbackSubmittedActivityDetailsSchema
    }
    case 'post_production_review_maker_proposal_submitted': {
      return PostProductionReviewMakerProposalSubmittedActivityDetailsSchema
    }
    case 'pre_production_brand_feedback_submitted': {
      return PreProductionBrandFeedbackSubmittedActivityDetailsSchema
    }
    case 'pre_production_maker_proposal_submitted': {
      return PreProductionMakerProposalSubmittedActivityDetailsSchema
    }
    case 'pre_production_review_brand_feedback_submitted': {
      return PreProductionReviewBrandFeedbackSubmittedActivityDetailsSchema
    }
    case 'pre_production_review_maker_proposal_submitted': {
      return PreProductionReviewMakerProposalSubmittedActivityDetailsSchema
    }
    case 'project_bid_accepted': {
      return ProjectBidAcceptedActivityDetailsSchema
    }
    case 'project_bid_rejected': {
      return ProjectBidRejectedActivityDetailsSchema
    }
    case 'project_bidding_closed': {
      return ProjectBiddingClosedActivityDetailsSchema
    }
    case 'project_bidding_opened': {
      return ProjectBiddingOpenedActivityDetailsSchema
    }
    case 'project_bidding_successful': {
      return ProjectBiddingSuccessfulActivityDetailsSchema
    }
    case 'project_bidding_unsuccessful': {
      return ProjectBiddingUnsuccessfulActivityDetailsSchema
    }
    case 'project_created': {
      return ProjectCreatedActivityDetailsSchema
    }
    case 'project_file_added': {
      return ProjectFileAddedActivityDetailsSchema
    }
    case 'project_not_matched': {
      return ProjectNotMatchedActivityDetailsSchema
    }
    case 'project_offer_expired': {
      return ProjectOfferExpiredActivityDetailsSchema
    }
    case 'project_offer_pending': {
      return ProjectOfferPendingActivityDetailsSchema
    }
    case 'project_offers_sent': {
      return ProjectOffersSentActivityDetailsSchema
    }
    case 'project_service_added': {
      return ProjectServiceAddedActivityDetailsSchema
    }
    case 'project_status_changed': {
      return ProjectStatusChangedActivityDetailsSchema
    }
    case 'service_note_added': {
      return ServiceNoteAddedActivityDetailsSchema
    }
    default: {
      throw new UnhandledUnionError(activityType)
    }
  }
}

export const ActivityDetailsSchema = z.discriminatedUnion('activityType', [
  getActivityDetailsSchema('agreement_terms_accepted'),
  getActivityDetailsSchema('comment_created'),
  getActivityDetailsSchema('post_production_review_brand_feedback_submitted'),
  getActivityDetailsSchema('post_production_review_maker_proposal_submitted'),
  getActivityDetailsSchema('pre_production_brand_feedback_submitted'),
  getActivityDetailsSchema('pre_production_maker_proposal_submitted'),
  getActivityDetailsSchema('pre_production_review_brand_feedback_submitted'),
  getActivityDetailsSchema('pre_production_review_maker_proposal_submitted'),
  getActivityDetailsSchema('project_bid_accepted'),
  getActivityDetailsSchema('project_bid_rejected'),
  getActivityDetailsSchema('project_bidding_closed'),
  getActivityDetailsSchema('project_bidding_opened'),
  getActivityDetailsSchema('project_bidding_successful'),
  getActivityDetailsSchema('project_bidding_unsuccessful'),
  getActivityDetailsSchema('project_created'),
  getActivityDetailsSchema('project_file_added'),
  getActivityDetailsSchema('project_not_matched'),
  getActivityDetailsSchema('project_offer_expired'),
  getActivityDetailsSchema('project_offer_pending'),
  getActivityDetailsSchema('project_offers_sent'),
  getActivityDetailsSchema('project_service_added'),
  getActivityDetailsSchema('project_status_changed'),
  getActivityDetailsSchema('service_note_added'),
] satisfies FixedLengthArray<z.AnyZodObject, typeof ActivityTypes.length>)

export * from './activity-data.models.ts'
export * from './activity-types.ts'
export * from './agreement-terms-accepted.ts'
export * from './comment-created.ts'
export * from './post-production-review-brand-feedback-submitted.ts'
export * from './post-production-review-maker-proposal-submitted.ts'
export * from './pre-production-brand-feedback-submitted.ts'
export * from './pre-production-maker-proposal-submitted.ts'
export * from './pre-production-review-brand-feedback-submitted.ts'
export * from './pre-production-review-maker-proposal-submitted.ts'
export * from './project-bidding-opened.ts'
export * from './project-created.ts'
export * from './project-file-added.ts'
export * from './project-not-matched.ts'
export * from './project-offer-expired.ts'
export * from './project-offer-pending.ts'
export * from './project-offers-sent.ts'
export * from './project-service-added.ts'
export * from './project-status-changed.ts'
export * from './service-note-added.ts'
