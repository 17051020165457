/**
 * An Object.keys alternative that returns an array of typed values instead of string.
 * E.g.,
 *  type MyKey = 'foo' | 'bar'
 *  LiteralKeys<MyKey>({ foo: 1, bar: 2 }) -- [ 'foo', 'bar' ] (as MyKey[])
 **/
export const literalKeys = <TKey extends string>(obj: {
  [key: string]: unknown
}) => Object.keys(obj) as TKey[]

export const literalValues = <TKey extends string>(obj: {
  [key: string]: unknown
}) => Object.values(obj) as TKey[]

/**
 * A property typeguard for the specified parameters.
 * E.g.,
 * hasProperty<'foo', number>({ foo: 1 }, 'foo') -- true
 * hasProperty<'foo', number>({ bar: 1 }, 'foo') -- false
 **/
export function hasProperty<TKey extends string, TValue>(
  input: unknown,
  propertyName: TKey
): input is Record<TKey, TValue> {
  return typeof input === 'object' && input !== null && propertyName in input
}
