import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ProjectIdSchema } from '../unique-id.models.ts'
import { SurveyFeedbackSchema, SurveyRatingSchema } from './common.ts'
import { SurveyTypeSchema } from './survey-types.ts'

export const ProjectCompletedBrandReviewsMakerResponsesSchema = z.object({
  makerRating: SurveyRatingSchema,
  finalVideoQualityRating: SurveyRatingSchema,
  feedback: SurveyFeedbackSchema.optional(),
})

export type ProjectCompletedBrandReviewsMakerResponses = ZodSimplify<
  typeof ProjectCompletedBrandReviewsMakerResponsesSchema
>

export const ProjectCompletedBrandReviewsMakerSurveyDetailsSchema = z.object({
  surveyType: SurveyTypeSchema.extract([
    'project-completed-brand-reviews-maker',
  ]),
  projectId: ProjectIdSchema,
  responses: ProjectCompletedBrandReviewsMakerResponsesSchema,
})
