import { z } from 'zod'

import type { ZodSimplify } from '@mntn-dev/utility-types'

/**
 * Schemas and types for Maker Application (bid) Rating
 * https://docs.google.com/spreadsheets/d/1qJsJpgjszt6gyxbX5RG-gTm2AkyOStWkVj4CkxIeUiM/edit?gid=0#gid=0
 */

/**
 * Review Score (Experience/Quality)
 *  3: 4.9+
 *  2: 4.5 - 4.89
 *  1: 4 - 4.49
 *  0: No rating
 * -1: Below 4
 */
export const ReviewScoreSchema = z.union([
  z.literal(3),
  z.literal(2),
  z.literal(1),
  z.literal(0),
  z.literal(-1),
])

/**
 * Count Score (Projects Rated/Projects Completed)
 *  2: 3+
 *  1: 1: 2
 *  0: 0
 */
export type ReviewScore = ZodSimplify<typeof ReviewScoreSchema>

export const CountScoreSchema = z.union([
  z.literal(2),
  z.literal(1),
  z.literal(0),
])

export type CountScore = ZodSimplify<typeof CountScoreSchema>

/**
 * High Value Category Score
 *  3: Yes
 *  0: No
 */
export const HighValueCategoryScoreSchema = z.union([
  z.literal(3),
  z.literal(0),
])

export type HighValueCategoryScore = ZodSimplify<
  typeof HighValueCategoryScoreSchema
>

/**
 * Medium Value Category Score
 *  2: Yes
 *  0: No
 */
export const MediumValueCategoryScoreSchema = z.union([
  z.literal(2),
  z.literal(0),
])

export type MediumValueCategoryScore = ZodSimplify<
  typeof MediumValueCategoryScoreSchema
>

/**
 * Low Value Category Score
 *  1: Yes
 *  0: No
 */
export const LowValueCategoryScoreSchema = z.union([z.literal(1), z.literal(0)])

export type LowValueCategoryScore = ZodSimplify<
  typeof LowValueCategoryScoreSchema
>

/**
 * Bid Scoring Model
 */
export const BidScoringModelSchema = z.object({
  experience: ReviewScoreSchema,
  quality: ReviewScoreSchema,
  projectsRated: CountScoreSchema,
  projectsCompleted: CountScoreSchema,
  inBudget: HighValueCategoryScoreSchema,
  inLocation: HighValueCategoryScoreSchema,
  tier1Status: HighValueCategoryScoreSchema,
  generalQfCertified: HighValueCategoryScoreSchema,
  mntnCertified: HighValueCategoryScoreSchema,
  tikTokCertified: HighValueCategoryScoreSchema,
  metaCertified: HighValueCategoryScoreSchema,
  meetsVerticalTags: LowValueCategoryScoreSchema,
  meetsPlatformTags: MediumValueCategoryScoreSchema,
  meetsSkillTags: HighValueCategoryScoreSchema,
  similarPastBudget: LowValueCategoryScoreSchema,
  meetsLanguageTags: HighValueCategoryScoreSchema,
  relevantExamples: MediumValueCategoryScoreSchema,
})

export type BidScoringModel = ZodSimplify<typeof BidScoringModelSchema>

/**
 * Bid Total Score
 */

export const BidScoreSchema = z.number().int().nonnegative()

export type BidScore = ZodSimplify<typeof BidScoreSchema>
