import type { PropsWithChildren } from 'react'
import type { TestIds } from '../../../utils/testing.ts'
import { Text } from '../../text/text.tsx'

export type CardDescriptionProps = PropsWithChildren<TestIds>

const CardDescription = ({ children, ...testIds }: CardDescriptionProps) => (
  <Text fontSize="sm" textColor="secondary" {...testIds}>
    {children}
  </Text>
)

export { CardDescription }
