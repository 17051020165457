import { z } from 'zod'

import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  DeliverableDetailsSchema,
  DeliverableDomainSelectModelSchema,
  DeliverableReviewLevelSchema,
} from './deliverable.models.ts'
import {
  DescriptionSchema,
  DollarsSchema,
  NameSchema,
  TranslationsSchema,
} from './property.models.ts'
import { TagFilterSchema } from './tag.models.ts'
import { ServiceIdSchema } from './unique-id.models.ts'

/**
 * ServiceStatus
 */
export const [ServiceStatuses, ServiceStatusSchema, ServiceStatusEnum] =
  EnumBuilder('draft', 'published', 'archived')
export type ServiceStatus = ZodSimplify<typeof ServiceStatusSchema>

/**
 * ServiceType
 */
export const [ServiceTypes, ServiceTypeSchema, ServiceTypeEnum] = EnumBuilder(
  'included',
  'standard',
  'custom'
)
export type ServiceType = ZodSimplify<typeof ServiceTypeSchema>

/**
 * ServiceKey
 */ export const [ServiceKeys, ServiceKeySchema, ServiceKeyEnum] = EnumBuilder(
  'pre_production_review_round',
  'post_production_review_round'
)
export type ServiceKey = ZodSimplify<typeof ServiceKeySchema>

/**
 * PreProductionReview
 */
export const PreProductionReviewSchema = z.boolean().default(false)
export type PreProductionReview = ZodSimplify<typeof PreProductionReviewSchema>

/**
 * Max
 */
export const MaxSchema = z.number().int().positive()
export type Max = ZodSimplify<typeof MaxSchema>

/**
 * ServiceTranslations
 */
export const ServiceTranslationsSchema = TranslationsSchema(
  'name',
  'description'
)
export type ServiceTranslations = ZodSimplify<typeof ServiceTranslationsSchema>

/**
 * ServiceDomainSelectModel
 */
export const ServiceDomainSelectModelSchema = z.object({
  serviceId: ServiceIdSchema,
  name: NameSchema,
  description: DescriptionSchema,
  serviceKey: ServiceKeySchema.optional(),
  translations: ServiceTranslationsSchema.optional(),
  deliverables: z.array(DeliverableDetailsSchema).optional(),
  preProductionReview: PreProductionReviewSchema,
  postProductionReview: DeliverableReviewLevelSchema.default('none'),
  max: MaxSchema.optional(),
  status: ServiceStatusSchema,
})

export type ServiceDomainSelectModel = ZodSimplify<
  typeof ServiceDomainSelectModelSchema
>

/**
 * ServiceDomainInsertModel
 */
export const ServiceDomainInsertModelSchema =
  ServiceDomainSelectModelSchema.omit({
    serviceKey: true, // Internal use only
    status: true, // Always inserted as draft
  }).partial({ serviceId: true })

export type ServiceDomainInsertModel = ZodSimplify<
  typeof ServiceDomainInsertModelSchema
>

/**
 * ServiceDomainUpdateModel
 */
export const ServiceDomainUpdateModelSchema =
  ServiceDomainSelectModelSchema.extend({
    max: ServiceDomainSelectModelSchema.shape.max.nullable(),
  })
    .pick({
      name: true,
      description: true,
      translations: true,
      deliverables: true,
      preProductionReview: true,
      postProductionReview: true,
      max: true,
      status: true,
    })
    .partial()

export type ServiceDomainUpdateModel = ZodSimplify<
  typeof ServiceDomainUpdateModelSchema
>

/**
 * ServiceDomainSelectModelFilter
 */
export const ServiceDomainSelectModelFiltersSchema = z
  .object({
    serviceId: ModelFilterSchema(ServiceIdSchema),
    serviceKey: ModelFilterSchema(ServiceKeySchema),
    status: ModelFilterSchema(ServiceStatusSchema),
    tags: TagFilterSchema,
  })
  .partial()

export type ServiceDomainSelectModelFilters = ZodSimplify<
  typeof ServiceDomainSelectModelFiltersSchema
>

/**
 * ServicePayoutSchema
 */
export const ServicePayoutMax = 500_000
export const ServicePayoutSchema = DollarsSchema(ServicePayoutMax)
export type ServicePayout = ZodSimplify<typeof ServicePayoutSchema>

const ServiceDeliverableSchema = z.union([
  DeliverableDetailsSchema,
  DeliverableDomainSelectModelSchema.pick({ details: true }),
])

export type ServiceDeliverable = ZodSimplify<typeof ServiceDeliverableSchema>

const ServiceLikeSchema = ServiceDomainSelectModelSchema.pick({
  serviceId: true,
  serviceKey: true,
}).extend({
  deliverables: z.array(ServiceDeliverableSchema).optional(),
  serviceType: ServiceTypeSchema.optional(),
})

export type ServiceLike = ZodSimplify<typeof ServiceLikeSchema>

/**
 * Type guard for services that have deliverables
 * @param service the service to be checked
 * @returns true if the service has deliverables
 */
export type ServiceWithDeliverables<Service extends ServiceLike> = Service & {
  deliverables: Array<ServiceDeliverable>
}

/**
 * Helper function to unwrap the details property of a deliverable
 * @param deliverable a deliverable details object or a deliverable details object wrapped in a details property
 * @returns the deliverable details object
 */
export const getServiceDeliverableDetails = (
  deliverable: ServiceDeliverable
) => ('details' in deliverable ? deliverable.details : deliverable)

export const serviceWithDeliverables = <Service extends ServiceLike>(
  service: Service
): service is ServiceWithDeliverables<Service> =>
  z.array(ServiceDeliverableSchema).nonempty().safeParse(service.deliverables)
    .success

/**
 * Extracts services from an array that have deliverables
 * @param services the array of services
 * @returns an array of services that have deliverables
 */
export const servicesWithDeliverables = <Service extends ServiceLike>(
  services: Array<Service>
) => services.filter(serviceWithDeliverables)

/**
 * Type guard for services that do not have deliverables
 * @param service the service to be checked
 * @returns true if the service does not have deliverables
 */
export type ServiceWithoutDeliverable<Service extends ServiceLike> = Service & {
  deliverables: undefined
}

export const serviceWithoutDeliverables = <Service extends ServiceLike>(
  service: Service
): service is ServiceWithoutDeliverable<Service> =>
  !serviceWithDeliverables(service)

/**
 * Excludes services from an array that have deliverables
 * @param services an array of models
 * @returns an array of models that do not have deliverables
 */
export const servicesWithoutDeliverables = <Service extends ServiceLike>(
  services: Array<Service>
) => services.filter(serviceWithoutDeliverables)

export const servicesByStatus =
  <Service extends { status: string }>(status: string) =>
  (service: Service): boolean =>
    z.string().safeParse(service.status).success && service.status === status

type CustomService<Service extends ServiceLike> = Service & {
  serviceType: 'custom'
}

type StandardService<Service extends ServiceLike> = Service & {
  serviceType: 'standard'
}

type IncludedService<Service extends ServiceLike> = Service & {
  serviceType: 'included'
}

export const isCustomService = <Service extends ServiceLike>(
  service: Service
): service is CustomService<Service> => service.serviceType === 'custom'

export const isStandardService = <Service extends ServiceLike>(
  service: Service
): service is StandardService<Service> => service.serviceType === 'standard'

export const isIncludedService = <Service extends ServiceLike>(
  service: Service
): service is IncludedService<Service> => service.serviceType === 'included'
