'use client'

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'

import {
  type ThemeBackgroundBlur,
  type ThemeFlexAlignItems,
  type ThemeFlexJustifyContent,
  type ThemeSpacing,
  flexAlignItemsMap,
  flexJustifyMap,
  getMarginMap,
  themeBackgroundBlurMap,
  themeBackgroundMap,
} from '@mntn-dev/ui-theme'

import { type UseSizingProps, useSizing } from '../../hooks/use-sizing.ts'
import { ModalHeading } from './modal-heading.tsx'
import { ModalOverline } from './modal-overline.tsx'
import { ModalSubheading } from './modal-subheading.tsx'
import { ModalSurface } from './modal-surface.tsx'
import { ModalProvider, type UseModalProps, useModal } from './use-modal.ts'

type ModalProps = Readonly<
  UseModalProps &
    UseSizingProps & {
      children: React.ReactNode
      initialFocusRef?: React.MutableRefObject<HTMLElement | null>
      backgroundBlur?: ThemeBackgroundBlur | null
      margin?: ThemeSpacing
      className?: string
      align?: ThemeFlexAlignItems
      justify?: ThemeFlexJustifyContent
    }
>

const Modal = ({
  children,
  initialFocusRef,
  margin = '8',
  className,
  backgroundBlur,
  align = 'center',
  justify = 'center',
  height,
  maxHeight = 'full',
  maxWidth,
  minHeight,
  minWidth,
  size,
  width = 'full',
  ...useModalProps
}: ModalProps) => {
  const context = useModal(useModalProps)
  const { getDialogProps, getPanelProps, onClose, open } = context

  const sizing = useSizing({
    height,
    maxHeight,
    minHeight,
    size,
    width,
    maxWidth,
    minWidth,
  })

  return (
    <ModalProvider value={context}>
      <Transition show={open}>
        <Dialog
          as="div"
          className="relative z-20"
          open={open}
          onClose={onClose}
          initialFocus={initialFocusRef}
          {...getDialogProps()}
        >
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={`fixed inset-0 z-20 ${themeBackgroundMap.container} ${backgroundBlur === null ? null : themeBackgroundBlurMap[backgroundBlur ?? 'blur-lg']}`}
            />
          </TransitionChild>

          <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
            <div
              className={`flex min-h-full items-end ${flexJustifyMap[justify]} p-4 sm:items-center sm:p-0`}
            >
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel
                  className={cn(
                    `relative flex transform flex-col ${flexAlignItemsMap[align]} transition-all xl:max-w-screen-2xl ${getMarginMap('margin')[margin]}`,
                    ...Object.values(sizing),
                    className
                  )}
                  {...getPanelProps()}
                >
                  {children}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </ModalProvider>
  )
}

const ModalNamespace = Object.assign(Modal, {
  Overline: ModalOverline,
  Heading: ModalHeading,
  Subheading: ModalSubheading,
  Surface: ModalSurface,
})

export { ModalNamespace as Modal, type ModalProps }
