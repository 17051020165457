import { z } from 'zod'
import { DateSchema } from '../property.models.ts'
import { ProjectIdSchema, TeamIdSchema } from '../unique-id.models.ts'
import { ActivityLinkTargets } from './activity-data.models.ts'
import { ActivityTypeAndDataSchema } from './utilities.ts'

export const ProjectBiddingOpenedActivityDetailsSchema =
  ActivityTypeAndDataSchema(
    'project_bidding_opened',
    z.object({
      projectId: ProjectIdSchema,
      brandTeamId: TeamIdSchema,
      biddingCloseDate: DateSchema,
      ...ActivityLinkTargets('project', 'brandTeam'),
    })
  )
