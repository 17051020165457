'use client'

import { cn } from '@mntn-dev/ui-utilities'
import { NativeImage } from '@mntn-dev/ui-utilities'
import { type AbstractImage, usePrevious } from '@mntn-dev/ui-utilities'
import { useEffect, useState } from 'react'
import { Heading } from '../heading/heading.tsx'
import { AvatarLoading } from './avatar-loading.tsx'
import type { AvatarEntity } from './types.ts'
import { useAvatarContext } from './use-avatar.ts'

type AvatarEntityProps = Readonly<{
  entity: AvatarEntity
  image?: AbstractImage
  loading?: boolean
}>

type LoadState = 'loading' | 'loaded' | 'error' | 'no-url'

const AvatarEntityComponent = ({
  entity,
  image: Image = NativeImage,
}: AvatarEntityProps) => {
  const {
    borderColor,
    pixelSize,
    sizeClassName,
    textSizeClassName,
    setHasUserImage,
  } = useAvatarContext()

  const { avatarUrl, displayName, initials } = entity

  const [loadState, setLoadState] = useState<LoadState>(
    avatarUrl ? 'loading' : 'no-url'
  )

  const previousAvatarUrl = usePrevious(avatarUrl)

  useEffect(() => {
    // previous will be null on first render, we don't want to run this code then
    if (previousAvatarUrl !== null && avatarUrl !== previousAvatarUrl) {
      setLoadState('loading')
    }
  }, [avatarUrl, previousAvatarUrl])

  const handleLoadingError = () => {
    setLoadState('error')
    setHasUserImage(false)
  }

  const handleLoadComplete = () => {
    setLoadState('loaded')
    setHasUserImage(true)
  }

  return loadState !== 'no-url' && loadState !== 'error' ? (
    <>
      <Image
        onLoad={handleLoadComplete}
        onError={handleLoadingError}
        alt={displayName}
        src={avatarUrl}
        height={pixelSize}
        width={pixelSize}
        className={cn('object-cover rounded-full overflow-hidden', {
          invisible: loadState === 'loading',
        })}
      />
      <div
        className={cn('absolute rounded-full', sizeClassName, {
          '-m-0.5 shadow-raised-inner': !!borderColor,
        })}
      />
      {loadState === 'loading' && <AvatarLoading />}
    </>
  ) : (
    <Heading
      textColor={borderColor}
      className={cn('leading-none', textSizeClassName)}
    >
      {initials}
    </Heading>
  )
}

export { AvatarEntityComponent, type AvatarEntityProps }
