import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import type { Uuid } from '../unique-id-builder.ts'

/**
 * CertificationTag
 * These are the certification tags we care about in the database. When we build a proper certification stack we can decide to port these to
 * a proper CertificationType as-is, or rename them, possibly to better align with names in workramp.
 */
export const [CertificationTags, CertificationTagSchema, CertificationTagEnum] =
  EnumBuilder(
    'General QF Certified',
    'MNTN Certified',
    'Meta Certified',
    'TikTok Certified'
  )
export type CertificationTag = ZodSimplify<typeof CertificationTagSchema>

// This the mapping of the workramp certificationId to the tag names we use in our database.
export const WorkrampCertificationIdToTagMap: Record<Uuid, CertificationTag> = {
  '019152a6-74d6-7579-ad4f-d51de6575c78': 'General QF Certified', // Named 'QuickFrame General Onboarding' in WorkRamp
  'c95d2bb6-d77a-11ee-97cf-02ab00d03d4f': 'MNTN Certified', // Named 'QuickFrame MNTN Onboarding' in WorkRamp
  '09b5f28e-d75c-11ee-953f-061fddaab711': 'Meta Certified', // Named 'QuickFrame Meta Onboarding' in WorkRamp
  '018f9d83-7cea-7026-82e5-1aee98dda2cc': 'TikTok Certified', // Named 'QuickFrame TikTok Onboarding' in WorkRamp
  // Additional certifications in our DB but don't exist in WorkRamp
  // '': 'Tier 1 Certified',
  // '': 'MNTN_CERTIFIED',
  // '': 'AMAZON_CERTIFIED',
  // '': 'ROKU_CERTIFIED',
  // '': 'HULU_CERTIFIED',
  // '': 'ACADEMY_CERTIFIED',
}
