import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import type { PackageSource } from '../package.models.ts'
import { BillingMethodSchema } from './billing-method.ts'

export const CreditProgramIdSchema = BillingMethodSchema.exclude([
  'invoice',
  'stripe',
])

export type CreditProgramId = ZodSimplify<typeof CreditProgramIdSchema> // "mntn_credits" | "meta" | "tiktok" | "linked_in"

export const CreditProgramMembershipIdSchema = z
  .string()
  .min(1)
  .max(128)
  .brand<'credit-program-membership'>()

export type CreditProgramMembershipId = ZodSimplify<
  typeof CreditProgramMembershipIdSchema
>

export const CreditProgramMembershipId = (value: string) => {
  return CreditProgramMembershipIdSchema.parse(value)
}

export const CreditProgramPackageSources: Record<
  CreditProgramId,
  PackageSource
> = {
  mntn_credits: 'ctv',
  meta: 'meta',
  tiktok: 'tiktok',
  linkedin: 'linkedin',
}
