import type { ZodSimplify } from '@mntn-dev/utility-types'
import { z } from 'zod'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import {
  EmailAddressSchema,
  OptionalNonEmptyStringSchema,
  USPhoneNumberSchema,
  WebsiteUrlSchema,
} from './property.models.ts'
import { TeamIdSchema, TeamProfileIdSchema } from './unique-id.models.ts'

/**
 * TeamProfileDomainSelectModel
 */
export const TeamProfileDomainSelectModelSchema = z.object({
  teamProfileId: TeamProfileIdSchema,
  teamId: TeamIdSchema,
  overview: OptionalNonEmptyStringSchema(4096),
  emailAddresses: EmailAddressSchema.array().nonempty(),
  websiteUrls: WebsiteUrlSchema.array().nonempty().optional(),
  phoneNumbers: USPhoneNumberSchema.array().nonempty().optional(),
})

export type TeamProfileDomainSelectModel = ZodSimplify<
  typeof TeamProfileDomainSelectModelSchema
>

/**
 * TeamProfileDomainInsertModel
 */
export const TeamProfileDomainInsertModelSchema =
  TeamProfileDomainSelectModelSchema.partial({
    teamProfileId: true,
  })

export type TeamProfileDomainInsertModel = ZodSimplify<
  typeof TeamProfileDomainInsertModelSchema
>

/**
 * TeamProfileDomainUpsertModel
 */
export const TeamProfileDomainUpsertModelSchema =
  TeamProfileDomainInsertModelSchema.omit({
    teamProfileId: true,
    teamId: true,
  })

export type TeamProfileDomainUpsertModel = ZodSimplify<
  typeof TeamProfileDomainUpsertModelSchema
>

/**
 * TeamProfileDomainUpdateModel
 */
export const TeamProfileDomainUpdateModelSchema =
  TeamProfileDomainUpsertModelSchema.partial()

export type TeamProfileDomainUpdateModel = ZodSimplify<
  typeof TeamProfileDomainUpdateModelSchema
>

/**
 * TeamProfileDomainSelectModelFiltersSchema
 */
export const TeamProfileDomainSelectModelFiltersSchema = z
  .object({
    teamProfileId: ModelFilterSchema(TeamProfileIdSchema),
    teamId: ModelFilterSchema(TeamIdSchema),
  })
  .partial()

export type TeamProfileDomainSelectModelFilters = ZodSimplify<
  typeof TeamProfileDomainSelectModelFiltersSchema
>
