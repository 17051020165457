'use client'

import { themeTextColorMap } from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { AnimatePresence, easeOut, motion } from 'framer-motion'
import type { PropsWithChildren } from 'react'
import { getTestProps } from '../../utils/testing.ts'
import { useCollapsibleContext } from './use-collapsible.ts'

type CollapsiblePanelProps = PropsWithChildren<{ className?: string }>

const CollapsiblePanel = ({ className, children }: CollapsiblePanelProps) => {
  const { isOpen, dataTestId, dataTrackingId } = useCollapsibleContext()
  return (
    <AnimatePresence>
      {isOpen && (
        <div
          className={cn(
            'flex w-full items-center gap-4 self-stretch',
            themeTextColorMap.secondary,
            className
          )}
          {...getTestProps({
            dataTestId: `${dataTestId}-panel`,
            dataTrackingId: `${dataTrackingId}-panel`,
          })}
        >
          <motion.div
            initial={{ opacity: 0, y: -24, height: 0, overflow: 'hidden' }}
            animate={{ opacity: 1, y: 0, height: 'fit-content' }}
            exit={{ opacity: 0, y: -24, height: 0, overflow: 'hidden' }}
            transition={{ duration: 0.3, ease: easeOut }}
            className="w-full origin-top"
          >
            {children}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export { CollapsiblePanel }
