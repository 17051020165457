'use client'

import {
  type ThemeSpacing,
  paddingMap,
  spacingToPixels,
  themeHoverBackgroundMap,
} from '@mntn-dev/ui-theme'
import { cn } from '@mntn-dev/ui-utilities'
import { type KeyboardEvent, forwardRef } from 'react'
import {
  type VirtualItemProps,
  getVirtualItemProps,
} from '../../hooks/use-virtualizer.tsx'
import { Checkbox } from '../input/checkbox.tsx'
import { Text } from '../text/text.tsx'
import {
  type MultiselectOptionItem,
  useMultiselectContext,
} from './use-multiselect.ts'

type MultiselectOptionProps = Partial<VirtualItemProps> & {
  item: MultiselectOptionItem
  onClick?: () => void
}

const optionPadding: ThemeSpacing = '3'
const contentHeight = 28 // checkbox height
export const borderWidth = 1

export const multiselectOptionHeight = () =>
  spacingToPixels(optionPadding) * 2 + contentHeight + borderWidth

const MultiselectOption = forwardRef<HTMLDivElement, MultiselectOptionProps>(
  ({ index, item, onClick }, ref) => {
    const { selectedItems } = useMultiselectContext()

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
      if (onClick && e.key === 'Space') {
        onClick()
      }
    }

    return (
      <div
        className={cn(
          paddingMap[optionPadding],
          'flex justify-start items-center gap-2.5 cursor-pointer',
          themeHoverBackgroundMap.tertiary
        )}
        onKeyDown={handleKeyDown}
        onClick={onClick}
        ref={ref}
        {...getVirtualItemProps({ index })}
      >
        <div className="grow-0 shrink-0 w-fit">
          <Checkbox
            value={
              !!selectedItems?.find(
                (selectedItem) => selectedItem.id === item.id
              )
            }
            className="pointer-events-none"
            onChange={onClick}
          />
        </div>
        <Text>{item.name}</Text>
      </div>
    )
  }
)

export { MultiselectOption }
