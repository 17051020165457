import { cn } from '@mntn-dev/ui-utilities'
import type React from 'react'
import {
  type UseFieldGridProps,
  useFieldGrid,
} from '../../hooks/use-field-grid.ts'

type FormLayoutProps = Readonly<
  {
    children: React.ReactNode
    className?: string
  } & UseFieldGridProps
>

const FormLayout = ({
  children,
  className,
  columnGap = '6',
  ...useFieldGridProps
}: FormLayoutProps) => {
  const { fieldGridClassName } = useFieldGrid({
    columnGap,
    ...useFieldGridProps,
  })
  return (
    <div className={cn('grid', fieldGridClassName, className)}>{children}</div>
  )
}

export { FormLayout, type FormLayoutProps }
