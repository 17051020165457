import { z } from 'zod'

const UrlSchema = z.string().url()

export const urlProtocolRx = /^[A-Za-z][A-Za-z0-9\+\.\-]+\:\/\//
export const trailingSlashRx = /\/$/

export function getProtocol(value: string) {
  const matches = value.match(urlProtocolRx)

  return matches ? matches[0] : null
}

export function hasProtocol(value: string) {
  return getProtocol(value) !== null
}

// take a dirty URL and return a URL object
// will add protocol if missing and trim spaces
export function normalizeUrl(dirtyUrl: string) {
  let cleanUrl = dirtyUrl.trim()

  if (!hasProtocol(cleanUrl)) {
    cleanUrl = `http://${cleanUrl}`
  }

  const { success } = UrlSchema.safeParse(cleanUrl)

  if (!success) {
    throw new Error('Invalid URL')
  }

  return new URL(cleanUrl)
}

// try to normalize a dirty without throwing
export function safeNormalizeUrl(
  dirtyUrl: string
): { success: true; url: URL } | { success: false } {
  try {
    const url = normalizeUrl(dirtyUrl)
    return { success: true, url }
  } catch {
    return { success: false }
  }
}

// remove trailing slash as URL object href or toString() will add it
export function sanitizeUrl(url: URL | string) {
  const urlString = typeof url === 'string' ? url : url.href
  return urlString.replace(trailingSlashRx, '')
}

// Remove trailing slashed and protocol
// i.e. http://example.com/ -> example.com
export function friendlyUrl(url: URL | string) {
  return sanitizeUrl(url).replace(urlProtocolRx, '')
}
