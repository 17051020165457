import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'

/**
 * OfferSurveyTypeSchema
 */
export const [OfferSurveyTypes, OfferSurveyTypeSchema, OfferSurveyTypeEnum] =
  EnumBuilder('offer-accepted', 'offer-declined')

export type OfferSurveyType = ZodSimplify<typeof OfferSurveyTypeSchema>

export function isOfferSurveyType(value: string): value is OfferSurveyType {
  return OfferSurveyTypeSchema.safeParse(value).success
}

/**
 * ProjectSurveyTypeSchema
 */
export const [
  ProjectSurveyTypes,
  ProjectSurveyTypeSchema,
  ProjectSurveyTypeEnum,
] = EnumBuilder(
  'project-completed-brand-reviews-maker',
  'project-completed-maker-reviews-brand'
)

export type ProjectSurveyType = ZodSimplify<typeof ProjectSurveyTypeSchema>

export function isProjectSurveyType(value: string): value is ProjectSurveyType {
  return ProjectSurveyTypeSchema.safeParse(value).success
}

/**
 * SurveyTypeSchema
 */
export const [SurveyTypes, SurveyTypeSchema, SurveyTypeEnum] = EnumBuilder(
  ...OfferSurveyTypes,
  ...ProjectSurveyTypes
)

export type SurveyType = ZodSimplify<typeof SurveyTypeSchema>

export function isSurveyType(value: string): value is SurveyType {
  return SurveyTypeSchema.safeParse(value).success
}
