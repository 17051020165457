import { z } from 'zod'

import { EnumBuilder, type ZodSimplify } from '@mntn-dev/utility-types'
import { ModelFilterSchema } from '../model-filter-schema.ts'
import { NoteSchema, TimestampSchema } from './property.models.ts'
import { ReviewRoundNumberSchema } from './review.models.ts'
import {
  ReviewIdSchema,
  RoundIdSchema,
  UserIdSchema,
} from './unique-id.models.ts'

/**
 * RoundStatus
 */
export const [RoundStatuses, RoundStatusSchema, RoundStatusEnum] = EnumBuilder(
  'in_progress',
  'changes_requested',
  'approved'
)
export type RoundStatus = ZodSimplify<typeof RoundStatusSchema>

/**
 * Round Submission
 */
const RoundSubmissionSchema = z.object({
  actorId: UserIdSchema,
  timestamp: TimestampSchema,
})

/**
 * Round Proposal
 */

export const RoundProposalSchema = z
  .object({
    notes: NoteSchema,
    submitted: RoundSubmissionSchema,
  })
  .partial()

export type RoundProposal = ZodSimplify<typeof RoundProposalSchema>

/**
 * Round Feedback
 */

export const RoundFeedbackSchema = z
  .object({
    notes: NoteSchema,
    submitted: RoundSubmissionSchema,
  })
  .partial()

export type RoundFeedback = ZodSimplify<typeof RoundFeedbackSchema>

export const RoundDomainSelectModelSchema = z.object({
  roundId: RoundIdSchema,
  reviewId: ReviewIdSchema,
  roundNumber: ReviewRoundNumberSchema,
  status: RoundStatusSchema,
  proposal: RoundProposalSchema,
  feedback: RoundFeedbackSchema,
})

export type RoundDomainSelectModel = ZodSimplify<
  typeof RoundDomainSelectModelSchema
>

/**
 * RoundDomainInsertModel
 */
export const RoundDomainInsertModelSchema = RoundDomainSelectModelSchema.omit({
  status: true, // Always inserted as in_progress
  proposal: true,
  feedback: true,
}).partial({
  roundId: true,
})

export type RoundDomainInsertModel = ZodSimplify<
  typeof RoundDomainInsertModelSchema
>

/**
 * RoundDomainUpdateModel
 */
export const RoundDomainUpdateModelSchema = RoundDomainSelectModelSchema.pick({
  status: true,
})
  .merge(
    z.object({
      proposal: RoundProposalSchema.merge(
        z.object({ notes: NoteSchema.nullable() }).partial()
      ),
      feedback: RoundFeedbackSchema.merge(
        z.object({ notes: NoteSchema.nullable() }).partial()
      ),
    })
  )
  .partial()

export type RoundDomainUpdateModel = ZodSimplify<
  typeof RoundDomainUpdateModelSchema
>

/**
 * RoundDomainSelectModelFilter
 */
export const RoundDomainSelectModelFiltersSchema = z
  .object({
    roundId: ModelFilterSchema(RoundIdSchema),
    reviewId: ModelFilterSchema(ReviewIdSchema),
    roundNumber: ModelFilterSchema(ReviewRoundNumberSchema),
    status: ModelFilterSchema(RoundStatusSchema),
  })
  .partial()

export type RoundDomainSelectModelFilters = ZodSimplify<
  typeof RoundDomainSelectModelFiltersSchema
>
